/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      fullName
      email
      phone
      dob
      address
      city
      state
      postalCode
      country
      netWorthOverOneMillion
      incomeOverTwoHundredThousand
      jointIncomeOverThreeHundredThousand
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        email
        phone
        dob
        address
        city
        state
        postalCode
        country
        netWorthOverOneMillion
        incomeOverTwoHundredThousand
        jointIncomeOverThreeHundredThousand
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
