//import { Auth, API, graphqlOperation } from "aws-amplify"
import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom"
import Dropdown from 'react-bootstrap/Dropdown'
import styled from 'styled-components'

function changeBackground(e) {
    e.target.style.background = 'linear-gradient(to right, red, black)'
} 

function restoreBackground(e) {
    e.target.style.background = 'linear-gradient(to right, black, red)'
}


const Button = ({ color, solidcolor, text, border, onMouseOver, onMouseLeave, textColor}) => {
    return (
      <button className='btn' style = {{ backgroundImage: color, backgroundColor: solidcolor, color: textColor, borderRadius: border }} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        {text}
      </button>
    )
}

const NavbarContainer = styled.nav`
    background-color: black;
    height: 100px;
    width: 100%;
    padding: 0;
    margin: 0;
    display:grid;
    grid-template-columns: 1fr 1fr;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
`

const NavbarRight = styled.div`   
    display: flex;
    justify-content: space-around;
`

const NavbarLeft = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
`

const PageSel = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
`

const TextLink = styled(Link)`
    color: lightgray;
    text-decoration: none;

    &:hover{
    color: white;
    cursor: pointer;
    }
`



const Navbar = ( { user } ) => {

    let styleTitle = { color: 'white', fontSize: 40, fontFamily: "Times"} 

    /*
    async function signOut(){
        try{
            await Auth.signOut({global: true})
            localStorage.removeItem('reloaded')
            window.location.reload()
        } catch (error){
            if(error.name == 'NotAuthorizedException'){
                await Auth.signOut();
                localStorage.removeItem('reloaded')
                window.location.reload()
            }else{
                console.log('error signing out: ', error)
            }
            
        }
    }
*/
    return (
        <NavbarContainer>
            
            <NavbarLeft>
                <TextLink to="/" className="text-link" style={ styleTitle }> MarketSpace </TextLink>
            </NavbarLeft>
            <NavbarRight>
                <PageSel> <TextLink to="/marketplace" className="text-link"> Marketplace </TextLink> </PageSel>
                <PageSel> <TextLink to="/resources" className="text-link"> Resources </TextLink> </PageSel>
                <PageSel> <TextLink to="/about" className="text-link"> About </TextLink> </PageSel>
                {user ? (<><PageSel> <TextLink to="/profile" className="text-link"> Profile </TextLink> </PageSel> <PageSel> <Button onClick={() => {}} text="Sign Out" /> </PageSel></>) : <PageSel><TextLink to="/profile" className="text-link"> Sign In </TextLink></PageSel>}
            </NavbarRight>
        </NavbarContainer>
    )
}

export default Navbar
export {Button}
