import styled from "styled-components";

export const NewsCardHolder = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 15px;
    overflow-x: auto;
    overflow-y: hidden;

`

export const NewsCard = styled.div`


    display: flex;
    flex-direction: column;
    border: 1px solid #D9D9D9;
    padding: 10px;
    flex: 1 1 0px;

    height: 250px;
    width: 250px;
    
    
`

export const NewsSource = styled.h2`
    color: #5990FF;
    font-weight: bold;
    font-size: 1rem;
`

export const NewsTitle = styled.h2`
    color: black;
    font-weight: normal;
    font-size: 1.1rem;

    display: -webkit-box;
    max-width: 250px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`

export const NewsBody = styled.p`
    color: #696969;
    font-size: .9rem;
    
    display: -webkit-box;
    max-width: 250px;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
`
