import React, { useState } from 'react';
import './PaymentOptions.css';

function PaymentOptions() {
    const [wireSelected, setWireSelected] = useState(true);

    return (
        <div className="payment-options">
            <div className={`option-box ach`}>
                <h3>ACH Payment</h3>
                <ul>
                    <li>Coming Soon, Expected May 22nd </li>
                    <li>Maximum Investment: $5k/week</li>
                    <li>1-4 Day Settlement Time</li>
                </ul>
            </div>
            <div className={`option-box wire ${wireSelected ? 'selected' : ''}`} onClick={() => setWireSelected(!wireSelected)}>
                <h3>Wire Transfer {wireSelected && <span className="checkmark">✓</span>}</h3>
                <ul>
                    <li>Available Now</li>
                    <li>Unlimited Funding Size</li>
                    <li>1 Day Settlement Time</li>
                </ul>
            </div>
        </div>
    );
}

export default PaymentOptions;
