import React from 'react'

import {SegmentHolder, Title} from 'components/newoffering/TextSegment/components'
import {Image} from './components'

const Tour = ( {offering} ) => {
  return (
    <SegmentHolder>
        <Title> Tour </Title>
        <iframe width="100%" style={{"aspectRatio": 1.77708}} src="https://my.matterport.com/show/?m=GsAvnjHQkja&play=1&mls=1" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></ iframe>
        {/*<Image src={offering.photos[0]} />*/}
    </SegmentHolder>
  )
}

export default Tour
