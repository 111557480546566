import React, {useState, useEffect} from 'react'

import ProgressBar from 'components/newoffering/ProgressBar'

import {Container, Tags, MainImage, Scrollable, ScrollImage, Left, Right, ImgHolder, GalleryContainer} from './components'

import { clampBuilder } from 'utils/utils'

/*
import AWS from 'aws-sdk'

AWS.config.update({
  region: "us-west-1"
})

const s3 = new AWS.S3();
*/
const ImagesAndTags = ({offering}) => {

  
  /*const params = {
    Bucket: 'ms-property-images'
  }*/



  const [curPhotoId, setCurPhotoId] = useState(0);
  const [curPhoto, setCurPhoto] = useState(offering.photos[0]);

  const nPhotos = offering.photos.length;

  const handleScrollableClick = (id) => {
    setCurPhoto(offering.photos[id]);
    setCurPhotoId(id);
  }

  const modulo = (n, d) => {return(((n % d) + d) % d)};
  const rotateImage = (delta) => {
    let newId = modulo(curPhotoId - delta, nPhotos);
    setCurPhotoId(newId);
    setCurPhoto(offering.photos[newId]);
  }

  let mobileGalleryWidth = clampBuilder(360, 1250, 330/16, 1000/16);
  

  return (
    
    
    <Container>
        <GalleryContainer>
          <ImgHolder>
          <MainImage width={clampBuilder(360, 1680, 330/16, 900/16)} mobileWidth={mobileGalleryWidth} src={curPhoto} />
            <Left onClick={() => rotateImage(-1)} />
            <Right onClick={() => rotateImage(1)} />
          </ImgHolder>

          <Scrollable width={clampBuilder(360, 1680, 330/16, 900/16)} mobileWidth={mobileGalleryWidth}>
            <ScrollImage width={clampBuilder(360, 1680, 330/(16*4.5), 900/(16*4.5))} src={offering.photos[0]} onClick={() => handleScrollableClick(0)}/>
            <ScrollImage width={clampBuilder(360, 1680, 330/(16*4.5), 900/(16*4.5))} src={offering.photos[1]} onClick={() => handleScrollableClick(1)}/>
            <ScrollImage width={clampBuilder(360, 1680, 330/(16*4.5), 900/(16*4.5))} src={offering.photos[2]} onClick={() => handleScrollableClick(2)}/>
            <ScrollImage width={clampBuilder(360, 1680, 330/(16*4.5), 900/(16*4.5))} src={offering.photos[3]} onClick={() => handleScrollableClick(3)}/>
            <ScrollImage width={clampBuilder(360, 1680, 330/(16*4.5), 900/(16*4.5))} src={offering.photos[4]} onClick={() => handleScrollableClick(4)}/>
            <ScrollImage width={clampBuilder(360, 1680, 330/(16*4.5), 900/(16*4.5))} src={offering.photos[5]} onClick={() => handleScrollableClick(5)}/>
            <ScrollImage width={clampBuilder(360, 1680, 330/(16*4.5), 900/(16*4.5))} src={offering.photos[6]} onClick={() => handleScrollableClick(6)}/>
          </Scrollable>
        </GalleryContainer>
        <ProgressBar investors={offering.investors} progress={offering.progress} />
        
    </Container>

  )
}

export default ImagesAndTags
