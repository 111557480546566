import styled from "styled-components"

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1rem;
    gap: 10px;

`