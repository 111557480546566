import styled from "styled-components";

export const Title = styled.h2`
    text-decoration: underline;
    font-size: ${ (props) => props.size };
`

export const Body = styled.p`
    color: #696969;
    font-size: ${ (props) => props.size };
    text-align: justify;
`

export const SegmentHolder = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
`