import React from 'react'

const colorDict = {
    'orange': '#FC7618',
    'white': '#FFFFFF',
    'development': '#1CE4FF',
    'rental': '#00D789',
}

const Tag = ( {text, color, fontSize, subText, image} ) => {
  return (
    <div style={{'display':'inline',
      'padding-left': '25px',
      'padding-right': '25px',
      'padding-top': '2.5px',
      'padding-bottom': '2.5px',
      'border-radius': '15px',
      'backgroundColor': colorDict[color],
      'fontSize': fontSize,
      'font-family': 'Inter',
      'fontWeight': 'bold',
      'boxShadow': '0px 4px 4px rgba(0,0,0,0.25)',
    }}>

      <div style={{
        'display': 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
      }}> 
      {text} {subText ? <span style={{'fontWeight': '400'}}>&nbsp;{subText} </span> : ''} {image ? <img src={image} style={{'max-width': fontSize, 'max-height': fontSize}} /> : ''} 
      </div>
    </div>
  )
}

export default Tag
