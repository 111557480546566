import React, { useState, useEffect } from 'react';
import SelectShares from './SelectShares/SelectShares';
import './InvestFlow.css';
import SignDocuments from './SignDocuments/SignDocuments';
import WiringInstructions from './WiringInstructions/WiringInstructions';
import ConfirmationPage from './Confirmation/ConfirmationPage';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Navigate, useParams } from 'react-router-dom';

import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getProfile } from 'graphql/queries'; // Import the query file

const steps = ['Select Shares', 'Sign Documents', 'Confirmation'];

const InvestFlow = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [canProceed, setCanProceed] = useState(false);
    const [enteredAmount, setEnteredAmount] = useState(2500); // Default to 250 shares

    const [redirect, setRedirect] = useState(false);

    const {offeringID} = useParams();
    

    const perShare = [1, 10][offeringID];

    const handleClick = (index) => {
        if (completedSteps.includes(index) || index === currentStep) {
            setCurrentStep(index);
            setCompletedSteps(completedSteps.filter(step => step !== index));
        }
    }

    
    const nextStep = () => {
        if (canProceed) {
            setCompletedSteps([...completedSteps, currentStep]);
            if (currentStep < steps.length - 1) {
                setCurrentStep(currentStep + 1);
            }
        }
    }

    const fetchProfile = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const profileData = await API.graphql(graphqlOperation(getProfile, { id: user.username}));
          return profileData.data.getProfile;
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      };

      useEffect(() => {
        console.log('id', offeringID);
        fetchProfile().then((profile) => {
            if(!profile){
                console.log(profile);
                setRedirect(true);
            }
        })
      }, [])
    
    return redirect ? <Navigate replace to="/finishprofile" /> : (
        <div className="invest-flow">
            <header className="header">
                {steps.map((step, index) => (
                    <h2 
                        key={index} 
                        onClick={() => handleClick(index)} 
                        className={index === currentStep ? 'active' : completedSteps.includes(index) ? 'completed' : ''}
                    >
                        {step} {completedSteps.includes(index) && <span>&#10003;</span>}
                    </h2>
                ))}
            </header>
            {currentStep === 0 && <SelectShares enteredAmount={enteredAmount} setEnteredAmount={setEnteredAmount} setCanProceed={setCanProceed} />}
            {currentStep === 1 && <SignDocuments offeringID={offeringID} totalAmount={enteredAmount} sharesToPurchase={enteredAmount / perShare} />}
            {currentStep === 2 && <ConfirmationPage shares={enteredAmount / perShare} amount={enteredAmount} />}
            {currentStep <= 1 && <button onClick={nextStep} className="next-button" disabled={!canProceed}> {["Next", "Buy Shares"][currentStep] } </button>}
            

        </div>
    );
}


export default withAuthenticator(InvestFlow);
