import './SignDocuments.css';

import React, { useEffect, useState } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { getProfile } from 'graphql/queries'; // Import the query file

import LinkBank from '../LinkBank/LinkBank';
import ESignature from '../E-Sign/ESignature';
import { FaEye } from 'react-icons/fa';

const SignDocuments = ({ totalAmount, sharesToPurchase, offeringID}) => {

    
    const offeringName = ['Catalina Village', 'The Century City Apartments'][parseInt(offeringID)]
    const pdfTemplateID = ['Auc4cmJyIK9uRQ91KBRs', 'Do1lZL6i0eH2hCzLUUrw'][parseInt(offeringID)]

    const [showAll, setShowAll] = useState(false);
    const [profile, setProfile] = useState(null);


    const [bankLinked, setBankLinked] = useState(false);
    const [signed, setSigned] = useState(false);

    const [agreementURL, setAgreementURL] = useState(null);

    
    useEffect(() => {
        fetchProfile();
        console.log(profile);
        console.log('id', offeringID);
    }, [])


    var dateObj = new Date(Date.now());
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    
    const date = month + "/" + day + "/" + year;

    const tableData = profile ? [
        ['Full Name', profile.fullName],
        ['Offering Name', offeringName],
        ['Total Amount', `$${new Intl.NumberFormat().format(totalAmount)}`],
        ['Shares to Purchase', new Intl.NumberFormat().format(sharesToPurchase)],
        ['Share Price', `$${[1, 10][offeringID]}`],
        ['Email', profile.email],
        ['Phone Number', profile.phone],
        ['Address', `${profile.address}, ${profile.city}, ${profile.state}, ${profile.postalCode}`],
        ['Birth Date', profile.dob],
        ['Account Type', 'Individual']
      ] : [];

      useEffect(() => {
        if(profile){
        generatePDF(pdfTemplateID);
        }
      }, [tableData])
      
      /*
      const test = async () => {
        const res = await API.get('helloWorldTest', '/test');
        console.log(res);
      }*/
      
      const generatePDF = async (pdfTemplateID) => {
        
        if(profile){
          
    let pdfData = null;

    if(offeringID == 0)
        pdfData = {
                "title": "Catalina Entitlement Fund LLC PPM Signature Pages Only 6 28 21",
                "fontSize": 10,
                "textColor": "#333333",
                "data": {
                  "cast61dcd5c0f51e11edb6377d04e03f286b": profile.fullName,
                  "cast691066e0f51e11edb6377d04e03f286b": profile.address,
                  "cast7395bb10f51e11edb6377d04e03f286b": date,
                  "cast76cb6aa0f51e11edb6377d04e03f286b": new Intl.NumberFormat().format(sharesToPurchase),
                  "cast7c129510f51e11edb6377d04e03f286b": new Intl.NumberFormat().format(sharesToPurchase),
                  "cast7f9a5c40f51e11edb6377d04e03f286b": new Intl.NumberFormat().format(totalAmount),
                  "cast02385170f51f11edb6377d04e03f286b": date,
                  "cast05648b20f51f11edb6377d04e03f286b": profile.fullName,
                  "cast07e20e40f51f11edb6377d04e03f286b": profile.fullName,
                  "cast0ab3f2f0f51f11edb6377d04e03f286b": profile.fullName,
                  "cast5c5e7940f51f11edb6377d04e03f286b": profile.fullName,
                  "cast5f794dd0f51f11edb6377d04e03f286b": profile.address + ', ' + profile.city + ', ' + profile.state + ', ' + profile.postalCode + ', ' + profile.country,
                  "cast63b2b1c0f51f11edb6377d04e03f286b": profile.phone,
                  "cast663209a0f51f11edb6377d04e03f286b": profile.email,
                  "cast6b7e1610f51f11edb6377d04e03f286b": profile.fullName,
                  "cast74f41d20f51f11edb6377d04e03f286b": profile.dob,
                  "cast88751840f51f11edb6377d04e03f286b": "x",
                  "cast92da8860f51f11edb6377d04e03f286b": profile.netWorthOverOneMillion ? "x" : "",
                  "cast97673c70f51f11edb6377d04e03f286b": profile.incomeOverTwoHundredThousand ? "x" : "",
                  "casta3002380f51f11edb6377d04e03f286b": profile.fullName,
                  "casta53f1700f51f11edb6377d04e03f286b": profile.fullName,
                  "castab819160f51f11edb6377d04e03f286b": profile.fullName
                }
    } 

    if(offeringID == 1){

     pdfData = {
        "title": "Century Subscription Agreement",
        "fontSize": 10,
        "textColor": "#333333",
        "data": {
          "cast91da6d80f5cf11edbb11f72f636f829f": date,
          "cast9761ae30f5cf11edbb11f72f636f829f": profile.netWorthOverOneMillion ? "x" : "",
          "cast9a62e040f5cf11edbb11f72f636f829f": profile.incomeOverTwoHundredThousand ? "x" : "",
          "casta7913460f5cf11edbb11f72f636f829f": profile.fullName,
          "castaa6d0420f5cf11edbb11f72f636f829f": profile.address + ', ' + profile.city + ', ' + profile.state + ', ' + profile.postalCode + ', ' + profile.country,
          "castb1415e40f5cf11edbb11f72f636f829f": profile.phone,
          "castbcc4c180f5cf11edbb11f72f636f829f": "x",
          "castc1253570f5cf11edbb11f72f636f829f": new Intl.NumberFormat().format(totalAmount),
          "castc722b790f5cf11edbb11f72f636f829f": profile.fullName,
          "castd4491c70f5cf11edbb11f72f636f829f": profile.fullName,
          "castd7f3d4f0f5cf11edbb11f72f636f829f": day,
          "castdeac90c0f5cf11edbb11f72f636f829f": dateObj.toLocaleString('default', {month: 'long'}),
          "caste1e28e70f5cf11edbb11f72f636f829f": year.toString().slice(-2)
        }
      }

    }
          const response = await API.post('fillPDF', '/fillPDF', {
              body: {
                  "User": profile.id,
                  "pdfTemplateID": pdfTemplateID,
                  "Data": pdfData
              }
            });
          setAgreementURL(response.signedURL);
          
        }
      };
      

    const fetchProfile = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const profileData = await API.graphql(graphqlOperation(getProfile, { id: user.username}));
          setProfile(profileData.data.getProfile);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      };


    return (
        <div className="sign-documents">
            <h2>1. Review Documents</h2>
            <div className="document-links">
                <a href={agreementURL} target="_blank" >Purchase Agreement { agreementURL ? "(Click to View)" : "(Generating)" } </a>
            </div>
            <h3>The documents are generated with the information below</h3>
            <table>
                <tbody>
                    {tableData.slice(0, showAll ? undefined : 3).map((row, i) => (
                        <tr key={i}>
                            <td>{row[0]}</td>
                            <td>{row[1]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={() => setShowAll(!showAll)}>
                {showAll ? 'View Less' : 'View More'}
            </button>
            
            <h2>2. Select Payment Method</h2>
            <LinkBank bankLinked={bankLinked} setBankLinked={setBankLinked} />

            <h2>3. Electronic Signature</h2>
            <ESignature signed={signed} setSigned={setSigned} profile={profile ? profile : {fullName: ''}}/>

            <h2>4. Sign Documents</h2>
            <p style={{'color': 'gray', fontSize: '0.8rem'}}>

            By clicking the "Buy Shares" button: I certify that I consent to conduct all affairs relating to the documents listed above electronically. I further certify that I have electronically accessed the documents listed above, and that I have read them. I further certify that the information provided is correct and confirm to the certifications on Form W-9. I adopt the above electronic signature as my signature, and hereby electronically sign the documents listed above. I acknowledge that I have accessed, have read and hereby agree to the MarketSpace Terms of Service, and that I authorize the MarketSpace services, in the manner designated therein, to process the documents and signatures provided herewith and to create, store, and communicate electronic records of the documents listed above.

            </p>

            

            
            
        </div>
    );
};

export default SignDocuments;
