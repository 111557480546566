import styled from "styled-components"

import {FaInfoCircle} from 'react-icons/fa'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @media(max-width: 670px){
        flex-direction: row;
        width: 85vw;
        align-items: space-around;
        justify-content: space-around;
    }
`

export const PropertyValue = styled.h4`
    color: #5992FF;
    text-decoration: underline;
    font-size: ${(props) => props.size}
`

export const TargetReturnHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative; 

`

export const TargetReturn = styled.h4`
    color: #4F4F4F;
    text-decoration: underline;
    border-right: 1px dashed #5992FF;
    padding-right: 10px;
    text-align: center;
    size: ${(props) => props.size};
`

export const Value = styled.h3`
    font-weight: bold;
    font-size: ${(props) => props.size};
    
`

export const TargetReturnInfo = styled(FaInfoCircle)`
    color: #5992FF;
    vertical-align: super;
    font-size: .5em;
    position: absolute;
    right: -23px;
    top: 10px;

    &:hover{
        cursor: pointer;
    }
`

export const OuterMiniHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 30px;
`

export const MiniHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
`

export const MiniImage = styled.img`
    height: 65px;
    width: 65px;
`

export const Mini = styled.h5`
    font-weight: bold;
    font-size: ${ (props) => props.size};
`

export const MiniValue = styled.h5`
    font-size: ${ (props) => props.size};
    font-weight: 400;
`



