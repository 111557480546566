import React from 'react'
import { Inner, InnerBottom, InnerTop, MainText, Outer, SignUpButton, SubText, MakeBlue } from './components'

import { clampBuilder } from 'utils/utils';

let minWidth = 360;
let maxWidth = 1680;

const Hero = () => {

    let minFont = 2;
    let maxFont = 3.3;

  return (
    
    <Outer top={clampBuilder(minWidth, maxWidth, 2, 4)}>
        <Inner width={clampBuilder(minWidth, maxWidth, 315/16, 780/16)}>
            <InnerTop>
                <MainText size={clampBuilder(minWidth, maxWidth, minFont, maxFont)}> Invest in <br/> Something Real. </MainText>
                <SignUpButton to="signup" size={clampBuilder(minWidth, maxWidth, minFont*.5, maxFont*.5)} height={clampBuilder(minWidth, maxWidth, 40/16, 70/16)} > Sign Up </SignUpButton>
            </InnerTop>
            <InnerBottom>
                <SubText size={clampBuilder(minWidth, maxWidth, minFont*.4, maxFont*.4)}>
                Join our community of investors looking to build real wealth together. <font color="#5990FF" weight="bold"> Get up to a $100 bonus investment in your first property to help kick off your portfolio. </font>
                </SubText>
            </InnerBottom>
        </Inner>
    </Outer>

    
  )
}

export default Hero