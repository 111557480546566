import styled from "styled-components";
import {Link} from 'react-router-dom';

export const Card = styled.div`
    
    font-family: tahoma;
    height: ${(props) => props.height};
    width: ${(props) => props.width};

    
    &:hover {
        transform: scale(1.03);
        box-shadow: -1px -1px 20px #ECEDEE;
        transition: all 0.5 ease;
        cursor: pointer;
      }
`

export const ImageHolder = styled.div`
    position: relative;
`

export const Title = styled.h3`
    margin-top: 1rem;
    font-weight: bold;
    text-align: center;
`

export const Location = styled.h4`
    text-align: center;
    color: #828282;
    margin-bottom: 1rem;
`


export const TagsHolder = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    font-family: Inter;
    justify-content: space-between;
    width: 100%;
`

export const LowerTagsHolder = styled.div`
    display: flex;
    position: absolute;
    flex-direction: row;
    bottom: 0;
    left: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    font-family: Inter;
    justify-content: space-between;
    width: 100%;

`

export const BarHolder = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`