import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
`

export const InfoBorder = styled.div`
    max-width: 600px;
    padding: 1rem;
    position: relative;
    background: linear-gradient(to right, red, purple);
    padding: 3px;
    height: 100%;

`

export const InfoInner = styled.div`
    background-color: white;
    color: black;
    padding: 2rem;
    height: 100%;
`