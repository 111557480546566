import styled from "styled-components";

import { Link } from "react-router-dom";

export const SignUpButton = styled(Link)`
    background-color: #5990FF;
    color: white;
    border-radius: 40pt;
    border: none;
    height: ${ (props) => props.height};
    
    text-decoration: none;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    width: 80%;

    font-weight: bold;
    font-size: ${ (props) => props.size};

    &:hover{
        background-color: #5973FF;
    }

    @media (max-width: 1440px) {
        width: 50%;
    }

`
export const MainText = styled.h1`
    padding: 0;
    margin: 0;
    font-family: Inter;
    font-weight: bold;
    font-size: ${ (props) => props.size};
    @media (max-width: 1440px){
        text-align: center;
    }
`

export const SubText = styled.h4`
    padding: 0;
    margin: 0;
    font-family: Inter;
    color: #5A5A5A; 
    font-size: ${ (props) => props.size};
`

export const MakeBlue = styled.span`
    color: #5990FF;
`

export const Outer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${ (props) => props.top};
    margin-bottom: ${ (props) => props.top};
`

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    width: ${ (props) => props.width};
    gap: 1rem;
`

export const InnerTop = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;

    @media (max-width: 1440px) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
`

export const InnerBottom = styled.div`

`