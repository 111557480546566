import React from 'react'
import { Container, MiniHolder, MiniImage, PropertyValue, TargetReturnHolder, Value, TargetReturn, Mini, MiniValue, TargetReturnInfo, OuterMiniHolder } from './components'

import beds from 'photos/icons/beds.png' 
import baths from 'photos/icons/baths.png'

import { clampBuilder } from 'utils/utils'

const SidebarFacts = ({offering, goToCalculator}) => {

  return (
    <Container>

        <div style={{textAlign:'center',}}>
            <PropertyValue size={clampBuilder(360, 1680, 1, 1.4)}> Offering Size </PropertyValue>
            <Value size={clampBuilder(360, 1680, 1.6, 2.2)}> ${offering.offeringSize.toLocaleString("en-US")} </Value>
        </div>

        {offering.class == 'development' ? 
        <>
        <TargetReturnHolder>
            <TargetReturn size={clampBuilder(360, 1680, 1, 1.2)}> Target <br /> Equity X </TargetReturn>
            <Value size={clampBuilder(360, 1680, 2, 2.4)}> {offering.equityMultiple}x </Value>
            <TargetReturnInfo size={clampBuilder(360, 1680, 0.5, 1.0)} onClick={goToCalculator}/>
        </TargetReturnHolder>
        <TargetReturnHolder>
            <TargetReturn size={clampBuilder(360, 1680, 1, 1.2)}> Target <br /> IRR </TargetReturn>
            <Value size={clampBuilder(360, 1680, 2, 2.4)}> {(parseFloat(offering.targetIRR)).toLocaleString("en-US", {style: 'percent'})} </Value>
            <TargetReturnInfo size={clampBuilder(360, 1680, 0.5, 1.0)} onClick={goToCalculator}/>
        </TargetReturnHolder>
        </>
        : 
        <TargetReturnHolder>
        <TargetReturn size={clampBuilder(360, 1680, 1, 1.2)}> Current <br /> Return </TargetReturn>
        <Value size={clampBuilder(360, 1680, 2, 2.4)}> {(parseFloat(offering.currentRental) + parseFloat(offering.currentAppreciation)).toLocaleString("en-US", {style: 'percent'})} </Value>
        <TargetReturnInfo size={clampBuilder(360, 1680, 0.5, 1.0)} onClick={goToCalculator}/>
        </TargetReturnHolder>
        
        }

        {/*
        <OuterMiniHolder>
            <MiniHolder>
                <MiniImage src={beds} />
                <div>
                    <Mini size={clampBuilder(360, 1680, 1, 1.2)}> Beds </Mini>
                    <MiniValue size={clampBuilder(360, 1680, .8, 1)}> {offering.beds} </MiniValue>
                </div>
            </MiniHolder>
            <MiniHolder>
                <MiniImage src={baths} />
                <div>
                    <Mini size={clampBuilder(360, 1680, 1, 1.2)}> Baths </Mini>
                    <MiniValue size={clampBuilder(360, 1680, .8, 1)}> {offering.baths} </MiniValue>
                </div>
            </MiniHolder>
  </OuterMiniHolder>*/}

    </Container>
  )
}

export default SidebarFacts
