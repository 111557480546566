import React, {useEffect} from 'react'
import {TotalBar, FillingBar, BarHolder, MetricsHolder} from './components' 

const ProgressBar = ( {progress, investors, fromColor, toColor} ) => {

  if(!fromColor){
    fromColor = [89, 145, 253]
  }
  if(!toColor){
    toColor = [109, 87, 253]
  }

  let adjustedToColor = [fromColor[0] + (toColor[0] - fromColor[0])*parseFloat(progress)/100,
                          fromColor[1] + (toColor[1] - fromColor[1])*parseFloat(progress)/100,
                          fromColor[2] + (toColor[2] - fromColor[2])*parseFloat(progress)/100]

  const rgbString = (l) => {
    return(`rgb(${l[0]}, ${l[1]}, ${l[2]})`);
  }

  useEffect(() => {
    console.log(rgbString(fromColor))
    console.log(rgbString(adjustedToColor))
  })
  
  return (
    <>
        <BarHolder>
            <TotalBar />
            <FillingBar start={rgbString(fromColor)} end={rgbString(adjustedToColor)} style={{'width': `${progress}%` }}/>
        </BarHolder>
        <MetricsHolder>
            <h5> {investors.toLocaleString()} Investors </h5> <h5> {progress}% Funded </h5>
        </MetricsHolder>
    </>
    
  )
}

export default ProgressBar