import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Container = styled.div`
    width: 100%;
    min-height: 100px;
    background-color: #182A44;
    padding: 2em;
`

const FooterP = styled.p`
  text-align: center;
  color: white;
  font-size: .75em;
  padding-left: 5rem;
  padding-right: 5rem;
`

const Footer = () => {
  return (
    <Container>
        <FooterP> © 2022 MarketSpace Technologies LLC. </FooterP>
        <FooterP> *  Information in this message, including information regarding targeted returns and investment performance, is provided by the sponsor of the investment opportunity and is subject to change. Forward-looking statements, hypothetical information or calculations, financial estimates and targeted returns are inherently uncertain. Such information should not be used as a primary basis for an investor’s decision to invest. Investment opportunities on the MarketSpace Platform are speculative and involve substantial risk. You should not invest unless you can sustain the risk of loss of capital, including the risk of total loss of capital.</FooterP>
    </Container>
  )
}

export default Footer
