import styled, {keyframes} from 'styled-components'



const floorSpacingCompressed = 150;
const pushOut = keyframes`
    from {
        transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg); 
        background-color: rgba(220, 220, 220, .2 )
    }
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(1.25); 
        background-color: rgba(220, 220, 220, .5)
    
        
        
    }
`
const reverse = keyframes`
from {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); 
    

    
    
}
to {
    transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg); 
    
}

`

export const Cont = styled.div`
    position:relative;
    height: 700px;
`

export const Floor = styled.div`
    
    width: 948px;
    height: 570px;
    transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg);
    left: 100px;
    right: 0;
    color: transparent;
    position:absolute;

    background-size: 100% 100%;
    background-image: url(${ ({img}) => img });

    background-color: rgba(220, 220, 220, .2 );
    


    bottom: ${({floorNumber}) => (floorNumber * floorSpacingCompressed)}px;
    z-index: ${ ({floorNumber}) => (floorNumber) };
    animation-name: ${reverse};
    animation-duration: 1s;
    animation-fill-mode: forwards;


    margin: 0 auto;
    &:hover{
        animation-name: ${pushOut};
        animation-duration: 2s;
        animation-fill-mode: forwards;
        cursor: pointer;
        z-index: 999;
        

    }

`

export const Room = styled.div``

/*
export const Room = styled.div`
    border: 1px solid #fff;
    border-right-width: 0px;
    font-size: 1em;
    text-align: center;

    &:hover{
        background-color: white;
    }
`*/

export const FloorplanFooter = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

