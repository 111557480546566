import React, {useEffect} from 'react'
import MarketplaceApp from '../components/marketplace/MarketplaceApp'
import Navbar from '../components/navbars/Navbar'
import MainNavbar from '../components/navbars/MainNavbar'
import Sidebar from '../components/Sidebar'

const Marketplace = ( {user} ) => {
  useEffect(() => {window.scrollTo(0, 0)}, []);
  return (
    
    <div>
        
        <MarketplaceApp />
    </div>
  )
}

export default Marketplace
