import React, {useEffect} from 'react'
import Footer from 'components/home/Footer'
import ResourceTopic from 'components/resources/ResourceTopic'
import knowledgetree from 'photos/treeOknowledge.png'

let resourcesJSON = require('testdata/resources/resources.json')

const Resources = () => {
    useEffect(() => {window.scrollTo(0, 0)}, []);

    return (
        <>

    <div style={
        {maxWidth: "800px", 
        margin: "0 auto",
        paddingBottom: '100px'
        }}>  
        

        {resourcesJSON.map( (topic) => {
            return(<ResourceTopic topic={topic} />)
        })}
        
    </div>
    <Footer/>
    </>
    )
}

export default Resources