import React, {useEffect} from 'react'

import MainNavbar from 'components/navbars/MainNavbar'

const Developers = () => {
  useEffect(() => {window.scrollTo(0, 0)}, []);
  return (
    <div>
      Developers
    </div>
  )
}

export default Developers
