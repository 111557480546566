import React from 'react'

import {Container} from './components'
import OfferingCard from 'components/shared/OfferingCard'

const CardHolder = ( {offerings} ) => {
  return (
    <Container>
      {offerings.map(offering => {
        return(
            <OfferingCard offering={offering} />
        )
      })}
    </Container>
  )
}

export default CardHolder
