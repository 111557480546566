import React from 'react';
import styled from 'styled-components';

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-top: 20px;
`;

const Header = styled.h2`
  text-align: left;
  padding-left: 50px;
`;

const Table = styled.table`
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  border-collapse: collapse;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
`;

const TableRow = styled.tr`
  background-color: white;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
  height: 80px;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #E4F5F7;
  color: black;
  width: ${props => props.width};
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
`;


const UsesOfFundsSection = () => {
    const tableData = [
      ['Purchase Price', '$234', '26.8%', '$12,000,000'],
      ['Hard Costs', '$335', '38.4%', '$17,204,860'],
      ['Soft Costs', '$90', '10.3%', '$4,618,009'],
      ['Financing Costs', '$35', '4.0%', '$1,799,762'],
      ['Interest Reserve', '$103', '11.8%', '$5,262,284'],
      ['Development Fee', '$34', '3.8%', '$1,719,129'],
      ['Project Contingency', '$23', '2.6%', '$1,181,132'],
      ['Equity Reserve', '$10', '1.1%', '$500,000'],
      ['Offering Costs', '$1', '0.1%', '$50,000'],
      ['Total', '$865', '100%', '$44,335,176'],
    ];
  
    return (
      <TableSection>
        <Header> Uses of Funds</Header>
        <Table>
          <thead>
            <TableRow>
              <TableHeader width="40%">Uses</TableHeader>
              <TableHeader width="20%">Per RSF</TableHeader>
              <TableHeader width="20%">% of Total</TableHeader>
              <TableHeader width="20%">Amounts</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {tableData.map((row, i) => (
              <TableRow key={i}>
                {row.map((cell, j) => (
                  <TableCell key={j}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableSection>
    );
  };

export default UsesOfFundsSection;