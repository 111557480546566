import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'

const Container = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 0 5px #ECEDEE;
  margin-bottom: 20px;
`


const Facts = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 1.5em;
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 250px;
  width: 250px;
  background-color: #F6F5FE;
  &:hover {
    transition: all 0.2 ease-in-out;
    background: ${({primary}) => (primary ? '#fff' : '#01BF71')};
    
  }
`




const Fact = styled.h1`
  
  font-size: 50px;
  text-align: center;

  &::before{
    content: '${ ({before}) => before }';
    color: black;
    display: block;
    font-size: .4em;
  }

  &::after{
    content: '${ ({after}) => after }';
    color: black;
    display: block;
  }
`

const FactsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FactsH1 = styled.h1`
  font-size: 2.5em;
  

  &::after{
    content: '';
    width:100%;
    height: 10px;
    background-color:purple; 
    display:block;
  }
`

const CircleLink = styled(Link)`
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px') };
  color: ${({dark}) => (dark ? '#010606' : '#fff') };
  background: ${({primary}) => (primary ? '#01BF71' : '#010606')};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 250px;
  width: 250px;
  &:hover {
    transition: all 0.2 ease-in-out;
    background: ${({primary}) => (primary ? 'purple' : '#01BF71')};
    cursor: pointer;
}
`




const FactsSection = () => {
  return (
    <Container>
      
      <FactsWrapper>
        <FactsH1> Meet Our Developers </FactsH1>
        <Facts>
          <Circle>
            <Fact before="Lifetime Deals"> &gt;$2B </Fact>
          </Circle>
          <Circle>
            <Fact before="Average Experience"> 20 yrs </Fact>
          </Circle>
          <Circle>
            <Fact before="Avg ROI"> 29% </Fact>
          </Circle>
          <CircleLink to="/about" primary="true">
            <h1>View Details</h1>
          </CircleLink>

        </Facts>
      </FactsWrapper>
    
    </Container>
  )
}

export default FactsSection
