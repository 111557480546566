import React from 'react';
import './FinalAbout.css';
import Footer from 'components/home/Footer'
import { FaUsers, FaBuilding, FaAward } from 'react-icons/fa';  // Importing icons from react-icons library


const FinalAbout = () => {
  return (
    <>
      <div className="about-section-about">
          <div className="about-topic-about slide-in-from-left-about">
              <h2 className="about-header-about"><FaUsers className="about-icon-about" />Who We Are</h2>
              <p className="about-text-about">We are a premier provider in the real estate sector, offering fractional ownership of commercial real estate opportunities. Our team combines decades of industry experience with innovative technology to bring our investors unique opportunities.</p>
          </div>

          <div className="about-topic-about slide-in-from-right-about">
              <h2 className="about-header-about"><FaBuilding className="about-icon-about" />What We Do</h2>
              <p className="about-text-about">We provide access to a diverse range of commercial real estate opportunities, including new developments and newly finished or renovated spaces. Our platform allows investors to enjoy the benefits of real estate ownership with the freedom and liquidity of publicly traded stocks.</p>
          </div>

          <div className="about-topic-about slide-in-from-left-about">
              <h2 className="about-header-about"><FaAward className="about-icon-about" />Why Choose Us</h2>
              <p className="about-text-about">Our commitment to transparency, innovation, and excellence sets us apart in the industry. We offer a user-friendly platform, personalized support, and curated investment opportunities to help you reach your financial goals.</p>
          </div>
      </div>
      <Footer />
    </>
  )
}

export default FinalAbout;
