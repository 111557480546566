/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      dob
      address
      city
      state
      postalCode
      country
      netWorthOverOneMillion
      incomeOverTwoHundredThousand
      jointIncomeOverThreeHundredThousand
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      dob
      address
      city
      state
      postalCode
      country
      netWorthOverOneMillion
      incomeOverTwoHundredThousand
      jointIncomeOverThreeHundredThousand
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      fullName
      email
      phone
      dob
      address
      city
      state
      postalCode
      country
      netWorthOverOneMillion
      incomeOverTwoHundredThousand
      jointIncomeOverThreeHundredThousand
      owner
      createdAt
      updatedAt
    }
  }
`;
