import React, {useEffect} from 'react'
import AccreditedInvestor from '../components/AccreditedInvestor'
import {Button} from '../components/shared/ButtonClick'
import {Link} from 'react-router-dom'
import {useParams} from 'react-router-dom'

const CompanyName = 'MarketSpace'
const BankingPartner = '...'


// The <a> tags should be pdfs

const Invest = () => {
    useEffect(() => {window.scrollTo(0, 0)}, []);
  return (
    <div className="invest">
        <h3> Step 1: Personal Info </h3>

        <h4> Primary Address </h4>
        <p> We will use this information to generate tax documents for your investment. </p>
        <form>

            <label> First Name  </label>
            <input />
            <label> Middle Name </label>
            <input />
            <label> Last Name </label>
            <input />
            <label> Address </label>
            <input />
            <label> City </label>
            <input />
            <label> State </label>
            <input />
            <label> Zip Code </label>
            <input />

            Pursuant to Section 326 of the US PATRIOT Act, we are required by federal law to collect your Date of Birth and Social Security Number.

            <label> Date of Birth </label>
            <input />
            <label> SSN or Tax ID </label> 
            <input /> 
        </form>
        <h4> Secondary Address (Optional) </h4>
        <p> A backup address in the rare case we need it. This can be your legal counsel, financial advisor, or person with power of attorney. </p>
        <form>
            <label> Address  </label>
            <input />
            <label> City  </label>
            <input />
            <label> State  </label>
            <input />
            <label> Zip Code  </label>
            <input />
        </form>
        <h4> Phone Number </h4>
        <form>
            <label> Phone </label>
            <input />
        </form>
        

        <p> Your phone number will only be used to contact you about transactional matters. </p>
        <Button> Save </Button>
        <hr />
        <h3> Step 2: Accreditation </h3>
        <AccreditedInvestor />
        <h4> Upload Documents </h4> 
        
        <p> Please provide one of the following documents to verify your status as an <Link to="/resources/accreditation" target="_blank" rel="noreferrer">accredited investor</Link> or qualified purchaser. </p>
        
        <ul>
        
            <li>  W-2 (past two years) </li>

            <li> Tax Form 1040 (past two years) </li>

            <li> Letter from a broker-dealer, SEC-registered investment adviser, licensed attorney or CPA stating that you are an accredited investor (“a verification letter”) </li>

            <li> Individual BrokerCheck Reports (as applicable) </li>
        
        </ul>

        <Button> Upload Documents </Button> 
        

        <hr /> 
        {/*
        <h3> Step 3: Link Bank Account </h3>
        <p> Please link your bank account with Plaid. The account name must match the name on your {CompanyName} account. </p>

        <hr /> 
        <Button> Link with Plaid </Button>*/}
        <h3> Step 3: Read/Sign Documents </h3>
        <h4> Please attest to the following </h4>
        <ul> 
            <li className='li-label'> <label> <input type="checkbox" /> I am not investing with the intent to sell, distribute, or transfer the investment to any other persons. </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> I am not investing as an agent, nominee, trustee, partner, or otherwise on behalf of, for the account of, or jointly with any other person or entity. </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> No person or persons other than myself has a beneficial interest in the investment </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> I do not control, am not controlled by, nor am I under common control with, any other existing or prospective investor </label> </li>
        </ul>
        <p> If any statement(s) above do not apply to you, please explain why. We may contact you for further confirmation before you are able to invest in this deal. </p>
        <textarea> </textarea>

        <h4> Please attest to the following </h4>
        <ul> 
            <li className='li-label'> <label> <input type="checkbox" /> I am able to bear the economic risk of the proposed investment. </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> I have previously made an investment in a private investment fund in which the investment manager(s) was/were entitled to compensation based on the fund's investment performance. </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> I have sufficient knowledge and experience in financial and business matters as to be capable of evaluating the merits and risks associated with the investment. </label> </li>
        </ul>

        <h4> Please check each checkbox to confirm that you have read and agree to all terms in the investment documents. You should keep a copy of these documents for your records </h4>
        <ul> 
            <li className='li-label'> <label> <input type="checkbox" /> I have read and agree to the <a>Electronic Communications and Signature Agreement</a> </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> I have read and agree to the <a>Subscription Agreement</a> including the grant of Power of Attorney as described in Section VI. </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> I completed or reviewed the <a>Investor Verification Form</a> and agree with all of the information and agreements made therein. </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> Under penalties of perjury, I certify that I have completed or reviewed the <a>Tax Form</a> and the information it contains is true, complete, and correct. </label> </li>
            <li className='li-label'> <label> <input type="checkbox" /> By opening a deposit account, I agree that {CompanyName}'s banking partner, {BankingPartner}'s, <a> Deposit Agreement</a>. I understand that the bank services are pervided by {BankingPartner}'s bank partners, ... </label> </li>
        </ul>

        <h4> Please type your name to sign the Agreements and Disclosures listed above. </h4>
        <textarea></textarea>

        <Button> Save </Button>
        <hr /> 
        <h3> Step 4: Investment Amount </h3>

        <label> Investment Amount: </label>
        <input />
        <hr />
        <h3> Step 5: Link Bank Account </h3>
        <h4> Stripe Logic Here </h4>
        
        <hr />

        <h3> Step 6: Confirm Investment </h3>

        <p> If your accrediation status hasn't been verified yet, we will hold your spot until it is completed. If we are unable to verify your accrediation status, your funds will be released back to you. </p>
        
        <Button> Invest Now </Button>

        <br></br>
        <br></br>

        
    </div>
  )
}

export default Invest
