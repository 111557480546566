import styled from "styled-components";

export const Inner = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;

    @media(max-width: 600px){
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 25px;
    }
`

export const Fact = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FactIcon = styled.img`
    aspect-ratio: 1;
    width: ${(props) => props.width};
` 

export const FactTitle = styled.h2`
    font-size: ${(props) => props.size};
`

export const FactBody = styled.p`
    color: #202020;
    font-size: ${(props) => props.size};
`
export const BigTitle = styled.h1`
    text-align: center;
    
    padding: 10px;
    margin-bottom: 20px;
`