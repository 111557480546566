import styled from "styled-components"
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'


export const MainImage = styled.img`
  width: ${(props) => props.width};
  aspect-ratio: 16/9;

  @media(max-width: 1250px){
    width: ${(props) => props.mobileWidth};
  }
`

export const Container = styled.div`
`

export const GalleryContainer = styled.div`

`
export const ImgHolder = styled.div`
  position: relative;
  
`

export const Left = styled(FaArrowLeft)`
  position: absolute;
  top: 50%;
  left: 10px;
  color: #EFEFEF;
  z-index: 11;

  cursor: pointer;
  
  &:hover{
    color: white;
  }
`

export const Right = styled(FaArrowRight)`
  position: absolute;
  top: 50%;
  right: 10px;
  color: #EFEFEF;
  z-index: 11;

  cursor: pointer;

  &:hover{
    color: white;
  }
`


export const Scrollable = styled.div`
  width: ${(props) => props.width};
  overflow-y: hidden;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 2px;    
  
  -ms-overflow-style: none;  
  scrollbar-width: none;

  &::-webkit-scrollbar{
    display: none; 
  }

  @media(max-width: 1250px){
    width: ${(props) => props.mobileWidth};
  }

`

export const ScrollImage = styled.img`
  width: ${(props) => props.width};
  cursor: pointer;


`