import React, {useEffect} from 'react'

import MainNavbar from 'components/navbars/MainNavbar'
import Header from 'components/newmarketplace/Header'
import CardHolder from 'components/newmarketplace/CardHolder'

import {offerings} from 'testdata/data'

const NewMarketplace = () => {
  useEffect(() => {window.scrollTo(0, 0)}, []);
  return (
    <div>
        <Header />
        <CardHolder offerings={offerings} />
    </div>
  )
}

export default NewMarketplace