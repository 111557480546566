import React, { useState } from 'react'
import Video from 'videos/video.mp4'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, ArrowDown  } from './HeroElements'
import { Button } from 'components/shared/ButtonElement'

const HeroSection = (props) => {

  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  return (
  
    <HeroContainer className="home">
        <HeroBg>
            <VideoBg autoPlay loop muted playsinline src={Video} type='video/mp4' />
        </HeroBg>  
        <HeroContent>
          <HeroH1> Real Estate Investing Made Easy </HeroH1>
          <HeroP>
          We allow anyone to invest in real estate with as little as $20. 
          </HeroP>
          <HeroBtnWrapper>
            <Button to="/marketplace" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
                Get Started { hover ? <ArrowForward /> : <ArrowRight /> }
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
        <ArrowDown onClick={props.goDown} />
    </HeroContainer>
  )
}

export default HeroSection