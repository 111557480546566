import styled from 'styled-components'
import {Link} from "react-router-dom"


export const NavbarContainer = styled.nav`
    background-color: white;
    color: black;
    height: 100px;
    width: 100%;
    position: relative;
    
    padding: 0;
    margin: 0;
    display:grid;
    grid-template-columns: 1fr 1fr;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    box-shadow: -1px -1px 20px #ECEDEE;

    @media (max-width: 850px){
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 2;
        
    }

`

export const MobileSpacer = styled.div`
    height: 100px;
    display: none;
    @media(max-width: 850px){
        display: block;
    }
`
export const NavbarRight = styled.div`   
    
    justify-content: space-around;
    display: none;

    @media (min-width: 850px){
        display:flex;
    }
`

export const NavbarLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    

`

export const PageSel = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
`

export const TextLink = styled(Link)`
    color: black;
    text-decoration: none;

    &:hover{
    color: #5990FF;
    cursor: pointer;
    }
`
