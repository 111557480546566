import styled from "styled-components"

import Title from "components/newoffering/Title"

export const HeroHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-top: 25px;
    

    @media(max-width: 1250px){
        flex-direction: column;
        gap: 20px;
        margin-top: 12.5px;
    }
`

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    gap: 40px;

    @media(max-width: 1250px){
        flex-direction: row;
        max-width: 1250px;
        align-items: center;
        justify-content: center;
        gap: 100px;
    }

    @media(max-width: 670px){
        flex-direction: column;
        max-width: 500px;
        gap: 0px;
    }
`

export const MobileTitleHolder = styled.div`
    display: none;

    @media(max-width: 1250px){
        display: block;
    }
`

export const DesktopTitleHolder = styled.div`
    display: block;
    
    @media(max-width: 1250px){
        display: none;
    }
`