// import { Auth, API, graphqlOperation } from "aws-amplify"
import React, {useState, useEffect} from 'react'

import Dropdown from 'react-bootstrap/Dropdown'

import {NavbarContainer, NavbarLeft, TextLink, NavbarRight, PageSel, MobileSpacer} from './MainNavbarElements'
import {Button} from '../../shared/ButtonClick'
import Sidebar from '../../Sidebar'
import {SidebarMenuButton} from '../../Sidebar/SidebarElements'
import { SignUpButton } from 'components/navbars/HomeNavbar/HomeNavbarElements'
import { Auth } from 'aws-amplify'
import useUserStatus from 'components/utils/useuserstatus'

function changeBackground(e) {
    e.target.style.background = '#D10606'
} 

function restoreBackground(e) {
    e.target.style.background = 'red'
}

const Navbar = ( ) => {

    let styleTitle = { color: 'black', fontSize: 40, fontFamily: "Times"} 
    let styleTitleBlue = { color: '#5A92FF', fontSize: 40, fontFamily: "Times", fontWeight: "600"}
    let styleButton = {
        position: 'absolute',
        backgroundColor: 'red',
        top: '0px',        
        right: '0px',
        height: '50px',
        width: '50px',
        border: '0px',

    }

    const userStatus = useUserStatus();

    const isLoggedIn = (null !== userStatus);
  
    async function signOut() {
      try {
          await Auth.signOut();
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }
    

    return (
        <>
        <Sidebar />
        
        <NavbarContainer>
            <NavbarLeft>
                <TextLink to="/" className="text-link" style={ styleTitle }> <div style={{'display': 'flex', 'flex-direction': 'row'}} > Market <div style={styleTitleBlue}>Space</div> </div> </TextLink>
            </NavbarLeft>
            <NavbarRight>
                <PageSel> <TextLink to="/marketplace" className="text-link"> Marketplace </TextLink> </PageSel>
                <PageSel> <TextLink to="/resources" className="text-link"> Resources </TextLink> </PageSel>
                <PageSel> <TextLink to="/about" className="text-link"> About </TextLink> </PageSel>
                
                {
                isLoggedIn 
                ? 
                <>
                <PageSel> <SignUpButton onClick={signOut} > Sign Out </SignUpButton> </PageSel>
                </>
                : 
                <>
                <PageSel> <TextLink to="/signup" className="text-link"> <SignUpButton> Sign In </SignUpButton> </TextLink> </PageSel>
                </>
                }
                
            </NavbarRight>
        </NavbarContainer>
        <MobileSpacer />
        </> 
    )
}

export default Navbar