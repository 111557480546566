import React, {useState} from 'react'
import { Item, ItemHeader, ItemBody, ItemTitle } from './components'

const ResourceItem = ({title, body, color}) => {

    const [expanded, setExpanded] = useState(false);

  return (
    <Item color={color} expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <ItemTitle> {title} </ItemTitle>
        <ItemBody style={{display: expanded ? 'block' : 'none'}} dangerouslySetInnerHTML={{__html: body}} ></ItemBody>
    </Item>
  )
}

export default ResourceItem
