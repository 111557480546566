//import { Auth, API, graphqlOperation } from "aws-amplify"
import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom"

import Dropdown from 'react-bootstrap/Dropdown'
import styled from 'styled-components'

import { NavbarRight } from 'components/navbars/MainNavbar/MainNavbarElements' 
import { NavbarContainer, NavbarLeft, PageSel, TextLink, SignUpButton } from './HomeNavbarElements'

import Logo from "components/navbars/MainNavbar/msLogo"
import Sidebar from "components/Sidebar"
import useUserStatus from 'components/utils/useuserstatus'
import { Auth } from 'aws-amplify'

function changeBackgroundCool(e) {
    e.target.style.background = 'linear-gradient(to right, red, #480202)'
} 

function restoreBackgroundCool(e) {
    e.target.style.background = 'linear-gradient(to right, #480202, red)'
}


const CoolButton = ({ color, solidcolor, text, border, width, onMouseOver, onMouseLeave, textColor, onClick, opacity, className}) => {
    return (
      <button className={`btn ${className}`} onClick={onClick} style = {{ backgroundImage: color, backgroundColor: solidcolor, width: width, color: textColor, borderRadius: border, opacity: opacity}} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        {text}
      </button>
    )
}


const HomeNavbar = ( { user } ) => {

    let styleTitle = { color: 'white', fontSize: 40, fontFamily: "Times"} 

    const userStatus = useUserStatus();

    const isLoggedIn = (null !== userStatus);
  
    async function signOut() {
      try {
          await Auth.signOut();
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }

    return (
        <>
            <Sidebar color="white" />
            <NavbarContainer>
                
                
                <NavbarLeft>
                    <TextLink to="/" className="text-link" style={ styleTitle }> MarketSpace </TextLink>
                </NavbarLeft>
                
                <NavbarRight>
                    <PageSel> <TextLink to="/marketplace" className="text-link"> Marketplace </TextLink> </PageSel>
                    <PageSel> <TextLink to="/resources" className="text-link"> Resources </TextLink> </PageSel>
                    <PageSel> <TextLink to="/about" className="text-link"> About </TextLink> </PageSel>
                    {
                    isLoggedIn 
                    ? 
                    <>
                    <PageSel> <SignUpButton onClick={signOut} > Sign Out </SignUpButton> </PageSel>
                    </>
                    : 
                    <>
                    <PageSel> <TextLink to="/signup" className="text-link"> <SignUpButton> Sign In </SignUpButton> </TextLink> </PageSel>
                    </>
                    }
                    
                </NavbarRight>
                
            </NavbarContainer>
        </>
        )
}

export default HomeNavbar