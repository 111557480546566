import React, {useEffect, useRef} from 'react'
import {useParams} from 'react-router-dom'


import Title from 'components/newoffering/Title'
import Tags from 'components/newoffering/Tags'
import FastFacts from 'components/newoffering/FastFacts'
import InfoBox from 'components/newoffering/InfoBox'
import ReturnCalculator from 'components/newoffering/ReturnCalculator'
import Tour from 'components/newoffering/Tour'
import News from 'components/newoffering/News'
import Chart from 'components/newoffering/Chart'


import DevelopmentHero from 'components/newoffering/Hero/DevelopmentHero'
import TextSegment from 'components/newoffering/TextSegment'

import {Outer, Inner} from 'components/newoffering/Misc/components'
import { clampBuilder } from 'utils/utils'
import AuditedFinancials from 'components/newoffering/AuditedFinancials'
import PropertyManagement from 'components/newoffering/PropertyManagement'
import Footer from 'components/home/Footer'

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;

const DevelopmentOffering = ({offering}) => {


  useEffect(() => {window.scrollTo(0, 0)}, []);
  
  const goToCalculator = () => {
    scroller.scrollTo('ReturnCalculatorScroll', {
      duration: 1250,
      delay: 100,
      smooth: true,
      offset: 10, // Scrolls to element + 50 pixels down the page
    })
  }

  return (
    <>
      

      
      <DevelopmentHero offering={offering} goToCalculator={goToCalculator} />

      {/*<Tags />*/}

      <Outer>
        <Inner width={clampBuilder(360, 1680, 330/16, 1000/16)}>
          {/*<InfoBox />*/}
          <br/>
          <hr/>
          
          <FastFacts offering={offering} />
          
          <Element name="ReturnCalculatorScroll" />
          
          
          
          
          
          <News offering={offering} />
          <TextSegment title="Market Information" body="The real estate market in Manhattan Beach, California is currently a seller's market, with low inventory and high demand leading to competitive bidding and rising prices. The median sale price for homes in Manhattan Beach is around $2.5 million, with a price per square foot of around $1,000. The demand for real estate in Manhattan Beach is driven by the city's strong job market, highly rated schools, and proximity to the beach. Additionally, the limited supply of land in Manhattan Beach has also contributed to the high prices. Overall, the real estate market in Manhattan Beach is very competitive, with homes selling quickly and for above asking price. The commercial real estate market in Manhattan Beach is also thriving, with a strong demand for both retail and office space. The downtown area is a popular destination for shopping, dining, and entertainment, and as such, commercial real estate prices in this area are some of the highest in the city. However, there is also demand for commercial real estate in other areas of the city, particularly for office space. The strong job market and high quality of life in Manhattan Beach make it an attractive location for businesses, which drives demand for commercial real estate. As with the residential market, the limited supply of land in Manhattan Beach has contributed to high prices for commercial real estate. Overall, the commercial real estate market in Manhattan Beach is robust and competitive." />
          <TextSegment title="Business Plan" body="Our medical office building, located in the heart of Manhattan Beach, will offer a range of healthcare services to meet the needs of the local community. With easy access to public transportation and ample parking, our facility will be convenient for both patients and healthcare providers. Our target market includes residents of Manhattan Beach and the surrounding neighborhoods, as well as tourists visiting the area. In addition to traditional medical services, our building will feature a pharmacy, a fitness center, and a café, making it a one-stop destination for all healthcare needs.The building will be open Monday through Friday, from 9 AM to 5 PM, with extended hours available by appointment. We will offer a range of healthcare services, including primary care, specialist consultations, diagnostic testing, and minor surgical procedures. Our facility will be staffed by a team of highly trained and experienced medical professionals, including doctors, nurses, and support staff. We will also establish partnerships with local hospitals and other healthcare providers to offer a comprehensive range of services to our patients." />
          <PropertyManagement />
          <AuditedFinancials />

        </Inner>
      </Outer>
            
          <Footer />



    </>
  )
}

export default DevelopmentOffering