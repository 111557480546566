import React, {useState, useRef} from 'react'

import {SegmentHolder, Title} from 'components/newoffering/TextSegment/components'

import {SlideContainer, Slider, BarHolder, Caption, CaptionInfo, LabelHolder, Total, TotalHolder, TotalValue, SliderLabelTop, SliderLabelBottom} from './components'
import { useEffect } from 'react';

const useContainerDimensions = (myRef) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  
    useEffect(() => {
      const getDimensions = () => ({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight
      })
  
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
  };

const ReturnCalculator = ({offering}) => {

    const [rent, setRent] = useState(offering.currentRental*100);
    const [appreciation, setAppreciation] = useState(offering.currentAppreciation*100);

    const handleRent = (e) => {setRent(e.target.value);}
    const handleAppreciation = (e) => {setAppreciation(e.target.value);}

    let min = 2;
    let max = 8;

    const sliderRef = useRef(null);
    const {width, height} = useContainerDimensions(sliderRef);

    let minMaxNorm = (val) => {return((val - min)/(max-min))};

    let historicalRentalOffset = `${width * minMaxNorm(parseFloat(offering.historicalRental*100))}px`;
    let historicalRentalLabelOffset = `${width * minMaxNorm(parseFloat(offering.historicalRental*100)) - 65}px`;

    let currentRentalOffset = `${width * minMaxNorm(parseFloat(offering.currentRental*100))}px`
    let currentRentalLabelOffset = `${width * minMaxNorm(parseFloat(offering.currentRental)*100) - 30}px`;

    let historicalAppreciationOffset = `${width * minMaxNorm(parseFloat(offering.historicalAppreciation*100))}px`;
    let historicalAppreciationLabelOffset = `${width * minMaxNorm(parseFloat(offering.historicalAppreciation*100)) - 65}px`;
    
    let currentAppreciationOffset = `${width * (minMaxNorm(parseFloat(offering.currentAppreciation)*100))}px`;
    let currentAppreciationLabelOffset = `${width * minMaxNorm(parseFloat(offering.currentAppreciation*100)) - 30}px`;

    let step = 0.1;

  return (
    <SegmentHolder>
        <Title> Return Projection </Title>
        <br />
        <BarHolder>
            <LabelHolder> <Caption> Rent <br /> </Caption>  <CaptionInfo> {rent}% / year </CaptionInfo></LabelHolder>
            <SlideContainer>
                <SliderLabelTop left={currentRentalLabelOffset}> Current {offering.currentRental.toLocaleString("en-US", {style: "percent"})} </SliderLabelTop>
                <SliderLabelBottom left={historicalRentalLabelOffset}> Historical Average {offering.historicalRental.toLocaleString("en-US", {style: "percent"})} </SliderLabelBottom>
                <Slider type="range" step={step} min={min} max={max} value={rent} onChange={handleRent} ref={sliderRef} historical={historicalRentalOffset} current={currentRentalOffset}/>
            </SlideContainer>
        </BarHolder>
        <br />
        <br />
        <BarHolder>
            <LabelHolder> <Caption> Appreciation <br /> </Caption>  <CaptionInfo> {appreciation}% / year </CaptionInfo></LabelHolder>
            <SlideContainer>
                <SliderLabelTop left={currentAppreciationLabelOffset}> Current {offering.currentAppreciation.toLocaleString("en-US", {style: "percent"})} </SliderLabelTop>
                <SliderLabelBottom left={historicalAppreciationLabelOffset}> Historical Average {offering.historicalAppreciation.toLocaleString("en-US", {style: "percent"})} </SliderLabelBottom>
                
                <Slider type="range" step={step} min={min} max={max} value={appreciation} onChange={handleAppreciation} historical={historicalAppreciationOffset} current={currentAppreciationOffset}/>
            </SlideContainer>
        </BarHolder>
        <TotalHolder>
            <Total> Projected One Year Return: &nbsp;</Total> <TotalValue> {(parseFloat(rent) + parseFloat(appreciation)).toLocaleString("en-US", {maximumFractionDigits: 1})}% / year </TotalValue>
        </TotalHolder>


    </SegmentHolder>
  )
}

export default ReturnCalculator