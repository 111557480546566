import React from 'react'
import { useParams } from 'react-router-dom'

import DevelopmentOffering from 'pages/DevelopmentOffering'
import RentalOffering from 'pages/RentalOffering'

import {offerings} from 'testdata/data'

const NewOffering = () => {

    const {id} = useParams();
    const offering = offerings[id];

  return (
    <>
    {offering.class === 'rental' ?
        <RentalOffering offering={offering} /> :
        <DevelopmentOffering offering={offering} />
    }

    </>
  )
}

export default NewOffering