import styled from "styled-components"

export const Main = styled.h1`
    font-size: ${ (props) => props.size };
`

export const Location = styled.h3`
    line-height: 16px;
    margin-bottom: 0px;
    color: #4F4F4F;
`

export const TitleHolder = styled.div`
    margin-bottom: 16px;
    text-align: center;
    @media(max-width: 1250px){
        margin-bottom: 0px;
    }
`