import React, {useEffect, useState} from 'react'

import {SegmentHolder, Title} from 'components/newoffering/TextSegment/components'

import {NewsCardHolder, NewsCard, NewsSource, NewsTitle, NewsBody} from './components'

const News = ({offering}) => {
    const NewsAPIKey = 'e5d0cfe227a645daaa0ebb92a8fba211';

    const [fetchedNews, setFetchedNews] = useState({});

    useEffect(() => {
        
        const headers = {'X-Api-Key': 'e5d0cfe227a645daaa0ebb92a8fba211'}
        const query = `${offering.newsKeyWord}`
        const queryOptions = '&searchIn=title&language=en&sortBy=relevancy'
        const domains = '&excludeDomains=biztoc.com,dailymail.co.uk,wwd.com,theflightdeal.com,slickdeals.net'
        const endpoint = `https://newsapi.org/v2/everything?q=${query}${queryOptions}${domains}`

        fetch(endpoint, {headers} )
        .then(response => response.json())
        .then(data => {setFetchedNews(data)
            console.log(data)
        }
        )
        .catch(error => {
            console.error("Error Fetching News.")
            console.error(error)
            setFetchedNews({
                source: '',
                title: 'Error Fetching News!',
                body: 'Please try again in a few moments. If the problem persists, please contact us.'
            })
        })
    }, [])



  return (
    <SegmentHolder>
        <Title> News </Title>
        <NewsCardHolder>
            { fetchedNews.articles ? fetchedNews.articles.slice(0, 10).map( (news) => {
                return(
                    <a target="_blank" href={news.url} style={{textDecoration: 'none', cursor: 'pointer'}}>
                    <NewsCard>
                        <NewsSource> {news.source.name} </NewsSource>
                        <NewsTitle> {news.title} </NewsTitle>
                        <NewsBody> {news.description} </NewsBody>
                    </NewsCard>
                    </a>
                )
            }) : ''}
        </NewsCardHolder>
    </SegmentHolder>
  )
}

export default News