import MainNavbar from 'components/navbars/MainNavbar'
import Footer from 'components/home/Footer'

import Home from './components/legacy/Home'
//import Marketplace from './components/Marketplace'
import Marketplace from './pages/Marketplace'
import NewMarketplace from 'pages/NewMarketplace'

import Error from './components/Error'

import Terms from './components/Terms.js'
import Privacy from './components/privacy.js'

import VideoHome from './pages/VideoHome'
import NewHome from 'pages/NewHome'
import Offering from './pages/Offering'

import NewOffering from 'pages/NewOffering'

import Invest from './pages/Invest'

import Resources from './pages/Resources'
import Developers from './pages/Developers'
import Investors from './pages/Investors'

import Signup from 'pages/Signup'


import './styles/App.css';
import {BrowserRouter as Router, Routes, Route}  from "react-router-dom";
import React, { useState, useEffect} from 'react'



// import { listUsers } from './graphql/queries'
import HomeNavbar from 'components/navbars/HomeNavbar'
import SignIn from 'pages/SignIn'

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import InvestFlow from 'components/InvestFlow/InvestFlow'
import FinishProfile from 'components/FinishProfile/FinishProfile'
import CatalinaVillage from 'components/offeringCatalina/CatalinaVillage'
import CenturyCity from 'components/offeringCentury/CenturyCity'

import FinalAbout from 'pages/FinalAbout'
Amplify.configure(awsExports);

var _ = require('lodash');


function App({user, signOut}) {


  //const [user, setUser] = useState(undefined)

  // Check if user is logged in. If so, we can propogate their information to the pages from here.
  /*
  useEffect(() => {
    const onLoad = async () => {
        const users = await API.graphql(graphqlOperation(listUsers))
        if(!users.data.listUsers.items[0]){
            setUser(undefined)
            return;
        }
        // Returning user
        if(!_.isEqual(user, users.data.listUsers.items[0])){
          setUser(users.data.listUsers.items[0])
        }
    }
    onLoad()
  }, [])
  */

  return (
    <>
    
    <Router>
      <Routes>
        <Route path="/" element={<></>} />
        
        <Route path="*" element={<MainNavbar />} />
      </Routes>
    
      <Routes>
        <Route path="/oldhome" element={<VideoHome user={user} />} />
        <Route path="/" element={<NewHome />} />

        <Route path="/marketplace" element={<Marketplace user={user} />} />
        <Route path="/oldnewmarketplace" element={<NewMarketplace user={user} />} />
        <Route path="/newoffering" element={<NewOffering />} />
        <Route path="/newoffering/:id" element={<Offering />} />



        <Route path="/offering" element={<Offering /> } />
        <Route path="/invest/:id" element = {<Invest />} />
        <Route path="/about" element={<FinalAbout />} />

        <Route path="investors" element={<Investors />} />
        <Route path="developers" element={<Developers />} />

        <Route path="/finishprofile" element={<FinishProfile />} />

        <Route path="investflow/:offeringID" element={<InvestFlow />} />

        {/*<Route path="/profile" element={<Profile />}>
          <Route path="info" element={<ProfileInfo />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="investingpreferences" element={<InvestingPreferences />} />
          <Route path="emailpreferences" element={<EmailPreferences />} />
        </Route> */}
        
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<SignIn />} />
          
                  <Route path="/tos" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        
        <Route path="/resources" element={<Resources />} />
        <Route path="/offering/:id" element={<Offering />} />
        <Route path="*" element={<Error />} />

      </Routes>
      
    </Router>
    </>
  );
}

export default App;
