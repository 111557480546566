import styled, {keyframes} from 'styled-components'
import { slideInUp } from 'react-animations';
import {Link} from 'react-router-dom';

const accent = '#0A68EC'
const slideAnimation = keyframes `${slideInUp}`;


export const PropertyContainer= styled.div`
  display: flex;
  flex-direction:column;
  animation: 1s ${slideAnimation};
`

export const OfferingLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

export const PropertyBoxContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`

export const LearnMore = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 20px;

  background-color: rgba(0,0,0,0.8);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`



export const PropertyBox = styled(Link)`
  flex: 1 0 31%;
  margin: 5px;
  min-width: 280px;
  max-width: 25%;
  aspect-ratio: 1;
  
  background: #F5F5F5;
  position: relative;
  color: inherit;
  text-decoration: inherit;

  &:hover {
    transform: scale(1.03);
    box-shadow: -1px -1px 20px #ECEDEE;
    transition: all 0.5 ease;
    cursor: pointer;
  }

  &:hover ${LearnMore}{
    opacity: 0
  }

  
`

export const PropertyH1 = styled.h1`

font-size: 2.5em;


&::after{
  content: '';
  width:100%;
  height: 10px;
  background-color:${accent}; 
  display:block;
}

`
export const PropertyBoxHeading = styled.h2`
  font-size: 1.6em;
  font-weight: 800;
  text-align: center;

  &::after{
    content: '${({location}) => location}';
    display: block;
    font-size: .6em;
    color: #4D4D4D;
    font-weight: 700;
  }
  
`

export const PropertyStats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 10px;
  margin-right: 10px;
`

export const PropertyStat = styled.h1`
  font-size: 1.4em;
  text-align: center;
  font-weight: 800;

  &::after{
    content: '${({label}) => label}';
    font-size: .6em;
    display: block;
    color: #262626;
    font-weight: 600;
  }

`
export const InfoContainer = styled.div`
  height: 400px;
  background-color: black;
  
`

export const SortContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20%;
`

export const Input = styled.input`
  background-color: transparent;
`

export const SortButton = styled.button`
    background-color: transparent;
    border: 0;
    white-space: nowrap;
    &:hover{
        cursor: pointer;
    }
`

export const SortInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const SortByContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10%;
  
`

export const BuildingWrapper = styled.div`
    @media(max-width: 650px){
      display: none;
    }
`