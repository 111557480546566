import React from 'react'

const Error = () => {
  return (
    <div>
      <img src="https://d1owsou015wajw.cloudfront.net/us-west-1_jzHrvKAYC/6qu3ggval8t6hp72hvupon1l4n/20230209215637/assets/images/image.jpg" />
      <h1 style={{textAlign: 'center'}}> Under Development </h1>
    </div>
  )
}

export default Error
