import React from 'react'

import { SegmentHolder, Title, Body} from 'components/newoffering/TextSegment/components'
import { Line, Download } from './components'

import {FaDownload} from 'react-icons/fa'

const AuditedFinancials = () => {
  return (
    <SegmentHolder>
        <Title> Audited Financials </Title>
        <Body>
            <Line> Rent Roll <Download> Download PDF <FaDownload /> </Download>  </Line>
            <Line> Pro Forma <Download> Download PDF <FaDownload /> </Download> </Line>
        </Body>
    </SegmentHolder>
  )
}

export default AuditedFinancials