import React from 'react'
import testImg from '../../../photos/floorplans/test/test.png'

import { Cont, Floor, FloorplanFooter, Selector, SelectorContainer } from './FloorplanComponents'

const OfferingLayout = ( { property } ) => {

  return (
    <>
    <Cont>
        {property.floorplans.map( (floorImg, floorNumber) => {
            return (
                <Floor key={floorNumber} floorNumber={floorNumber} img={floorImg}>
                </Floor>
                
            )
        })}
    </Cont>
    <FloorplanFooter>
        {property.floorplans.map( (floorImg, floorNumber) => {
            return ( <img src={floorImg} height="300px" width="400px"/> )
        } )}
    </FloorplanFooter>
    </>

  )
}

export default OfferingLayout