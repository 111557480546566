import React from 'react'
import ResourceItem from '../ResourceItem'
import { TopicTitle, ItemHolder } from './components'

// Topic has fields
// title: A string, e.g. "Taxes"
// color: A string, e.g. "#5990FF"
// items: An array of Item objects. Each Item object has fields 
    // title, e.g. "How do I apply my tax advantages?"
    // body, e.g. "You can apply them by..."


// Component for rendering Topic objects
const ResourceTopic = ({topic}) => {
  return (
    <>
    <TopicTitle> {topic.title} </TopicTitle>
    <ItemHolder>
        {topic.items.map( (item) => {
                return(<ResourceItem title={item.title} body={item.body} color={topic.color} />)
            }
        )}
    </ItemHolder>
    </>
  )
}

export default ResourceTopic