import styled from 'styled-components'

export const SectionHolder = styled.div`
    margin-top: 20px;
`


export const SlideContainer = styled.div`
    width: 100%;
    position: relative;
`

export const SliderLabelTop = styled.div`
  position: absolute;
  left: ${ (props) => props.left};
  top: -25px;
`

export const SliderLabelBottom = styled.div`
  position: absolute;
  left: ${ (props) => props.left};
  bottom: -25px;
`

export const Slider = styled.input`
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    background: #04AA6D;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    position: relative;

  &::-webkit-slider-thumb{
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: black;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }

  &:hover{
    opacity: 1; /* Fully shown on mouse-over */
  }

  &::after{
    content: '';
    position: absolute;
    background-color: black;
    height: 150%;
    width: 2px;
    top: 0;
    left: ${(props) => props.historical};
  }

  &::before{
    content: '';
    position: absolute;
    background-color: black;
    height: 150%;
    width: 2px;
    bottom: 0;
    left: ${(props) => props.current};
  }


`
export const BarHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LabelHolder = styled.div`
  width: 200px;
  text-align: center;
`

export const Caption = styled.p`
  font-size: 20px;
  font-weight: bold;
`

export const CaptionInfo = styled.p`
  font-size: 20px;
  
`

export const Total = styled.h4`
font-weight: 400;
`

export const TotalValue = styled.h4`
font-weight: bold;
`

export const TotalHolder = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: 20px;
`