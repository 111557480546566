import styled from "styled-components"

import { Link } from "react-router-dom"

import { FaEye, FaEyeSlash } from "react-icons/fa"

export const Outer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
    height: 100vh;
    width: 100vw;
    @media(max-width: 400px){
        display: block;
        height: 100%;
    }
`

export const Card = styled.div`
    background-color: white;
    border-radius: 2px;
    height: 550px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    @media(max-width: 400px){
        width: 100%;
    }
`

export const UpperCard = styled.div`
    display: flex;
    flex-direction: column;
    
    text-align: center;
`

export const Subtext = styled.p`
    font-size: 1rem;
`

export const LowerCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export const FieldsCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
`

export const InputBox = styled.input`
    width: 300px;
    height: 50px;
    position: relative;
    border: 1px solid #D9D9D9;
    color: #424242;
    padding-left: 5px;
    padding-right: 5px;
`

export const SignUpCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 100%;
    align-items: center;
`

export const AlreadyHaveAnAccount = styled.p`
    font-size: 12px;
`

export const Blue = styled(Link)`
    font-size: 12px;
    font-weight: bold;
    color: #5990FF;
    cursor: pointer;
    text-decoration: none;
`

export const Eye = styled(FaEye)`
    position: absolute;
    right: 15px;
    bottom: 16px;
    cursor: pointer;
`

export const EyeSlash = styled(FaEyeSlash)`
    position: absolute;
    right: 15px;
    bottom: 16px;
    cursor: pointer;
`