import React from 'react'
import { SegmentHolder, Title, Body } from './components'

const TextSegment = ( {title, body} ) => {
  return (
    <SegmentHolder>
        <Title> {title} </Title>
        <Body> {body} </Body>
    </SegmentHolder>
  )
}

export default TextSegment