import styled from "styled-components";

export const Item = styled.div`
    border-radius: 21px;
    width: 130%;
    min-height: ${(props) => props.expanded ? 'auto' : '40px'};
    background-color: ${(props) => props.color};
    cursor: pointer;
    padding: .4rem 1rem;
    display: flex;
    flex-direction: ${(props) => props.expanded ? 'column' : 'row'};
    align-items: center;
    justify-content: center;
`

export const ItemTitle = styled.h3`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemBody = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`