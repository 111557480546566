import styled from 'styled-components'

export const TotalBar = styled.div`
    width: 100%;
    height: 10px;
    border: 0px;
    background-color: #D9D9D9;
    top: 0;
    left: 0;
`

export const FillingBar = styled.div`
    height: 10px;
    background: linear-gradient(to right, ${(props) => props.start}, ${(props) => props.end});
    position: absolute;
    top: 0;
    left: 0;
    
`

export const BarHolder = styled.div`
    position: relative;
`

export const MetricsHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #000000;
    font-family: Inter;
    font-size: 14px;
    padding-top: 5px;
`