
import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Header = styled.h2`
  text-align: center;
  font-size: 30px;
`;

const TextWrapper = styled.div`
  width: 80%;
  margin-left: 20%;
`;

const SubHeader = styled.h3`
  text-align: left;
  text-decoration: underline;
  margin-bottom: 5px;
`;

const Info = styled.p`
  text-align: left;
  margin-top: 5px;
`;

const Rectangle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 500px;
  margin-top: 20px;
  position: relative;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  color: white;
  background-color: ${props => props.color};
  flex: ${props => props.flex};
  border-top: 0.5px solid #528389;
  border-left: 1px solid #528389;
  border-right: 1px solid #528389;
`;

const Line = styled.div`
  position: absolute;
  width: 1px;
  background-color: black;
  right: -10px;
  top: ${props => `${props.top}%`};
`;

const Line0 = styled.div`
  position: absolute;
  left: -2px;
  width: 1px;
  background-color: black;
  top: ${props => props.top}%;
`;

const Percentage = styled.p`
  position: absolute;
  right: -30px;
  top: ${props => `${props.top}%`};
`;

const Percentage0 = styled.p`
  position: absolute;
  left: -150px;
  top: ${props => props.top}%;
`;

const FinancialSection = () => {
  const numbers = [0.5, 1.0, 2.8, 12.5];
  const colors = ["lightblue", "cornflowerblue", "skyblue", "powderblue"];
  const percentagesLeft = [25, 75];  // for the left lines
  const percentagesRight = [30, 70, 50, 50];

  const labels = ['Developer Equity', 'MarketSpace Investors', 'Other Equity', 'Construction Financing']

  return (
    <Section>
      <Header>Financial Breakdown</Header>
      <TextWrapper>
        <SubHeader>Capital Stack</SubHeader>
        <Info>Project Capitalization</Info>
      </TextWrapper>
      <Rectangle>
        {numbers.map((num, index) => (
          <Box key={index} flex={num} color={colors[index]}>
            ${num.toFixed(1)}M - {labels[index]}
          </Box>
        ))}
        <Line0 top={0} style={{height: `${percentagesLeft[0]}%`}} />
        <Percentage0 top={percentagesLeft[0]/2 - 5}>Equity - {percentagesLeft[0]}%</Percentage0>
        <Line0 top={percentagesLeft[0]} style={{height: `${percentagesLeft[1]}%`, left: '-10px'}} />
        <Percentage0 top={percentagesLeft[0] + percentagesLeft[1]/2 - 5}style = {{left: '-250px'}}>Financing & <br/>Construction Debt - {percentagesLeft[1]}%</Percentage0>

        {/* <Line right={0} style={{height: `${percentagesRight[0]}%`}} />
        <Percentage right={0} style={{top: `${percentagesRight[0]/2}%`}}>{percentagesRight[0]}%</Percentage>
        <Line right={10} style={{height: `${percentagesRight[1]}%`}} />
        <Percentage right={10} style={{top: `${percentagesRight[0] + percentagesRight[1]/2}%`}}>{percentagesRight[1]}%</Percentage>
        <Line right={20} style={{height: `${percentagesRight[2]}%`}} />
        <Percentage right={20} style={{top: `${percentagesRight[0] + percentagesRight[1] + percentagesRight[2]/2}%`}}>{percentagesRight[2]}%</Percentage>
        <Line right={30} style={{height: `${percentagesRight[3]}%`}} />
        <Percentage right={30} style={{top: `${percentagesRight[0] + percentagesRight[1] + percentagesRight[2] + percentagesRight[3]/2}%`}}>{percentagesRight[3]}%</Percentage> */}
      </Rectangle>
    </Section>
  );
};

export default FinancialSection;
