import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './FinishProfile.module.css'

import { API, graphqlOperation } from 'aws-amplify';
import { createProfile } from 'graphql/mutations'
import { Auth } from 'aws-amplify';
import { Link, Navigate } from 'react-router-dom';
import { FaUpload } from 'react-icons/fa';

const countries = ['United States', /* Add all other countries here */];

const labelFormat = {
  fullName: 'Full Name',
  email: 'Email',
  phone: 'Phone',
  dob: 'Date of Birth',
  address: 'Address',
  city: 'City',
  state: 'State',
  postalCode: 'Postal Code',
  country: 'Country',
};

const FinishProfile = (props) => {
  const [errorMsg, setErrorMsg] = useState('');
  

  const [filePicked, setFilePicked] = useState(false);

  const [popUp, setPopUp] = useState();

  const uploadHandler = () => {
    setFilePicked(true);
  }

  const formik = useFormik({
    initialValues: {
        fullName: '',
        email: '',
        phone: '',
        dob: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        netWorthOverOneMillion: false,
        incomeOverTwoHundredThousand: false,
        jointIncomeOverThreeHundredThousand: false,
      },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone: Yup.string().required('Required'),
      dob: Yup.string().matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Invalid date format').required('Required'),
      address: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      postalCode: Yup.number().required('Required'),
      country: Yup.string().required('Required'),
    }),


    onSubmit: async (values) => {
        if (values.country !== 'United States') {
          setErrorMsg('MarketSpace is currently only available to US residents');
        } else {
          try {
            
            const user = await Auth.currentAuthenticatedUser();
            const profile = { ...values, id: user.username, owner: user.username };
            await API.graphql(graphqlOperation(createProfile, { input: profile }));
            setPopUp(true);
          } catch (error) {
            console.log('Error creating profile:', error);
          }
        }
    }
});

const handleSkip = () => {
  props.history.push('/marketplace');
};

const formFields = Object.keys(formik.values).filter((field) => field !== 'country' && field != 'netWorthOverOneMillion' && field !== 'incomeOverTwoHundredThousand' && field !== 'jointIncomeOverThreeHundredThousand');
const isFormValid = formik.isValid && formik.dirty;


return (

    <div className={styles.container}>
      <h1>Finalize Profile</h1>
      <p>This information will be used to generate legal and tax documents for your investments.</p>
      <form onSubmit={formik.handleSubmit}>
        {/* Form fields except country */}
        {formFields.map((field) => (
          <div className={styles.formField} key={field}>
            <label htmlFor={field}>{labelFormat[field]}</label>
            <div className={styles.inputContainer}>
              <input
                id={field}
                name={field}
                type={field === 'dob' ? 'text' : 'input'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={formik.touched[field] && formik.errors[field] ? styles.inputError : ''}
                value={formik.values[field]}
              />
              
            </div>
          </div>    
        ))}
        <div className={styles.formField}>
          <label htmlFor="country">Country</label>
          <div className={styles.inputContainer}>
            <select id="country" name="country" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.country} >
              <option value="">Select your country</option>
              {countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
          
        </div>
        <p>Some of our investments are currently only avaiable to accredited investors. If you are accredited, please testify to all of the options below that apply to you. </p>
        {errorMsg && <div className={styles.countryErrorMsg}>{errorMsg}</div>}
        
              <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id="netWorthOverOneMillion"
          name="netWorthOverOneMillion"
          checked={formik.values.netWorthOverOneMillion}
          onChange={formik.handleChange}
        />
        <label htmlFor="netWorthOverOneMillion">
        An individual with a net worth, or a joint net worth together with his or her spouse, in excess of $1,000,000. (In calculating net worth, you may include equity in personal property and real estate (however, you cannot include your primary residence), cash, short term investments, stock and securities. Equity in personal property and real estate (excluding your primary residence) should be based on the fair market value of such property minus debt secured by such property.)
        </label>

      </div>
      {formik.values.netWorthOverOneMillion ? 
      <>
      <input onChange={uploadHandler} type="file" id="W2"  /> <label for="W2" style={{cusor: "pointer", marginBottom: "10px"}}> <FaUpload /> Please upload a bank statement, brokerage statement, certificate of deposit, or other means of verifying your assets. </label>
      </>: ""
      
      }


      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id="incomeOverTwoHundredThousand"
          name="incomeOverTwoHundredThousand"
          checked={formik.values.incomeOverTwoHundredThousand}
          onChange={formik.handleChange}
        />
        <label htmlFor="incomeOverTwoHundredThousand">
        An individual that had an individual income in excess of $200,000 in each of the prior two years and reasonably expects an income in excess of $200,000 in the current year. (In calculating net income, you may include earned income and other ordinary income, such as interest, dividends and royalties.)
        </label>
      </div>

      {formik.values.incomeOverTwoHundredThousand ? 
      <>
      <input onChange={uploadHandler} type="file" id="W2"  /> <label for="W2" style={{cusor: "pointer", marginBottom: "10px"}}> <FaUpload /> Please upload a W-2, 1099, K-1, or 1040 </label>
      </>: ""
      
      }

      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          id="jointIncomeOverThreeHundredThousand"
          name="jointIncomeOverThreeHundredThousand"
          checked={formik.values.jointIncomeOverThreeHundredThousand}
          onChange={formik.handleChange}
        />
        <label htmlFor="jointIncomeOverThreeHundredThousand">
        An individual that had with his/her spouse joint income in excess of $300,000 in each of the prior two years and reasonably expects joint income in excess of $300,000 in the current year. (In calculating net income, you may include earned income and other ordinary income, such as interest, dividends and royalties.)
        </label>
      </div>

      {formik.values.jointIncomeOverThreeHundredThousand ? 
      <>
      <input onChange={uploadHandler} type="file" id="W2"  /> <label for="W2" style={{cusor: "pointer", marginBottom: "10px"}}> <FaUpload /> Please upload a W-2, 1099, K-1, or 1040 </label>
      </>: ""
      
      }
      <br/>
      <div className={styles.checkboxContainer}>
        <input type="checkbox" id="tos" name="tos"/>
            <label htmlFor="tos">
            <p style={{margin: 0, padding: 0}}>By checking this box you agree to <a href=""> Our Terms of Service </a> and <a href=""> Privacy Policy</a>, as well as our partner <a href=""> Dwolla's Terms of Service </a> and <a href=""> Privacy Policy </a>.</p>
            </label>

            </div>

        <div>
        <button className={`${styles.saveBtn} ${isFormValid ? styles.enabled : ''}`} type="submit" disabled={!isFormValid}>
          Save
        </button>

        </div>
      </form>

    
      { popUp && (
          <div className="info-popup" onClick={() => setPopUp(false)}>
          <div className="info-popup-content" onClick={e => e.stopPropagation()}>
            <p>
              <h2> Important </h2>
              All of our current investments require confirmation of status as an accredited investor. 
              We are currently sharing the site with a few investors we have already confirmed are accredited. As we are only sharing the site with these select few for initial feedback, we are not restricting accounts that don't have their documents verified.
              However, if you were not given express permission to try making an investment by a member of our team, please wait until you recieve an email from us confirming that we have verified your status.
              <br/>
              <br/>
              MarketSpace is not offering securities, nor will issue securities, to any investor who is non-accredited. No subscription agreements will be executed until your documents have been verified. 
              <br />
              <br />
              Thank you! We're looking forward to hearing your thoughts.
              <br/>
              <br/>

              <Link to="/marketplace" >
                <button className={`${styles.saveBtn} ${styles.enabled}`} style={{margin: 0}}> View the MarketPlace </button>
              </Link>
            </p>
          </div>
          </div>
      )
      }
    </div>

);
              
};

export default FinishProfile;

