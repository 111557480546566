import ProgressBar from 'components/newoffering/ProgressBar'
import React from 'react'
import { Card, ImageHolder, Title, Location, TagsHolder, BarHolder, LowerTagsHolder } from './components'

import mmc1 from 'photos/propertyphotos/0/mmc1.jpeg'

import Tag from 'components/shared/Tags/Tag'

import {clampBuilder} from 'utils/utils'

const OfferingCard = ( {offering} ) => {
  return (
    <Card height={clampBuilder(360, 1680, 335/16, 335/16)} width={clampBuilder(360, 1680, 330/16, 450/16)}>
        <ImageHolder>
            <TagsHolder>
                {offering.upperTags.map(tag => {
                    return(
                        <Tag text={tag.text} subText={tag.subText} color={tag.color} fontSize='15px' image={tag.image}/>
                    )
                })}
            </TagsHolder>
            <img src={offering.photos[0]} width={380*1.2} height={175*1.2} />
            <LowerTagsHolder>
                {offering.lowerTags.map(tag => {
                    return(
                        <Tag text={tag.text} subText={tag.subText} color={tag.color} fontSize='15px' image={tag.image} />
                    )
                })}
            </LowerTagsHolder>
        </ImageHolder>
        
        <Title> {offering.name} </Title>
        <Location> {offering.location} </Location>
        <BarHolder> <ProgressBar progress={offering.progress} investors={offering.investors} /> </BarHolder>
    </Card>
  )
}

export default OfferingCard
