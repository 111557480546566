import React, { useState, useEffect } from 'react'
import {properties as ps} from './data'

import {offerings} from 'testdata/data'
import {Link} from 'react-router-dom';

import Building from 'components/shared/Building'
import {OfferingLink, PropertyContainer, PropertyH1, PropertyBoxContainer, PropertyBox, PropertyBoxHeading, PropertyStats, PropertyStat, LearnMore, SortContainer, Input, SortButton, SortInner, SortByContainer} from './MarketplaceElements'
import { sortBy } from 'lodash'


import OfferingCard from 'components/shared/OfferingCard'
import Header from 'components/newmarketplace/Header'
import Footer from 'components/home/Footer'

const MarketplaceApp = () => {

  
  const [isLoading, setLoading] = useState('true')

  const [properties, setProperties] = useState(offerings)

  // Search and filter things
  const [search, setSearch] = useState('')
  const [IRRClicked, setIRRClicked] = useState('off')
  const [capClicked, setCapClicked] = useState(true)
  const [targetHoldClicked, setTargetHoldClicked] = useState(false)


const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

  const typedInSearch = (e) => {
    setSearch(e.target.value)
    const checkProperty = (p) => {
      return (p.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
              p.location.toLowerCase().includes(e.target.value.toLowerCase()))
    }
    setProperties(ps.filter(checkProperty))
  }

  const sortingOff = () => {
    setIRRClicked('off')
    setCapClicked('off')
    setTargetHoldClicked('off')
  }

  const toggleSortingTargetHold = () => {
    if(targetHoldClicked === 'off'){
      sortingOff()
      setTargetHoldClicked('up')
      setProperties(properties.sort((a, b) => {
        return parseFloat(b.targetedHold) - parseFloat(a.targetedHold)
      }))
    }else if(targetHoldClicked === 'up'){
      sortingOff()
      setTargetHoldClicked('down')
      setProperties(properties.sort((a, b) => {
        return parseFloat(a.targetedHold) - parseFloat(b.targetedHold)
      }))
    }else if(targetHoldClicked === 'down'){
      sortingOff()
    }
  }

  const toggleSortingIRR = () => {
    if(IRRClicked === 'off'){
      sortingOff()
      setIRRClicked('up')
      setProperties(properties.sort((a, b) => {
        return b.targetIRR - a.targetIRR
      }))
    }else if(IRRClicked === 'up'){
      sortingOff()
      setIRRClicked('down')
      setProperties(properties.sort((a, b) => {
        return a.targetIRR - b.targetIRR
      }))
    }else if(IRRClicked === 'down'){
      sortingOff()
      setIRRClicked('off')
    }
  }

  const toggleCapitalization = () => {
    if(capClicked === 'off'){
      sortingOff()
      setCapClicked('up')
      setProperties(properties.sort((a, b) => {
        return b.marketCap - a.marketCap
      }))
    }else if(capClicked === 'up'){
      sortingOff()
      setCapClicked('down')
      setProperties(properties.sort((a, b) => {
        return a.marketCap - b.marketCap
      }))
    }else if(capClicked === 'down'){
      sortingOff()
    }
  }

  // from so



  useEffect( () => {
    setTimeout(() => setLoading(false), 1500);
  }) 

  return (

    <>
    
    {isLoading ? <Building></Building> : ''}
    {isLoading ? '' : 
    <PropertyContainer>
      <Header />
      {/*
      <SortContainer>
        <SortInner>
          <label> Search: </label>
          <Input value={search} onChange={(e) => typedInSearch(e)}/> 
        </SortInner>
        <SortInner>
        <label> Sort By: </label>
            <SortByContainer>
              <SortButton onClick={toggleSortingIRR}> IRR {IRRClicked} </SortButton>
              <SortButton onClick={toggleSortingTargetHold}> Target Hold {targetHoldClicked} </SortButton>
              <SortButton onClick={toggleCapitalization}> Capitalization {capClicked} </SortButton>
            </SortByContainer>
        </SortInner>
      </SortContainer>
  */}
      <PropertyBoxContainer>
        
        {/*properties.map( (property, i) => {
          return (<PropertyBox key={i} to={`/offering/${property.id}`} >
            <img height='40%' width='100%' src={property.photos[0]} />
            <PropertyBoxHeading location={property.location}> {property.name} </PropertyBoxHeading>
            
        </PropertyBox>)
        })*/}

        {properties.map( (offering, i) => {
          return (<OfferingLink to={`/newoffering/${i}`}> <OfferingCard offering={offering} /> </OfferingLink>)
        })}

        
        
      </PropertyBoxContainer>
      
    </PropertyContainer>}
    
  </>
  )
}

export default MarketplaceApp
