import styled from "styled-components"


/*export const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 0.5rem;
    background-color: #5990FF;
`

export const Inner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    width: ${ (props) => props.width};
    flex-wrap: wrap;
    @media (max-width: 900px){
        flex-wrap: wrap;
    }
    
`*/

export const Outer = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    gap: 2rem;

`

export const Inner = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
    width: 100%;
    justify-content: space-around;
    max-width: 1000px;

`

export const SectionTitle = styled.h1`
    color: #1F2937;
    font-weight: bold;
    font-size: ${ (props) => props.size};
    padding: 0;
    margin: 0;
    position: relative;

    &:after{
        position: absolute;
        bottom: 0px;
        right: 0;
        content: '';
        background-color: white;
        height: 2.5px;
        width: 100%;
    }
    
`

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: ${ (props) => props.width};
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid #F2F2F2;
    
`

export const StepTitle = styled.h1`
    color: #1F2937;
    font-weight: 500;
    font-size: ${ (props) => props.size};
    padding: 0;
    margin: 0;
`

export const StepBody = styled.p`
    color: #1F2937;
    font-size: ${ (props) => props.size};
    padding: 0 1rem;
    margin: 0;
`

export const StepImg = styled.img`
    width: 100%;
    object-fit: cover;
    height: 225px;
`