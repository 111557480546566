import styled from "styled-components";

export const TopicTitle = styled.h2`
`

export const ItemHolder = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

