import React from 'react'
import { TitleHolder, Main, Location } from './components'

import { clampBuilder } from 'utils/utils'

const Title = ( {offering} ) => {
  return (
    <TitleHolder>
      <Main size={clampBuilder(360, 1680, 1.4, 2.2)}> {offering.name} </Main>
      <Location> {offering.location} </Location>
    </TitleHolder>
  )
}

export default Title