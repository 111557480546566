import React, {useEffect} from 'react'

import MainNavbar from 'components/navbars/MainNavbar'

const ForInvestors = () => {
  useEffect(() => {window.scrollTo(0, 0)}, []);
  return (

    <>
      
      <div>
        For Investors
      </div>
    </>

  )
}

export default ForInvestors
