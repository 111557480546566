import React from 'react'
import ruler from 'photos/homeextraphotos/blueprintruler.jpeg'
import {Link} from 'react-router-dom'
import {Button} from './button'

const BluePrint = () => {
    return (
        <div className="container4home" >
            <div style = {{padding: '100px', border: "2px dotted"}}>
        <span>
            <div className="picture" >
                <img alt = 'blueprint' src = { ruler } style={{ maxHeight: '100%', maxWidth: '100%'}} />
            </div>
        </span> 
        <span>
            <header className="homebigtxt">Expand your portfolio without fees. </header>  
            <p> Enjoy the stability and tax benefits of real estate, just like the 1%. </p>
            <Link to="/resources"><Button solidcolor = { 'black' } textColor = {'white'} text = {'Learn More'} border = '20px'></Button></Link>
        </span>
        </div>
    </div>
    )
}

export default BluePrint