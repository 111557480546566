import React from 'react'

const Logo = ({mColor}) => {
    let styleTitle = { color: mColor ? mColor : 'black', fontSize: 40, fontFamily: "Times"} 
    let styleTitleBlue = { color: '#5A92FF', fontSize: 40, fontFamily: "Times", fontWeight: "400"}

  return (
    <>
        <div style={{'display': 'flex', 'flex-direction': 'row'}} > 
            <div style={ styleTitle }> Market </div> 
            <div style={styleTitleBlue}>Space</div> 
        </div> 
    </>
  )
}

export default Logo