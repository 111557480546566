import styled from 'styled-components'
import {InfoBorder} from 'components/home/InfoSection/InfoComponents'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    
        background-color: white;
    color: black;
    padding: 2rem;
    height: 100%;
    
    @media(max-width: 670px){
        flex-direction: row;
        padding: 1.1rem;
        gap: 30px;
    }
`

export const InputBox = styled.input`
    width: ${(props) => props.width};
    height: 35px;
    text-align: center;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style:groove;
    background-color: white;
    font-size: ${(props) => props.size};
    font-weight: 200;
    font-family: tahoma;
    color: black;
    -webkit-appearance : none;
`

export const InvestButton = styled.button`
    border: 1px solid #5990FF;
    background-color: #5990FF;
    color: white;
    border-radius: 15px;
    height: 50px;
    padding: 5px 50px;
    cursor: pointer;
    width: ${(props)=>props.width};

    &:hover{
        background-color: #2D73FF;
    }
    @media(max-width: 670px){
        height: 65px;
    }
`

export const HorizSpan = styled.span`
    display:flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center;
    gap: 50px; 
    font-family: Inter;
    @media(max-width: 670px){
        gap: 30px;
    }
`

export const Bolded = styled.h2`
    font-weight: bold;
    font-size: ${(props) => props.size};
    
`

export const ButtonLabelHolder = styled.div`
    display:flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center; 
    gap: 10px;
    @media(max-width: 670px){
        flex-direction: column;
        gap: 5px;
    }
`

export const Unbolded = styled.h2`
    font-weight: 400;
    color: #808080;
    font-size: ${(props) => props.size};
`

export const SharePriceHolder = styled.span`
    text-align: center;
    font-family: Arial;
`

export const InvestBorder = styled(InfoBorder)`

    background: white;/*linear-gradient(to bottom, orange, red);*/

    @media(max-width: 670px){
        margin-top: 30px;
    }
`