import React, { useState } from 'react';
import './ESignature.css'; // Assuming you have a CSS file for styling

function ESignature(props) {
    
    const fullName = props.profile.fullName;

    const [input, setInput] = useState('');
    

    const handleChange = (event) => {
        setInput(event.target.value);
        props.setSigned(event.target.value === fullName);
    };

    return (
        <div className="signature-box">
            <p>Type your name exactly as it appears here, including capitalization: <b>{fullName}</b></p>
            <input 
                className={`signature-input ${props.signed === true ? 'valid' : props.signed === false ? 'invalid' : ''}`} 
                type="text"
                value={input}
                onChange={handleChange}
                style={{fontFamily: 'cursive'}}
            />
            {props.signed === false && <p>Your input does not match the full name. Check for capitalization. </p>}
        </div>
    );
}

export default ESignature;
