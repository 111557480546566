import React, { useState, useEffect } from 'react';
import './CenturyCity.css';
import Footer from 'components/home/Footer'
import CenturyCover from 'photos/10285 Missouri Ave Century City/cc.jpg'
// import OldBuilding from 'photos/oldTownCondo.svg'
import infoButton from 'photos/blueinfoicon.png'
import './gallery.css';
import garage from 'photos/10285 Missouri Ave Century City/Garage.png'
import floor1 from 'photos/10285 Missouri Ave Century City/First Floor.png'
import floor2 from 'photos/10285 Missouri Ave Century City/Second Floor.png'
import floor3 from 'photos/10285 Missouri Ave Century City/Third Floor.png'
import floor4 from 'photos/10285 Missouri Ave Century City/Fourth Floor.png'
import floor5 from 'photos/10285 Missouri Ave Century City/Fifth Floor.png'
import floor6 from 'photos/10285 Missouri Ave Century City/Sixth Floor.png'
import PhotoGallery from './PhotoGallery';
import warning from 'photos/clipboardwarning.svg'
import moneygrowth from 'photos/group83.svg'
import moneycircle from 'photos/group79.svg'
import FinancialSection from './financials';
import SourcesOfFundsSection from './SourcesofFunds';
import UsesOfFundsSection from './UsesofFunds';
import distribution from 'photos/Catalina/distribution.png'
import CashFlow from './cashflow';
import BCCTeam from 'photos/Catalina/BCCTeam.png'
import BoardAdvisors from 'photos/Catalina/BoardAdvisors.png'
import ThirdParty from 'photos/Catalina/ThirdPartyConsultants.png'
import FloorPlans from './floorplans';
import floorplan1 from 'photos/Catalina/floorplan1.png'
import floorplan2 from 'photos/Catalina/floorplan2.png'
import floorplan3 from 'photos/Catalina/floorplan3.png'
import floorplan4 from 'photos/Catalina/floorplan4.png'
import conceptualrender1 from 'photos/Catalina/conceptualrender1.png'
import conceptualrender2 from 'photos/Catalina/conceptualrender2.png'
import elevation from 'photos/Catalina/elevation.png'
import siteplan1 from 'photos/Catalina/siteplan1.png'
import siteplan2 from 'photos/Catalina/siteplan2.png'
import Watermark from 'photos/10285 Missouri Ave Century City/watermark.png'
import { FaBook, FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';



const CatalinaVillage = ({ imagePath }) => {

  const [shares, setShares] = useState(10);
  const offeringID = 1;

  const handleSharesChange = (e) => {
    setShares(e.target.value);
  };

  const calculateInvestment = () => {
    return (10.30 * shares).toFixed(2);
  };

  const [showPopup, setShowPopup] = useState(false);

  const [showCheckSoon, setShowCheckSoon] = useState(false);

  const [showAnnualReturn, setShowAnnualReturn] = useState(false);
const [showAnnualDividend, setShowAnnualDividend] = useState(false);
const [showOfferingSize, setShowOfferingSize] = useState(false);


  const investors = '2';
  const fundedPercentage = '1';

  
  const photoList = [CenturyCover, garage, floor1, floor2, floor3, floor4, floor5, floor6];

  const data = [
    { value: 2.3, color: '#ADD8E6' },
    { value: 11, color: '#B0E0E6' },
    { value: 11, color: '#87CEEB' },
    { value: 22.5, color: '#87CEFA' },
  ];
    const [showConfirmation, setShowConfirmation] = useState(false);

  const handleNotifyClick = e => {
    e.stopPropagation();
    setShowConfirmation(true);
  };

  const photoUrls = [floorplan1, floorplan2, floorplan3, floorplan4  ];
    
  return (
    <>
    <div className="catalina-village">
      <div className="left-section">
        <div className="left-content">
          <h1 className="title">The Century City <br /> Apartments</h1>
          <p className="location">Los Angeles, CA</p>
          <a href="https://ms-property-images.s3.us-west-1.amazonaws.com/Century+City+Memorandum.pdf" style={{textDecoration: 'none', color: 'black'}}> <FaBook/> View Project Memorandum</a> 
          <a href="https://ms-property-images.s3.us-west-1.amazonaws.com/Century+City+Pro+Forma.xlsx" style={{textDecoration: 'none', color: 'black'}}> <FaChartLine/> Download Pro Forma</a>
        </div>
        <div className="left-content-bldgback">
          {/* <img src={OldBuilding} style={{marginRight: '0px', marginLeft: 'auto', opacity: '60%'}}/> */}
          <div className="left-content-boxes">
            <div className="info-boxes" >
              <div className="info-item">
                <h3>Offering Size</h3>
                <p>$1,000,000</p>
              </div>
              <div className="info-item">
                <h3>3-Yr EM</h3>
                <div className="info-text-button-1"> {/* Add this div */}
                  <p>1.86x</p>
                  <button onClick={() => setShowAnnualDividend(true)}>
                    <img src={infoButton} alt="Info" className="info-icon-2" />
                  </button>
                </div>
              </div>
              <div className="info-item">
                <h3>3-Yr IRR</h3>
                <div className="info-text-button-1"> {/* Add this div */}
                  <p>24.92%</p>
                  <button onClick={() => setShowAnnualReturn(true)}>
                    <img src={infoButton} alt="Info" className="info-icon-2" />
                  </button>
                </div>
              </div>
              {/* <div className="info-item">
                <h3>Annual Dividend</h3>
                <p>14.83% </p>
              </div>
              <div className="info-item">
                <h3>Annual Return</h3>
                <p>17%</p>
              </div> */}
              <Link to={`/investflow/${offeringID}`} style={{textDecoration: 'none'}}>
              <button className='invest-item'>
                <h3>Buy Shares</h3>
              </button>
              </Link>
            </div>
            </div>
        </div>
      </div>
      <div className="right-section">
        <img src={CenturyCover} alt="Building" />
      </div>
    </div>

            {/* New div with two halves */}
      <div className="progress-section">
        <div className="left-progress">
          <h2>Offering Progress</h2>
        </div>
        <div className="right-progress">
          <div className="progress-item">
            <h4>Investors: {investors} {/* Replace with dynamic value */}</h4>
            
          </div>
          <div className="progress-item">
            <h4>Progress</h4>
            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: "5%" }}> {/* Replace 50% with dynamic value */}
                {fundedPercentage}% {/* Replace with dynamic value */}
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* New photo gallery section */}
      <PhotoGallery photoList={photoList} />
      <div className="highlights-section">
        <h1>Highlights</h1>
        <p>
        The Watermark Group (Watermark) is pleased to present this opportunity to invest in a six-story, 20-
unit for-rent multi-family apartment project with TOC (Transit Oriented Community) Incentives. The
project includes six 1-Bedroom and fourteen 2-Bedroom units including 2 rent restricted units
qualifying the project for the TOC Affordable Hosing Incentives Program. Additionally, there is the
possible opportunity to add two additional ADU units for a total of 22 residential units. Watermark
has already received LADBS Plan Check Approval for Zoning
Watermark is currently offering accredited investors an opportunity to invest in this development
project. Upon stabilization, this Investment is projected to yield a 24.92% Investor IRR and 1.86x
Investor Equity Multiple upon stabilization over an approximate three-year period.
        </p>
      </div>
      <div>
      <div className='catalina-village-highlights'>
      <div className='left-section-highlights'>
        <h2> Progress Overview</h2>
        <p>
        After working with the design team, legal team, and city planning for the past 5 years, Watermark
has secured LADBS Plan Check Approval for Zoning. The project has satisfied the requirements for
the TOC (Transit Oriented Community) Housing Incentive Program and by keeping the property
vacant for over 5-years, we have satisfied all the requirements to be exempt from the Ellis Act
regarding rent control. Final construction drawings and engineering are expected to be complete by
the end of Q2 of 2023. Construction to commence in Q4 of 2023 or sooner pending the receipt of
construction permits.
        </p>
      </div>
      <div className='right-section-2'>
        <img src={warning} alt="warning"></img>
      </div>
      </div>
      <div className='catalina-village-highlights'>
      <div className='left-section-highlights' style={{width:'40%'}}>
        <img src={moneygrowth} alt="warning" ></img>
      </div> 
      <div className='right-section-text' style={{width:'60%'}}>
        <h2> Strong Return Profile</h2>
        <p>
        This Investment is projected to yield a 24.92%+ Investor IRR and 1.86x Equity Multiple over a three
year period. For example, after stabilization, if the property were sold at a 4% cap, a $250,000
investment is projected to return a total of $465,000 in approximately 3 years.
          </p>
      </div>

      </div>
      <div className='catalina-village-highlights'>
      <div className='left-section-highlights'>
        <h2> High Demand Location</h2>
        <p>
        Located adjacent to the Westfield Century City Mall (renovated in 2017 at a cost reported to be over
$1 billion), local rents for Class-A residential units are between $4-$5+ PSF. This prime in-fill location
is in the heart of Century City and within walking distance to high-end shopping, a wide variety of
restaurants and the Century City Office Corridor.
</p>
      </div>
      <div className='right-section-2'>
        <img src={moneycircle} alt="warning" ></img>
      </div>
      </div>
      {/* <FloorPlans photoList={photoUrls} /> */}
      <FinancialSection data={data} />
      </div>
      <div>
        <SourcesOfFundsSection />
      </div>
      <div style= {{marginTop: '60px'}}>
        <UsesOfFundsSection />
      </div>
      <div className='catalina-village-highlights'>
      <div className='left-section-highlights'>
       <h2 style = {{color: '#277DFF'}}> Distributions</h2> 
       <p style = {{color: '#277DFF'}}>Beach City Capital Management intends to make distributions in the following three phases: </p>
        <p style = {{fontSize: '13px'}}>1. At Refinance - To the investors, pari passu, all proceeds at refinance to an 8% cumulative preferred return, then remaining upside split 70% to investors / 30% to sponsor thereafter.
        <br/>2. During Cashflows - To the investors, pari passu, all proceeds during cashflows to a 5% preferred return, then remaining upside split 70% to investors / 30% to sponsor thereafter.
        <br/>3. At Sale - To the investors, pari passu, all proceeds at sale to a 8% IRR catch-up, then 80%/20% split to investors/sponsor up to a 12% investor IRR, then 60%/40% split to the investors/sponsor thereafter.</p>
            <p style = {{fontSize: '13px'}}>Beach City Capital Management intends to make distributions to investors after the payment of the company's liabilities (loan payments, operating expenses, and other fees as more specifically set forth in the LLC agreements, in addition to any member loans or returns due on member loan).</p>
            <p style = {{fontSize: '13px'}}>Distributions are expected to start Dec 2025 and are projected to continue on a quarterly basis thereafter. Distributions are at the discretion of Beach City Capital Management, who may decide to delay distributions for any reason, including maintenance or capital reserves.
        </p> <p style = {{fontSize: '13px'}}>Beach City Capital Management will receive a promoted interest as indicated above.</p>
      </div>
      
      <div className='right-section-2'>
        <img src={distribution} alt="distribution"></img>
      </div>
      
      </div>
      <CashFlow/>
      <div className='management'>
        <h1>Management</h1>
        <p>
        George Apostol is the founder and Managing Member of The Watermark Group, LLC, a real estate investment and advisory company located in Southern California and also the founder and Chairman of the Board of Watermark Construction, Inc. As an accomplished developer and real estate executive with over 20 years of experience, George has managed, developed, leased, entitled and strategically re-positioned over $2 billion of real estate. The Manhattan Medical Center represents the successful collaboration between George’s team and a talented local architectural firm.        </p>
        <p>
        Other projects include the entitlement and development of a $42 million ground up re-development of a parcel in Mamaroneck, NY, to include 92 newly constructed residential condominiums. George and his team have also completed the subdivision of an 18-acre retail commercial center. Completed deals include Wells Fargo Bank, CVS Pharmacy and with a preferred developer for a new PetClub. He and his partners have also secured deals for a new 25,000 sf build-to-suit for Goodwill and a new Sonic Burger restaurant.         </p>
        <p>
        George received a Masters of Business Administration Degree with Honors from Columbia Business School with an emphasis in Real Estate Finance and Management of Organizations, and graduated Cum Laude with a Bachelor of Science Degree in Business and Economics from Lehigh University. He is a licensed Broker in California and has served the City of Manhattan Beach as a Commissioner on the Board of Building Appeals and on the Parking and Public Improvements Commission and is currently serving as a Commissioner on the Planning Commission. As a resident of Manhattan Beach, CA for over 17 years, George is also currently serving as the President of the Board of Directors of Leadership Manhattan Beach, a non-profit organization focused on leadership training and enhancing and improving the local community.         
        </p>
     </div>
      <div style={{width: '100%', marginBottom: '0px', paddingBottom: '0px'}}>
      <img src={Watermark} alt="watermark" style={{paddingTop: '30px', margin: '0px'}}></img>
      </div>
      <div className='landuse'>
        <h1>Land Use and Design</h1>
      </div>
      <div className="container-landuse">
      <h1 className="header-1-landuse">Entitlement Strategy</h1>
      <h2 className="header-2-landuse">Fully Entitled</h2>
      <p className="paragraph-landuse">After extensive design, legal, and entitlement review with the City's Planning Staff and Commission, the project secured planning entitlements on schedule at the end of Q1 2023. These include rights to build 32 residential units, including two very low income and one moderate income unit. In addition, they permit two commercial spaces (3,000 SF total), one planned to be a full menu restaurant with alcohol, and the other planned as retail with the option to convert to a second restaurant for the right tenant.</p>
      <h2 className="header-2-landuse">Permit Approval</h2>
      <p className="paragraph-landuse">Beach City will begin producing Construction Documents in Q1 2023 and construction will commence in Q2 2024.</p>
      <h1 className="header-1-landuse">Environmental Strategy</h1>
      <p className="paragraph-landuse">The Remediation Strategy & Application has been reviewed and approved by the Los Angeles Fire Department.<br/> Phase I and Phase II Environmental Reports confirmed low levels of PCE and arsenic remediation needed from the site's previous dry cleaner and railroad line activity. The scope of the remediation is limited to the top five feet, which was expected. The Sponsor negotiated with the landowners to reduce the purchase price and worked with environmental consultants and estimators to set a site strategy in motion to remediate the site with minimal risk exposure.Beach City has contracted with E2C Remediation and secured a Stipulated Sum contract for the entirety of the remediation. Remediation will include excavation of the arsenic and vapor extraction of the PCE within the first two feet, which will take 16 months and provide the project a 10-month window of contingency to receive our letter of No Further Action from LA County Fire. This aligns with the project schedule prior to the final certificate of occupancy. </p>
      {/* <h1 className="header-1-landuse">Site Plan</h1>
      <img src={siteplan1} alt="siteplan1" style={{paddingTop: '30px'}}></img>
      <img src={siteplan2} alt="siteplan2" style={{paddingTop: '30px', width: '100%'}}></img>
      <h1 className="header-1-landuse">Conceptual Renders</h1>
      <img src={conceptualrender1} alt="conceptualrender1" style={{paddingTop: '30px'}}></img>
      <img src={conceptualrender2} alt="conceptualrender2" style={{paddingTop: '30px', width: '100%'}}></img>
      <h1 className="header-1-landuse">Residential Elevation</h1>
      <img src={elevation} alt="elevation" style={{paddingTop: '30px', width: '100%'}}></img> */}



    </div>
    <Footer/>
    {showAnnualReturn && (
  <div className="info-popup" onClick={() => setShowAnnualReturn(false)}>
    <div className="info-popup-content" onClick={e => e.stopPropagation()}>
      <h2>IRR</h2>
      <p>
      The Internal Rate of Return (IRR) is projected the annual return of the investment.
      <br/>
      <br/>
Let's say you bought a piece of property. You paid some money to buy it, and then, over time, you received money from it - maybe from renting it out or selling it.
<br/>
<br/>
The IRR is the average annual growth rate that your investment has experienced.
<br/>
<br/>
For example, let's say you invested $1000 in a lemonade stand business. Over 3 years, you made $300 from selling lemonade, so in total, you ended up with $1300 at the end of three years. The IRR is the average yearly growth rate of your initial $1000 that would result in $1300 after three years.
<br/>
<br/>
Remember, the IRR is just one way to evaluate an investment opportunity. 
      </p>
    </div>
  </div>
)}

{showAnnualDividend && (
  <div className="info-popup" onClick={() => setShowAnnualDividend(false)}>
    <div className="info-popup-content" onClick={e => e.stopPropagation()}>
      <h2>Equity Multiple</h2>
      <p>
      An equity multiple is a financial metric used by investors to understand the profitability of an investment. It is often used in the context of real estate investments, representing the total cash return an investor can expect to receive over the lifespan of the investment.
<br/>
<br/>
The equity multiple is calculated by dividing the total cash distributions received from the investment, including the eventual sale of the investment, by the total equity invested.
<br/>
<br/>
For instance, if you have invested $1,000 in a real estate project and over the course of three years you receive distributions totaling $1,500 (which includes the return of your initial investment and any profits from the investment), your equity multiple would be 1.5 ($1,500 / $1,000).
<br/>
<br/>
It's important to note that the equity multiple does not account for the time value of money, or the risk associated with the investment. Therefore, while it can provide a quick snapshot of the return, it should be used in conjunction with other financial metrics when evaluating an investment opportunity.
      </p>
    </div>
  </div>
)}

{showCheckSoon && (
      <div className="info-popup" onClick={() => setShowCheckSoon(false)}>
      <div className="info-popup-content" onClick={e => e.stopPropagation()}>
      {!showConfirmation ? (
        <>
        <p>
          We're waiting on revised subscription agreements for this one. Check back soon!
        </p>
        
          <button style={{cursor:"pointer"}} onClick={handleNotifyClick}>Get Notified > </button>
          </>
        ) : (
          <p>You will be notified when the agreements are updated!</p>
        )}
      </div>
    </div>
)}


    </>
  );
};
export default CatalinaVillage;
