import React, {useState, useEffect} from 'react'
import {Button} from 'components/shared/ButtonClick'
import {FcCheckmark} from 'react-icons/fc'
import styled from 'styled-components'

const Outer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
`

const Container = styled.div`
    min-width: 320px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
`

const Choice = styled(Button)`
    margin: 10px;
    position: relative;
`

const StyledFcCheckmark = styled(FcCheckmark)`
    position: absolute;
    font-size: 1.5em;
    top: calc(50% - .5em);
    left: 40px;
`

const AccreditedInvestor = () => {
  const [choice0, setChoice0] = useState(false)
  const [choice1, setChoice1] = useState(false)
  const [choice2, setChoice2] = useState(false)
  const [choice3, setChoice3] = useState(false)
  const [choice4, setChoice4] = useState(true)

  const clearChoices = (choice4Real) => {
      if(choice4Real && (choice0 || choice1 || choice2 || choice3) ){
          console.log("Hello there")
          setChoice0(false);
          setChoice1(false);
          setChoice2(false);
          setChoice3(false);
      }
  }

  const clearNone = (choiceReal) => {
      if(choice4 && choiceReal ){
          console.log("Hello there2")
          setChoice4(false);
      }
  }



  useEffect( () => {
      if(!(choice0 || choice1 || choice2 || choice3) ){
          setChoice4(true);
      }

  }, [choice0, choice1, choice2, choice3])
  
  
  return (
      <Outer>
          <Container>
              <h1> Are you an Accredited Investor? </h1>
              <p> Our offerings are currently only available to accredited investors and qualified purchasers. Please allow one business day for us to review your documents. In the meantime, we will hold your spot in the offering.   </p>
              <Choice onClick={() => {setChoice0(!choice0); clearNone(!choice0) } }>   {choice0 ? <StyledFcCheckmark /> : ''} <h4> I earn $200k annually, or $300k+ with my spousal equivalent. </h4> </Choice>
              <Choice onClick={() => {setChoice1(!choice1); clearNone(!choice1) } }>   {choice1 ? <StyledFcCheckmark /> : ''} <h4> I have $1M+ in assets, excluding my primary residence. </h4> </Choice>
              <Choice onClick={() => {setChoice2(!choice2); clearNone(!choice2) } }>   {choice2 ? <StyledFcCheckmark /> : ''} <h4> I own an entity (i.e. family office) with $5M+ in assets. </h4> </Choice>
              <Choice onClick={() => {setChoice3(!choice3); clearNone(!choice3) } }>   {choice3 ? <StyledFcCheckmark /> : ''} <h4> I hold a series 7, 65, or 82 license currently in good standing. </h4> </Choice>
              <Choice onClick={() => {setChoice4(!choice4); clearChoices(!choice4)} }> {choice4 ? <StyledFcCheckmark /> : ''} <h4> None of the above </h4> </Choice>
              <Choice primary="true"> <h4> Submit </h4> </Choice>
          </Container>
              
      </Outer>

  )
}

export default AccreditedInvestor
