import React from 'react'

import { clampBuilder } from 'utils/utils'

import { SegmentHolder, Title } from '../TextSegment/components'
import { Fact, FactBody, FactIcon, FactTitle, Inner, BigTitle } from './components'





const FastFacts = ({offering}) => {
    let facts = offering.facts
  return (
    <SegmentHolder>
        <BigTitle> Explore The Offering </BigTitle>
        <Inner>
            {facts.map((fact) => {
                return(
                <Fact>
                    <FactIcon src={fact.icon} width={clampBuilder(360, 1680, 100/(16 * facts.length + 1), 270/(16 * facts.length + 1))}/>
                    <FactTitle size={clampBuilder(360, 1680, 1, 1.5)}> {fact.name} </FactTitle>
                    <FactBody size={clampBuilder(360, 1680, 1, 1.5)}> {fact.body} </FactBody>
                </Fact>)
            })}
        </Inner>
    </SegmentHolder>
  )
}

export default FastFacts
