import React from 'react';
import styled from 'styled-components';

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-top: 20px;
`;

const Header = styled.h2`
  text-align: left;
  padding-left: 50px;
`;

const Table = styled.table`
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  border-collapse: collapse;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
`;

const TableRow = styled.tr`
  background-color: white;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
  height: 80px;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #E4F5F7;
  color: black;
  width: ${props => props.width};
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
`;


const SourcesOfFundsSection = () => {
    const tableData = [
      ['Senior Debt', '$439', '74%', '$12,500,000'],
      ['LP Equity', '$160', '21%', '$3,791,961'],
      ['GP Equity', '$22', '5.2%', '$500,000'],
      ['Total', '$865', '100%', '$16,791,961'],
    ];
  
    return (
      <TableSection>
        <Header>Sources of Funds</Header>
        <Table>
          <thead>
            <TableRow>
              <TableHeader width="40%">Uses</TableHeader>
              <TableHeader width="20%">Per RSF</TableHeader>
              <TableHeader width="20%">% of Total</TableHeader>
              <TableHeader width="20%">Amounts</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {tableData.map((row, i) => (
              <TableRow key={i}>
                {row.map((cell, j) => (
                  <TableCell key={j}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableSection>
    );
  };

export default SourcesOfFundsSection;
