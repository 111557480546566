import React from 'react';
import './ConfirmationPage.css';
import { MdPayment, MdCheckCircle } from 'react-icons/md'; // Importing some icons

function ConfirmationPage() {
    return (
        <div className="confirmation-page">
            <div className="message-box">
                <MdCheckCircle className="icon" />
                <p>Congratulations! MarketSpace will send confirmation of your investment within 24 hours of the wire being received.</p>
            </div>
            <div className="instructions">
                <h2><MdPayment className="icon" /> Wiring Instructions</h2>
                <p>First Republic Bank (JPMorgan Chase) <br /> 1888 Century Park East <br/> Los Angeles, CA  90067</p>
                <p> Routing Number: 321 081 669  </p>
                <p>Account Number: 99700056908 </p>
                <small>Your shares will be held for 2 business days while your wire processes.</small>
            </div>
            <div className="legal-notice">
                <small>
                    Your funds will be held until the target funding goal for the project is reached. 
                    If the funding goal is not reached within 90 days of its listing on MarketSpace, 
                    your funds will be returned to you within 5 business days afterwards. 
                    If the target funding goal is met, the documents you signed will be executed by the Developer, 
                    and MarketSpace will provide you with confirmation of your shares in the entity. 
                    By executing these wiring instructions, you agree to these terms, 
                    the MarketSpace Terms of Service, and authorize the MarketSpace services, 
                    in the manner designated therein, to process the documents and signatures provided herewith 
                    and to create, store, and communicate electronic records of the documents listed above.
                </small>
            </div>
        </div>
    );
}

export default ConfirmationPage;
