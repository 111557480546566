import OfferingCard from 'components/shared/OfferingCard'
import React from 'react'
import { Outer, Inner, Title, SubTitle, OfferingsGrid, OfferingInfoBox, ItemHeader, ItemCaption, DesktopCardHolder, MobileCardHolder, DesktopIcon, MobileIcon, MobileMiniDivider} from './components'
import { OfferingLink } from 'components/marketplace/MarketplaceApp/MarketplaceElements'

import tool from 'photos/icons/tool.png'
import multifam from 'photos/icons/multifam.png'

import {offerings} from 'testdata/data'
import { clampBuilder } from 'utils/utils'
import { SectionTitle } from '../MiscComponents/misc'

let ccOffering = offerings[0]
let kauaiOffering = offerings[1]

const Explore = () => {
  return (
    <Outer>
        <Inner width={clampBuilder(750, 1680, 740/16, 1000/16)}>
            <SectionTitle size={clampBuilder(360, 1680, 2.2, 3.2)} > Explore the Marketplace </SectionTitle>
            <SubTitle size={clampBuilder(360, 1680, 1, 1.4)}> Click on an offering to see its audited financials, 3D tours, comparable assets, and analysis from real estate experts. </SubTitle>
            <OfferingsGrid>
                
                <DesktopCardHolder> <OfferingLink to="/newoffering/0"> <OfferingCard offering={ccOffering}/> </OfferingLink> </DesktopCardHolder>
                <OfferingInfoBox>
                    <MobileMiniDivider />
                    <DesktopIcon src={tool} height={60} style={{marginBottom: '10px'}}/>
                    <ItemHeader size={clampBuilder(360, 1680, 1.5, 2)}> New Developments </ItemHeader>
                    <ItemCaption size={clampBuilder(360, 1680, .8, 1.1)}> For the first time ever, anyone can invest with industry-leading developers working on premier commercial projects across the United States. </ItemCaption>
                </OfferingInfoBox>
                <MobileCardHolder><OfferingLink to="/newoffering/0"> <OfferingCard offering={ccOffering}/> </OfferingLink> </MobileCardHolder>
                <DesktopCardHolder> <OfferingLink to="/newoffering/1"> <OfferingCard offering={kauaiOffering}/> </OfferingLink> </DesktopCardHolder>
                <OfferingInfoBox>
                    <MobileMiniDivider />
                    <DesktopIcon src={multifam} height={75} />
                    <ItemHeader size={clampBuilder(360, 1680, 1.5, 2)}> Rental Properties </ItemHeader>
                    <ItemCaption size={clampBuilder(360, 1680, .8, 1.1)}> Diversify your portfolio with high-quality income-generating rentals managed by industry professionals in the nation’s fastest growing markets. </ItemCaption>
                </OfferingInfoBox>
                <MobileCardHolder><OfferingLink to="/newoffering/1"> <OfferingCard offering={kauaiOffering}/> </OfferingLink> </MobileCardHolder>
            </OfferingsGrid>
        </Inner>

        
    </Outer>
  )
}

export default Explore