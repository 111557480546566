import styled from "styled-components"

export const SectionTitle = styled.h1`
    color: black;
    font-weight: bold;
    font-size: ${(props) => props.size};
    padding: 0;
    margin: 0;
    position: relative;
    text-align: center;

    &:after{
        position: absolute;
        bottom: 0px;
        right: 0;
        content: '';
        background-color: #5990FF;
        height: 2.5px;
        width: 100%;
    }
`

export const SubTitle = styled.h4`
    padding: 0;
    margin: 0;
    font-size: ${(props) => props.size};
    font-weight: normal;
    text-align: center;
    width: 60%;
    text-align: center;
    margin: 0 auto;
`

export const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 1rem;
`