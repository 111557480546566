import styled from 'styled-components'
import {FaTimes, FaBars} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import { SignUpButton } from 'components/navbars/HomeNavbar/HomeNavbarElements';


export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: white;
    display: grid;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${ ({isOpen}) => (isOpen ? '100%' : '0%')};
    top: ${ ({isOpen}) => (isOpen ? '0%' : '-100%') };
`

export const CloseIcon = styled(FaTimes)`
    color: black;
    top: calc(50px - .5em);
    right: 1em;
    position: fixed;
    display: ${(props) => props.isOpen ? 'block' : 'none'};
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.div`
    
`

export const SidebarMenuButton = styled(FaBars)`
    position: fixed;
    top: calc(50px - .5em);
    right: 1em;
    color: black;
    z-index: 10;
    float: right;
    font-size: 1.5em;
    cursor: pointer;
    
    
    color: ${(props) => props.color};
    

    @media (min-width: 850px){
        right: -5em;
        display: none;
    }

`

export const SidebarMenuBtnWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    

`

export const SidebarMenu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

export const SidebarLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: black;
    cursor: pointer;
    font-size: 2rem;

    &:hover{
        color: #01bf71;
        transition: 0.2s ease-in-out;
    }
    
`

export const SidebarSignUpButton = styled(SignUpButton)`
    font-size: 2rem;
    width: 150px;
    font-weight: 400;
    
`