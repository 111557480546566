import styled from 'styled-components'

export const Box = styled.div`
    margin-top: 40px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    padding: 20px;
    gap: 6px;
`

export const Item = styled.span`
    display: flex;
    flex-direction: row;
` 

export const ItemTitle = styled.h4`
    font-weight: bold;
`

export const ItemInfo = styled.h4`
    font-weight: 400;
`
