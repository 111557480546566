// APP COMPONENT
// Upon rendering of App component, make a request to create and
// obtain a link token to be used in the Link component

import React, { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import { Auth, API, graphqlOperation } from 'aws-amplify';
import { updateProfile } from 'graphql/mutations';
import { getProfile } from 'graphql/queries'
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import PaymentOptions from './PaymentOptions/PaymentOptions';

const LinkBank = () => {
  const [linkToken, setLinkToken] = useState(null);
  const [products, setProducts] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    (async () => {
      
      await fetchProfile();
      console.log(profile);
      

      const user = await fetchUser();
      generateToken(user);

    })();
    
  }, [])


  // MOVE THE USER CALL OUTSIDE MAYBE MORE EFFICIENT, BUT FOR NOW JUST MAKE IT WORK.
  // NEW USE EFFECT THAT CHANGES ON STATE OF PROFILE CHANGE


  // Funds settle in Dwolla master balance
  // KYC not required for Dwolla environment // can't rely on KYC themselves
  // KYC required for funds flow // they have to do it themselves // might have double KYC

  // Debits 3-4 days to master bank account
  // Credits from master bank to customer bank accounts 1-2 days

  // Unverfied customers:
  // Default limits $5k/week

  // Balance sheet/asset sheet


  // For unverified to 
  // 500 transactions/mo
  // $100k/week -- $7.5MM
  // $50k/week -- $3.25MM
  // $10k/week -- $750k

  // After 3mos, look at return rates

  // For us filling our balance, $10k transaction, but no limits. Can do as many $10ks as we want
  // (i.e. for disbursments)

  // Return/Dispute
  // Webhooks for returns
  // 70 different return codes
  // No fraud prevention/fraud monitoring

  // Transactions been made, then 3days later, if bank files unauthorized ACH charge, Dwolla sends the money back

  // $3.60 fee
  // Unauthorized $4.50 fee

  // No balance needs to be held

  // Average timing is 30 days
  // Business Integration
  // Funds flow review call -- confirm the flow of funds
  // Integration Support Agreement 
  // Business -- Dwolla

  // Funds Flow Call -- Earliest TM 3PM CST

  const bankLinked = profile ? (profile.accessToken !== undefined) : false;

  useEffect(() => {
    getAccounts()
    }
  , [profile])

  const fetchProfile = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const profileData = await API.graphql(graphqlOperation(getProfile, { id: user.username}));
      setProfile(profileData.data.getProfile);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const fetchUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (error) {
      console.error('Error fetching user:', error);
      return null;
    }
  };


  const generateToken = async (user) => {
    const response = await fetch('/api/create_link_token', {
      method: 'POST',
    });
    const data = await response.json();
    setLinkToken(data.link_token);
  };

  
  const getAccounts = async () => {
    const response = await fetch('/api/info', {
      method: 'POST',
    })
    const data = await response.json();
    setAccounts(data.accounts);
  }

  const getInfo = useCallback(async () => {
    const response = await fetch("/api/info", { method: "POST" });
    if (!response.ok){
      return false;
    }
    const data = await response.json();
    setProducts(data.products)
  }, setLinkToken, setProducts);

  return(
    <PaymentOptions />
  )
  /*
  return( 

    <>
    
    { bankLinked ? 
    
    <> 
    <FaRegCheckCircle height={200}/> 
    <p>Bank linked & identity verified.</p>
    <PaymentOptions />
    </>
    : 
    (linkToken != null ? <Link linkToken={linkToken} setProfile={setProfile} profile={profile}/> : "")
    }

    </>

  );*/

   
};
// LINK COMPONENT
// Use Plaid Link and pass link token and onSuccess function
// in configuration to initialize Plaid Link

const Link = ({linkToken, profile, setProfile}) => {
  const onSuccess = React.useCallback((public_token, metadata) => {
    // send public_token to server
    const response = fetch('/api/set_access_token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ public_token }),
    }).then((response) => response.json()).then( (data) => {
      console.log(data);
      const updateUser = async (access_token) => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          await API.graphql(graphqlOperation(updateProfile, {input: { id: user.username, accessToken: access_token}}));
          setProfile({...profile, accessToken: access_token});
        } catch (error) {
          console.error('Error updating user:', error);
        }
      }
      updateUser(data.access_token);
      
    })

    
  }, []);
  
  const config = {
    token: linkToken,
    onSuccess,
  };
  const { open, ready } = usePlaidLink(config);
  return (
    <>

    
    <button onClick={() => open()} disabled={!ready}>
      Link account
    </button>
    
  </>
  );
};
export default LinkBank;