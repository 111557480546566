import React from 'react'

import {Button} from 'components/shared/ButtonElement'
import {Container, InfoBorder, InfoInner} from './InfoComponents'

const HomeInfoSection = () => {
  return (
    <div>
        <Container>
          <InfoBorder>
              <InfoInner>
                  <h1> 
                    1. Browse
                  </h1>
                  <p>
                  Browse the MarketSpace catalog. Once you see a property you like, review its historical operating income, appreciation, neighborhood, and more.
                  </p>
                  <Button to="/about"> Hear from our Developers </Button>

              </InfoInner>
          
          </InfoBorder>
          <InfoBorder>
              <InfoInner>
                  <h1> 
                    2. Invest
                  </h1>
                  <p>
                  Add a share of the property to your portfolio.
                      {/*We believe real estate investing should be avaiable to everyone, so we are comitted to charging users no fees, ever. That's less than you'd pay to a broker when buying a home yourself. */}
                  </p>
                  <Button to="/about"> View Offerings </Button>
              </InfoInner>
          
          </InfoBorder>
          <InfoBorder>
              <InfoInner>
                  <h1> 
                    3. Earn
                  </h1>
                  <p>
                  Receive your share of the rental income in your bank account every quarter. That’s it!
                  </p>
                  <Button to="/about"> Schedule a Meeting with Our Team  </Button>
              </InfoInner>
          
          </InfoBorder>
        </Container>

    </div>
  )
}

export default HomeInfoSection

// Old Section
/*
const HomeInfoSection = () => {
  return (
    <div>
        <Container>
          <InfoBorder>
              <InfoInner>
                  <h1> 
                    Deals from Top Developers 
                  </h1>
                  <p>
                    We partner with experienced developers throughout the United States specializing in single-family, appartment, condominum, and office space. All developers on MarketSpace are thoroughly vetted and come with a track record of over $2B. See some of their projects below.
                  </p>
                  <Button to="/about"> Hear from our Developers </Button>

              </InfoInner>
          
          </InfoBorder>
          <InfoBorder>
              <InfoInner>
                  <h1> 
                    No Hidden Fees
                  </h1>
                  <p>
                  We believe real estate investing should be avaiable to everyone, and includes lowing fees and reducing road blocks along the way. This means buying real estate comes just as easily as buying candy– you know what you're getting. 
                      {//We believe real estate investing should be avaiable to everyone, so we are comitted to charging users no fees, ever. That's less than you'd pay to a broker when buying a home yourself.}
                  </p>
                  <Button to="/about"> View Offerings </Button>
              </InfoInner>
          
          </InfoBorder>
          <InfoBorder>
              <InfoInner>
                  <h1> 
                    Speak to Real Estate Experts
                  </h1>
                  <p>
                      We belive real estate investing should be for everyone, not just the 1%. If you have questions about our process, or a particular deal on the platform, feel free to reach out and one of our teamembers will get back to you as soon as possible to set up a time to chat.
                  </p>
                  <Button to="/about"> Schedule a Meeting with Our Team  </Button>
              </InfoInner>
          
          </InfoBorder>
        </Container>

    </div>
  )
}

export default HomeInfoSection
*/