import React from 'react'

import { SegmentHolder, Title } from '../TextSegment/components'
import { Subtitle } from './components'

const PropertyManagement = () => {
  return (
    <SegmentHolder>
        <Title> Property Management </Title>
        <Subtitle> koolkondo </Subtitle>
        <p> 
            Airbnb Super Host <br />
            334 Reviews <br /><br />
            Welcome to koolkondo! We have the most modern Hawaiian luxury homes and condos in Poipu Beach Kauai and perfect Getaways in Malibu Beach, CA. Along with our VIP Customer service and professional management, you will enjoy every second of your stay with us at any one of our luxury properties!

            Every unit is designer furnished and fully stocked with everything you will need to enjoy and relax like being at your own home. Quality vacations and experiences at its finest.
        </p>
    </SegmentHolder>
  )
}

export default PropertyManagement