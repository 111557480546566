import React from 'react'
import { Outer, SectionTitle, SubTitle } from './components'
import { Link } from 'react-router-dom';
import {clampBuilder} from 'utils/utils'

const Header = () => {
  return (
    <Outer>
        <SectionTitle size={clampBuilder(360, 1680, 2.2, 3)}> Marketplace </SectionTitle>
        <SubTitle size={clampBuilder(360, 1680, 1, 1.4)}>
  Welcome to the Marketplace. Want to learn more? Read our primer on
  <Link to="/resources" style={{'color': '#5990FF', textDecoration: 'none'}}> Real Estate 101 </Link> 
  or see how we 
  <Link to="/resources" style={{'color': '#5990FF', textDecoration: 'none'}}> Choose our Offerings</Link>
</SubTitle>    </Outer>
  )
}

export default Header