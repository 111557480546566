import React, { useState } from 'react';
import './floorplans.css'
import './CatalinaVillage.css'

const FloorPlans = ({ photoList }) => {
  const [popupPhoto, setPopupPhoto] = useState(null);

  const handlePhotoClick = (photo) => {
    setPopupPhoto(photo);
  }

  const closePopup = () => {
    setPopupPhoto(null);
  }

  return (
    <div className="photo-gallery">
      <h2>Floor Plans</h2>
      <div className="photo-gallery-row">
        {photoList.map((photo, index) => (
          <div
            key={index}
            className="photo-wrapper-2"
            onClick={() => handlePhotoClick(photo)}
          >
            <img src={photo} alt={`Gallery Photo ${index + 1}`} />
          </div>
        ))}
      </div>
      {popupPhoto && (
        <div className="popup">
          <div className="popup-content">
            <img className="popup-img" src={popupPhoto} alt="Popup" />
            <span className="close" onClick={closePopup}> close </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloorPlans;



  

// const FloorPlans = ({ photoList }) => {
//   const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

// //   const handleNext = () => {
// //     setCurrentIndex((currentIndex + 1) % photoList.length);
// //   };

// //   const handlePrev = () => {
// //     setCurrentIndex((currentIndex - 1 + photoList.length) % photoList.length);
// //   };

//   const handleNextClick = () => {
//     if (currentPhotoIndex < photoList.length - 1) {
//       setCurrentPhotoIndex(currentPhotoIndex + 1);
//     } else {
//       setCurrentPhotoIndex(0);
//     }
//   };

//   const handlePrevClick = () => {
//     if (currentPhotoIndex > 0) {
//       setCurrentPhotoIndex(currentPhotoIndex - 1);
//     } else {
//       setCurrentPhotoIndex(photoList.length - 1);
//     }
//   };

//   return (
//     <div className="photo-gallery">
//       <h2>Floor Plans</h2>
//       <div className="photo-gallery-buttons">
//         <button onClick={handlePrevClick}>&lt;</button>
//         <button onClick={handleNextClick}>&gt;</button>
//       </div>
//       <div className="photo-gallery-row">
//         {photoList.map((photo, index) => (
//           <div
//             key={index}
//             className="photo-wrapper-2"
//             style={{
//               transform: `translateX(-${currentPhotoIndex * 100}%)`,
//               transition: 'transform 0.5s ease',
//             }}
//           >
//             <img src={photo} alt={`Gallery Photo ${index + 1}`} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default FloorPlans;
