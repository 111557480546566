import '@aws-amplify/ui-react/styles.css';

import React, { useState, useEffect } from 'react';
import styles from './Confirmation.module.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import {getProfile} from 'graphql/queries'
import { Navigate } from 'react-router-dom';

const Confirmation = ({ username }) => {

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    fetchProfile()
    console.log("I was called.")
    console.log(profile)
  })

  const fetchProfile = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const profileData = await API.graphql(graphqlOperation(getProfile, { id: user.username}));
      setProfile(profileData.data.getProfile);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Welcome!</h1>
      <p className={styles.text}>Thank you for signing up. We're glad to have you on board.</p>
      {profile ? <Navigate replace to="/marketplace" /> :
      <a href="/finishprofile" className={styles.link}>Finish Your Profile</a>
      }
    </div>
  );
}

export default withAuthenticator(Confirmation);
