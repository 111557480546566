import React, { useState, useEffect } from 'react';
import './CatalinaVillage.css';
import Footer from 'components/home/Footer'
import CatalinaCover from 'photos/Catalina/catalinacoverpic.png'
// import OldBuilding from 'photos/oldTownCondo.svg'
import infoButton from 'photos/blueinfoicon.png'
import './gallery.css';
import rendering1 from 'photos/Catalina/rendering1.png'
import rendering2 from 'photos/Catalina/rendering2.png'
import rendering3 from 'photos/Catalina/rendering3.png'
import PhotoGallery from './PhotoGallery';
import warning from 'photos/clipboardwarning.svg'
import moneygrowth from 'photos/group83.svg'
import moneycircle from 'photos/group79.svg'
import FinancialSection from './financials';
import SourcesOfFundsSection from './SourcesofFunds';
import UsesOfFundsSection from './UsesofFunds';
import distribution from 'photos/Catalina/distribution.png'
import CashFlow from './cashflow';
import BCCTeam from 'photos/Catalina/BCCTeam.png'
import BoardAdvisors from 'photos/Catalina/BoardAdvisors.png'
import ThirdParty from 'photos/Catalina/ThirdPartyConsultants.png'
import FloorPlans from './floorplans';
import floorplan1 from 'photos/Catalina/floorplan1.png'
import floorplan2 from 'photos/Catalina/floorplan2.png'
import floorplan3 from 'photos/Catalina/floorplan3.png'
import floorplan4 from 'photos/Catalina/floorplan4.png'
import conceptualrender1 from 'photos/Catalina/conceptualrender1.png'
import conceptualrender2 from 'photos/Catalina/conceptualrender2.png'
import elevation from 'photos/Catalina/elevation.png'
import siteplan1 from 'photos/Catalina/siteplan1.png'
import siteplan2 from 'photos/Catalina/siteplan2.png'
import { Link } from 'react-router-dom';
import { FaBook, FaChartLine } from 'react-icons/fa';



const CatalinaVillage = ({ imagePath }) => {

  const [shares, setShares] = useState(250);

  const handleSharesChange = (e) => {
    setShares(e.target.value);
  };

  const calculateInvestment = () => {
    return (10.00 * shares).toFixed(2);
  };

  const [showPopup, setShowPopup] = useState(false);

  const [showAnnualReturn, setShowAnnualReturn] = useState(false);
const [showAnnualDividend, setShowAnnualDividend] = useState(false);
const [showOfferingSize, setShowOfferingSize] = useState(false);


  const id = 0;

  const investors = '2';
  const fundedPercentage = '1';

  
  const photoList = [rendering1, rendering2, rendering3, rendering1, rendering2, rendering3, rendering1];

  const data = [
    { value: 2.3, color: '#ADD8E6' },
    { value: 11, color: '#B0E0E6' },
    { value: 11, color: '#87CEEB' },
    { value: 22.5, color: '#87CEFA' },
  ];

  const photoUrls = [floorplan1, floorplan2, floorplan3, floorplan4  ];
    
  return (
    <>
    <div className="catalina-village">
      <div className="left-section">
        <div className="left-content">
          <h1 className="title">Catalina<br/>Village</h1>
          <p className="location">Redondo Beach, CA</p>
          
        </div>
        <div className="left-content-bldgback">
          {/* <img src={OldBuilding} style={{marginRight: '0px', marginLeft: 'auto', opacity: '60%'}}/> */}
          <div className="left-content-boxes">
            <div className="info-boxes" >
              <div className="info-item">
                <h3>Offering Size</h3>
                <p>$1,000,000</p>
              </div>
              <div className="info-item">
              <h3>5-Yr EM</h3>
                <div className="info-text-button-1"> {/* Add this div */}
                  <p>2.5x</p>
                  <button onClick={() => setShowAnnualDividend(true)}>
                    <img src={infoButton} alt="Info" className="info-icon-2" />
                  </button>
                </div>
              </div>
              <div className="info-item">
                <h3>5-Yr IRR</h3>
                <div className="info-text-button-1"> {/* Add this div */}
                  <p>17%</p>
                  <button onClick={() => setShowAnnualReturn(true)}>
                    <img src={infoButton} alt="Info" className="info-icon-2" />
                  </button>
                </div>
              </div>
              {/* <div className="info-item">
                <h3>Annual Dividend</h3>
                <p>14.83% </p>
              </div>
              <div className="info-item">
                <h3>Annual Return</h3>
                <p>17%</p>
              </div> */}

              <Link to={`/investflow/${id}`} style={{textDecoration: 'none'}}>
              <button className='invest-item'>
                <h3>Buy Shares</h3>
              </button>
              </Link>
            </div>
            </div>
        </div>
      </div>
      <div className="right-section">
        <img src={CatalinaCover} alt="Building" />
      </div>
    </div>

            {/* New div with two halves */}
      <div className="progress-section">
        <div className="left-progress">
          <h2>Offering Progress</h2>
        </div>
        <div className="right-progress">
          <div className="progress-item">
            <h4>Investors: {investors} {/* Replace with dynamic value */}</h4>
            
          </div>
          <div className="progress-item">
            <h4>Progress</h4>
            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: "5%" }}> {/* Replace 50% with dynamic value */}
                {fundedPercentage}% {/* Replace with dynamic value */}
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* New photo gallery section */}
      <PhotoGallery photoList={photoList} />
      <div className="highlights-section">
        <h1>Highlights</h1>
        <p>Beach City Capital (Beach City) is pleased to present this opportunity to invest in a 30-unit for rent townhome apartment project with 3,000 SF of neighborhood-serving retail/restaurant space located at 100-132 N Catalina Ave in Redondo Beach, CA, with a 5-year hold strategy. Beach City has received fully vested entitlements for Catalina Village, and will start construction in Q2 of 2024. </p>
      </div>
      <div>
      <div className='catalina-village-highlights'>
      <div className='left-section-highlights'>
        <h2> Mitigated Development Risk</h2>
        <p>Beach City has invested extensive time working with multiple GCs, sub-contractors, and the City to create accurate cost estimates and will implement Building Information Modeling (BIM) software to save additional cost and time. </p>

<p>Given their efforts during this pre-development phase, they expect a maximum construction period of 20 months. Construction is on schedule to commence Q2 2024. The project will be protected by a Guaranteed Maximum Price (GMP) construction contract for any potential construction cost increases. The project secured fully vested entitlements at the end of Q1 2023 for 32 units, the largest at 4 bedrooms, and two commercial spaces.</p>
      </div>
      <div className='right-section-2'>
        <img src={warning} alt="warning"></img>
      </div>
      </div>
      <div className='catalina-village-highlights'>
      <div className='left-section-highlights' style={{width:'40%'}}>
        <img src={moneygrowth} alt="warning" ></img>
      </div> 
      <div className='right-section-text' style={{width:'60%'}}>
        <h2> Strong Return Profile</h2>
        <p>This Investment is projected to yield a 14.83%+ annual cash-on-cash return to investors with a total 17% Investor IRR and 2.5x Equity Multiple over a five-year hold. For example, a $100,000 investment is projected to return a total of $250,000 to you over the next 5 years.</p>
      </div>

      </div>
      <div className='catalina-village-highlights'>
      <div className='left-section-highlights'>
        <h2> High Demand Location</h2>
        <p>Catalina Village is located directly across from the ocean in South Redondo Beach, CA with a public oceanview park directly across the street and a variety of restaurants, beaches, and outdoor activities within walking distance.</p>
      </div>
      <div className='right-section-2'>
        <img src={moneycircle} alt="warning" ></img>
      </div>
      </div>
      <FloorPlans photoList={photoUrls} />
      <FinancialSection data={data} />
      </div>
      <div>
        <SourcesOfFundsSection />
      </div>
      <div style= {{marginTop: '60px'}}>
        <UsesOfFundsSection />
      </div>
      <div className='catalina-village-highlights'>
      <div className='left-section-highlights'>
       <h2 style = {{color: '#277DFF'}}> Distributions</h2> 
       <p style = {{color: '#277DFF'}}>Beach City Capital Management intends to make distributions in the following three phases: </p>
        <p style = {{fontSize: '13px'}}>1. At Refinance - To the investors, pari passu, all proceeds at refinance to an 8% cumulative preferred return, then remaining upside split 70% to investors / 30% to sponsor thereafter.
        <br/>2. During Cashflows - To the investors, pari passu, all proceeds during cashflows to a 5% preferred return, then remaining upside split 70% to investors / 30% to sponsor thereafter.
        <br/>3. At Sale - To the investors, pari passu, all proceeds at sale to a 8% IRR catch-up, then 80%/20% split to investors/sponsor up to a 12% investor IRR, then 60%/40% split to the investors/sponsor thereafter.</p>
            <p style = {{fontSize: '13px'}}>Beach City Capital Management intends to make distributions to investors after the payment of the company's liabilities (loan payments, operating expenses, and other fees as more specifically set forth in the LLC agreements, in addition to any member loans or returns due on member loan).</p>
            <p style = {{fontSize: '13px'}}>Distributions are expected to start Dec 2025 and are projected to continue on a quarterly basis thereafter. Distributions are at the discretion of Beach City Capital Management, who may decide to delay distributions for any reason, including maintenance or capital reserves.
        </p> <p style = {{fontSize: '13px'}}>Beach City Capital Management will receive a promoted interest as indicated above.</p>
      </div>
      
      <div className='right-section-2'>
        <img src={distribution} alt="distribution"></img>
      </div>
      
      </div>
      <CashFlow/>
      <div className='management'>
        <h1>Management</h1>
        <p>Beach City Capital is a private real estate investment firm headquartered in Hermosa Beach, California focusing on executing the development of multi-family assets in A+ locations.</p>
        <p>Beach City Capital’s staff is made up of experienced professionals with backgrounds in both architecture and finance, all seasoned to pursue a consistent development and investment process that combines intimate local market knowledge and hands-on expertise, creating value for shareholders. BCC’s cutting edge and competitive advantage as a developer is that Founder and CEO, Jason Muller, is traditionally trained as an architect, then proceeded to practice land use and entitlements in Santa Monica, Culver City, and LA, focusing on the adaptive reuse of industrial campuses into creative offices and residential land use typologies. </p>
        <p>Thereafter, Jason attended grad school at USC and went to work for large institutional development companies, building hundreds of units of housing, mixed-use and large-scale hotel projects. He then decided to start Beach City Capital seven years ago, with the first phase of the company to buy old apartment buildings, demolish them, and build ocean-view townhomes to sell for $2-3M each. Jason was also able to build Beach City Capital’s accounting, legal, staffing, and operational systems.</p>
        <p>For the next phase of the company, BCC is building product to be resilient in any fluctuating economy. Studying 2008+, affordable and workforce housing commanded low vacancy rates of 4- 5%, following suit Beach City Capital is focusing the company's phase two on building this product. In order to scale up unit counts, BCC focused on Joint Venture Partnerships with Landowners and were able to build a pipeline with four major projects. Beach City Capital also had a goal to improve efficiencies in Property Management. BCC researched tech-based flexible leasing systems and found it to be an affordable and effective solution, boosting NOI by 20% while providing the end user with affordable rent. </p>
        <p>Beach City Capital is currently developing over $156,100,000 in gross development product. Beach City Capital plans to enhance and enrich the urban fabric of the city and in doing so, BCC’s projects are designed to be affordable, well-integrated, profitable, and positively impactful. Beach City Capital’s mission is to improve the quality of life and community, one project at a time, while growing generational wealth for the company’s partners.</p>
      </div>
      <div style={{width: '100%', marginBottom: '0px', paddingBottom: '0px'}}>
      <img src={BCCTeam} alt="BCC" style={{paddingTop: '30px'}}></img>
      <img src={BoardAdvisors} alt="Advisors" style={{paddingTop: '30px'}}></img>
      <img src={ThirdParty} alt="thirdparty" style={{paddingTop: '30px', margin: '0px'}}></img>
      </div>
      <div className='landuse'>
        <h1>Land Use and Design</h1>
      </div>
      <div className="container-landuse">
      <h1 className="header-1-landuse">Entitlement Strategy</h1>
      <h2 className="header-2-landuse">Fully Entitled</h2>
      <p className="paragraph-landuse">After extensive design, legal, and entitlement review with the City's Planning Staff and Commission, the project secured planning entitlements on schedule at the end of Q1 2023. These include rights to build 32 residential units, including two very low income and one moderate income unit. In addition, they permit two commercial spaces (3,000 SF total), one planned to be a full menu restaurant with alcohol, and the other planned as retail with the option to convert to a second restaurant for the right tenant.</p>
      <h2 className="header-2-landuse">Permit Approval</h2>
      <p className="paragraph-landuse">Beach City will begin producing Construction Documents in Q1 2023 and construction will commence in Q2 2024.</p>
      <h1 className="header-1-landuse">Environmental Strategy</h1>
      <p className="paragraph-landuse">The Remediation Strategy & Application has been reviewed and approved by the Los Angeles Fire Department.<br/> Phase I and Phase II Environmental Reports confirmed low levels of PCE and arsenic remediation needed from the site's previous dry cleaner and railroad line activity. The scope of the remediation is limited to the top five feet, which was expected. The Sponsor negotiated with the landowners to reduce the purchase price and worked with environmental consultants and estimators to set a site strategy in motion to remediate the site with minimal risk exposure.Beach City has contracted with E2C Remediation and secured a Stipulated Sum contract for the entirety of the remediation. Remediation will include excavation of the arsenic and vapor extraction of the PCE within the first two feet, which will take 16 months and provide the project a 10-month window of contingency to receive our letter of No Further Action from LA County Fire. This aligns with the project schedule prior to the final certificate of occupancy. </p>
      <h1 className="header-1-landuse">Site Plan</h1>
      <img src={siteplan1} alt="siteplan1" style={{paddingTop: '30px'}}></img>
      <img src={siteplan2} alt="siteplan2" style={{paddingTop: '30px', width: '100%'}}></img>
      <h1 className="header-1-landuse">Conceptual Renders</h1>
      <img src={conceptualrender1} alt="conceptualrender1" style={{paddingTop: '30px'}}></img>
      <img src={conceptualrender2} alt="conceptualrender2" style={{paddingTop: '30px', width: '100%'}}></img>
      <h1 className="header-1-landuse">Residential Elevation</h1>
      <img src={elevation} alt="elevation" style={{paddingTop: '30px', width: '100%'}}></img>



    </div>
    <Footer/>
    {showAnnualReturn && (
  <div className="info-popup" onClick={() => setShowAnnualReturn(false)}>
    <div className="info-popup-content" onClick={e => e.stopPropagation()}>
      <h2>IRR</h2>
      <p>
      The Internal Rate of Return (IRR) is projected the annual return of the investment.
      <br/>
      <br/>
Let's say you bought a piece of property. You paid some money to buy it, and then, over time, you received money from it - maybe from renting it out or selling it.
<br/>
<br/>
The IRR is the average annual growth rate that your investment has experienced.
<br/>
<br/>
For example, let's say you invested $1000 in a lemonade stand business. Over 3 years, you made $300 from selling lemonade, so in total, you ended up with $1300 at the end of three years. The IRR is the average yearly growth rate of your initial $1000 that would result in $1300 after three years.
<br/>
<br/>
Remember, the IRR is just one way to evaluate an investment opportunity. 
      </p>
    </div>
  </div>
)}

{showAnnualDividend && (
  <div className="info-popup" onClick={() => setShowAnnualDividend(false)}>
    <div className="info-popup-content" onClick={e => e.stopPropagation()}>
      <h2>Equity Multiple</h2>
      <p>
      An equity multiple is a financial metric used by investors to understand the profitability of an investment. It is often used in the context of real estate investments, representing the total cash return an investor can expect to receive over the lifespan of the investment.
<br/>
<br/>
The equity multiple is calculated by dividing the total cash distributions received from the investment, including the eventual sale of the investment, by the total equity invested.
<br/>
<br/>
For instance, if you have invested $1,000 in a real estate project and over the course of three years you receive distributions totaling $1,500 (which includes the return of your initial investment and any profits from the investment), your equity multiple would be 1.5 ($1,500 / $1,000).
<br/>
<br/>
It's important to note that the equity multiple does not account for the time value of money, or the risk associated with the investment. Therefore, while it can provide a quick snapshot of the return, it should be used in conjunction with other financial metrics when evaluating an investment opportunity.
      </p>
    </div>
  </div>
)}
    </>
  );
};
export default CatalinaVillage;
