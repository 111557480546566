import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const NavbarContainer = styled.nav`
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 90px;
    width: 100%;
    padding: 0;
    margin: 0;
    display:grid;
    grid-template-columns: 1fr 1fr;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    z-index: 2;

    @media (max-width: 850px){
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 2;
        
    }
`


export const NavbarLeft = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
`

export const PageSel = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
`

export const TextLink = styled(Link)`
    color: lightgray;
    text-decoration: none;

    &:hover{
    color: white;
    cursor: pointer;
    }
`

export const SignUpButton = styled.button`
    width: 100px;
    height: 50px;
    background-color: #5990FF;
    color: white;
    font-weight: 400;
    cursor: pointer;
    border: 0;
    font-size: 1em;
    border-radius: 10px;
    white-space: nowrap;
    outline: none;
    border: none;
    text-decoration: none;





`