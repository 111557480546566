/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://kyf4n46tkzddbdowo5hswfaazy.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "helloWorldTest",
            "endpoint": "https://w0fo28h6k6.execute-api.us-west-1.amazonaws.com/staging",
            "region": "us-west-1"
        },
        {
            "name": "fillPDF",
            "endpoint": "https://gv9ci04j58.execute-api.us-west-1.amazonaws.com/staging",
            "region": "us-west-1"
        }
    ],
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_jzHrvKAYC",
    "aws_user_pools_web_client_id": "6qu3ggval8t6hp72hvupon1l4n",
    "oauth": {
        "domain": "marketspace.auth.us-west-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
