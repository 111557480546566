import React, { useState } from 'react';
import './gallery.css'
import './CatalinaVillage.css'

const PhotoGallery = ({ photoList }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

//   const handleNext = () => {
//     setCurrentIndex((currentIndex + 1) % photoList.length);
//   };

//   const handlePrev = () => {
//     setCurrentIndex((currentIndex - 1 + photoList.length) % photoList.length);
//   };

  const handleNextClick = () => {
    if (currentPhotoIndex < photoList.length - 1) {
      setCurrentPhotoIndex(currentPhotoIndex + 1);
    } else {
      setCurrentPhotoIndex(0);
    }
  };

  const handlePrevClick = () => {
    if (currentPhotoIndex > 0) {
      setCurrentPhotoIndex(currentPhotoIndex - 1);
    } else {
      setCurrentPhotoIndex(photoList.length - 1);
    }
  };

  return (
    <div className="photo-gallery">
      <h2>Renderings</h2>
      <div className="photo-gallery-buttons">
        <button onClick={handlePrevClick}>&lt;</button>
        <button onClick={handleNextClick}>&gt;</button>
      </div>
      <div className="photo-gallery-row">
        {photoList.map((photo, index) => (
          <div
            key={index}
            className="photo-wrapper"
            style={{
              transform: `translateX(-${currentPhotoIndex * 100}%)`,
              transition: 'transform 0.5s ease',
            }}
          >
            <img src={photo} alt={`Gallery Photo ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoGallery;



// import React, {useState} from 'react';
// import './gallery.css';
// import './CatalinaVillage.css'


// const handleArrowClick = (direction) => {
//     if (direction === 'left') {
//       setCurrentPhotoIndex((prevIndex) => {
//         if (prevIndex === 0) {
//           return photoList.length - 1;
//         }
//         return prevIndex - 1;
//       });
//     } else {
//       setCurrentPhotoIndex((prevIndex) => {
//         if (prevIndex === photoList.length - 1) {
//           return 0;
//         }
//         return prevIndex + 1;
//       });
//     }
//   };

//   return (
//     <div className="photo-gallery">
//       <h2>Renderings</h2>
//       <div className="photo-gallery-buttons">
//         <button onClick={handlePrev}>&lt;</button>
//         <button onClick={handleNext}>&gt;</button>
//       </div>
//       <div className="photo-gallery-row">
//         {photoList.map((photo, index) => (
//           <div
//             key={index}
//             className="photo-wrapper"
//             style={{
//               transform: `translateX(-${currentIndex * 100}%)`,
//               transition: 'transform 0.5s ease',
//             }}
//           >
//             <img src={photo} alt={`Gallery Photo ${index + 1}`} />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default PhotoGallery;