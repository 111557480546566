import CottonGreenway from 'photos/cottongreenway.png'
import MMC from 'photos/MMC.jpeg'
import MBVillage from 'photos/mbvillage.webp'
import Condos from 'photos/condos.jpeg'
import WMC from 'photos/wmc.jpeg'
import ACME from 'photos/acme.jpeg'

import mmcfp1 from 'photos/floorplans/mmc/1.jpg'
import mmcfp2 from 'photos/floorplans/mmc/2.jpg'

import mmc1 from 'photos/propertyphotos/0/mmc1.jpeg'
import mmc2 from 'photos/propertyphotos/0/mmc2.jpeg'
import mmc3 from 'photos/propertyphotos/0/mmc3.jpeg'

import mvil1 from 'photos/propertyphotos/1/manhattanvillage1.jpeg'
import mvil2 from 'photos/propertyphotos/1/manhattanvilage2.jpeg'
import mvil3 from 'photos/propertyphotos/1/manhattanvillage3.jpeg'

const developers = [{
  id: 0,
  name: 'Watermark Construction',
  developerPhoto: WMC,
  developerDescription: `George Apostol is the founder and Managing Member of The Watermark Group, LLC, a real estate investment and advisory company located in Southern California and also the founder and Chairman of the Board of Watermark Construction, Inc.  As an accomplished developer and real estate executive with over 20 years of experience, George has managed, developed, leased, entitled and strategically re-positioned over $2 billion of real estate.
  \n The Manhattan Medical Center represents the successful collaboration between George’s team and a talented local architectural firm.  George and his team secured the re-zoning and entitlements for this new 26,000 sf Class‑A Medical Office Building to be located in Manhattan Beach, CA.  As the Managing Member, George is currently overseeing and managing a $12 million construction budget.  The building has an estimated completion value in excess of $30 million.
  \n Other projects include the entitlement and development of a $42 million ground up re-development of a parcel in Mamaroneck, NY, to include 92 newly constructed residential condominiums.  George and his team have also completed the subdivision of an 18-acre retail commercial center.  Completed deals include Wells Fargo Bank, CVS Pharmacy and with a preferred developer for a new PetClub.  He and his partners have also secured deals for a new 25,000 sf build-to-suit for Goodwill and a new Sonic Burger.
  \n George received a Masters of Business Administration Degree with Honors from Columbia Business School with an emphasis in Real Estate Finance and Management of Organizations, and graduated Cum Laude with a Bachelor of Science Degree in Business and Economics from Lehigh University.  He is a licensed Broker in California and has served the City of Manhattan Beach as a Commissioner on the Board of Building Appeals and on the Parking and Public Improvements Commission and is currently serving as a Commissioner on the Planning Commission.  As a resident of Manhattan Beach, CA for over 17 years, George is also currently serving as the Vice President of the Board of Directors of Leadership Manhattan Beach, a non-profit organization focused on leadership training and enhancing and improving the local community.
  `,
},
{
id: 1,
name: 'Acme Construction',
developerPhoto: ACME,
developerDescription: `ACME corporation is a nationwide leader in everything from anvils to real estate... Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`
}
]


export const properties = [{
  id: 0,
  name: 'Manhattan Medical Center',
  location: 'Manhattan Beach, CA',
  assetType: 'Commercial',
  developer: developers[0],
  targetIRR: 16,
  acquisitionDate: 'June 27, 2022',
  size: '40,000 sqft',
  currentOffering: '$0',
  totalOffering: '$20,000,000',
  description: 'New state of the art medical center in Manhattan Beach. The facility has approximately 26,000 square feet of customizable space to meet the needs of any medical practice. Two floors of subterranean parking providing ample parking for physicians, staff and patients. The building will be LEED Silver compliant.',
  performanceTargets: ['Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.'],
  photo: MMC,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  
  // 
  floorplans: [mmcfp1, mmcfp2],
  targetedHold: '10 yr',
  marketCap: 45000000,
  photos: [mmc1, mmc2, mmc3],
  
},{
id: 1,
name: 'Manhattan Village',
location: 'Manhattan Beach, CA',
assetType: 'Commercial',
developer: developers[1],
targetIRR: 19.1,
acquisitionDate: 'June 27, 2022',
size: '40,000 sqft',
currentOffering: '$0',
totalOffering: '$20,000,000',
description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut maxime quasi esse ipsa ab pariatur vitae omnis distinctio facilis voluptate libero, nam ipsam, recusandae deserunt mollitia aperiam repellat, ducimus tempora. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab rerum veritatis hic, amet laborum pariatur esse repellat laboriosam, dolores porro tempora nihil aliquam quidem perferendis saepe quaerat quos eum nisi.',
performanceTargets: ['Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.'],
photo: MBVillage,
businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
              \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
              \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,

// 
floorplans: [mmcfp1, mmcfp2],
targetedHold: '3 yr',
marketCap: 129000000,
photos: [mvil1, mvil2, mvil3],
},{
  id: 2,
  name: 'Century City Condos',
  location: 'Century City, CA',
  assetType: 'Ground-Up',
  developer: developers[0],
  targetIRR: 15.8,
  acquisitionDate: 'June 27, 2022',
  size: '22,181 sqft',
  currentOffering: '$0',
  totalOffering: '$5,000,000',
  description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut maxime quasi esse ipsa ab pariatur vitae omnis distinctio facilis voluptate libero, nam ipsam, recusandae deserunt mollitia aperiam repellat, ducimus tempora. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab rerum veritatis hic, amet laborum pariatur esse repellat laboriosam, dolores porro tempora nihil aliquam quidem perferendis saepe quaerat quos eum nisi.',
  performanceTargets: ['Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.'],
  photo: Condos,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  targetedHold: '5 yr',
  marketCap: 22000000,
  floorplans: [mmcfp1, mmcfp2],
  photos: [Condos],
  },{
    id: 3,
    name: 'Manhattan Medical Center',
    location: 'Manhattan Beach, CA',
    assetType: 'Commercial',
    developer: developers[0],
    targetIRR: 18.2,
    acquisitionDate: 'June 27, 2022',
    size: '40,000 sqft',
    currentOffering: '$0',
    totalOffering: '$20,000,000',
    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut maxime quasi esse ipsa ab pariatur vitae omnis distinctio facilis voluptate libero, nam ipsam, recusandae deserunt mollitia aperiam repellat, ducimus tempora. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab rerum veritatis hic, amet laborum pariatur esse repellat laboriosam, dolores porro tempora nihil aliquam quidem perferendis saepe quaerat quos eum nisi.',
    performanceTargets: ['Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.'],
    photo: MMC,
    businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
    
    // 
    targetedHold: '4 yr',
    marketCap: 85000000,
    floorplans: [mmcfp1, mmcfp2],
    photos: [mmc1, mmc2, mmc3],
  },{
  id: 4,
  name: 'Manhattan Village',
  location: 'Manhattan Beach, CA',
  assetType: 'Commercial',
  developer: developers[1],
  targetIRR: 19.1,
  acquisitionDate: 'June 27, 2022',
  size: '40,000 sqft',
  currentOffering: '$0',
  totalOffering: '$20,000,000',
  description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut maxime quasi esse ipsa ab pariatur vitae omnis distinctio facilis voluptate libero, nam ipsam, recusandae deserunt mollitia aperiam repellat, ducimus tempora. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab rerum veritatis hic, amet laborum pariatur esse repellat laboriosam, dolores porro tempora nihil aliquam quidem perferendis saepe quaerat quos eum nisi.',
  performanceTargets: ['Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.'],
  photo: MBVillage,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  
  // 
  targetedHold: '8 yr',
  marketCap: 100000000,
  floorplans: [mmcfp1, mmcfp2],
  photos: [mvil1, mvil2, mvil3],
  },{
    id: 5,
    name: 'Century City Condos',
    location: 'Century City, CA',
    assetType: 'Commercial',
    developer: developers[0],
    targetIRR: 14.8,
    acquisitionDate: 'June 27, 2022',
    size: '40,000 sqft',
    currentOffering: '$0',
    totalOffering: '$20,000,000',
    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut maxime quasi esse ipsa ab pariatur vitae omnis distinctio facilis voluptate libero, nam ipsam, recusandae deserunt mollitia aperiam repellat, ducimus tempora. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab rerum veritatis hic, amet laborum pariatur esse repellat laboriosam, dolores porro tempora nihil aliquam quidem perferendis saepe quaerat quos eum nisi.',
    performanceTargets: ['Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.'],
    photo: Condos,
    businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
    
    // 
    targetedHold: '3 yr',
    marketCap: 30000000,
    floorplans: [mmcfp1, mmcfp2],
    photos: [mvil1, mvil2, mvil3],
    },{
    id: 6,
    name: 'Cotton Shopping Center',
    location: 'Surprise, AZ',
    assetType: 'Commercial',
    developer: developers[0],
    targetIRR: 12.8,
    acquisitionDate: 'June 27, 2022',
    size: '40,000 sqft',
    currentOffering: '$0',
    totalOffering: '$20,000,000',
    description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut maxime quasi esse ipsa ab pariatur vitae omnis distinctio facilis voluptate libero, nam ipsam, recusandae deserunt mollitia aperiam repellat, ducimus tempora. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab rerum veritatis hic, amet laborum pariatur esse repellat laboriosam, dolores porro tempora nihil aliquam quidem perferendis saepe quaerat quos eum nisi.',
    performanceTargets: ['Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.',
                        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.'],
    photo: CottonGreenway,
    businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
    targetedHold: '3 yr',
    marketCap: 20000000,
    floorplans: [mmcfp1, mmcfp2],
    photos: [mvil1, mvil2, mvil3],
    }


]

/*export const properties = [{
    name: 'Manhattan Medical Center',
    location: 'Manhattan Beach, CA',
    description: 'Top medical space in Manhattan Beach',
    marketCap: '$45M',
    initialInvestment: '$500,000',
    investmentValue: '$1,280,942',
    nextDistribution: 'November 13th, 2022',
    distributionsToDate: '$850,273',
    projectPhase: 'Leased',
    targetedHold: '30 yr',
    IRR: '16%',
    photo: MMC,
    businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },{
  name: 'Manhattan Village',
  location: 'Manhattan Beach, CA',
  marketCap: '$120M',
  initialInvestment: '$500,000',
  investmentValue: '$1,280,942',
  nextDistribution: 'November 13th, 2022',
  distributionsToDate: '$850,273',
  projectPhase: 'Leased',
  targetedHold: '10 yr',
  IRR: '16%',
  photo: MBVillage,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },{
  name: 'Century City Condos',
  location: 'Century City, CA',
  marketCap: '$20M',
  initialInvestment: '$300,000',
  investmentValue: '$500,293',
  nextDistribution: 'October 4th, 2022',
  distributionsToDate: '$220,273',
  projectPhase: 'Under Construction',
  targetedHold: '30 yr',
  IRR: '16%',
  photo: Condos,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },{
    name: 'Manhattan Medical Center',
    location: 'Manhattan Beach, CA',
    description: 'Top medical space in Manhattan Beach',
    marketCap: '$45M',
    initialInvestment: '$500,000',
    investmentValue: '$1,280,942',
    nextDistribution: 'November 13th, 2022',
    distributionsToDate: '$850,273',
    projectPhase: 'Leased',
    targetedHold: '30 yr',
    IRR: '16%',
    photo: MMC,
    businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },{
  name: 'Manhattan Village',
  location: 'Manhattan Beach, CA',
  marketCap: '$120M',
  initialInvestment: '$500,000',
  investmentValue: '$1,280,942',
  nextDistribution: 'November 13th, 2022',
  distributionsToDate: '$850,273',
  projectPhase: 'Leased',
  targetedHold: '10 yr',
  IRR: '16%',
  photo: MBVillage,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },{
  name: 'Century City Condos',
  location: 'Century City, CA',
  marketCap: '$20M',
  initialInvestment: '$300,000',
  investmentValue: '$500,293',
  nextDistribution: 'October 4th, 2022',
  distributionsToDate: '$220,273',
  projectPhase: 'Under Construction',
  targetedHold: '30 yr',
  IRR: '16%',
  photo: Condos,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  }, {
    name: 'Manhattan Medical Center',
    location: 'Manhattan Beach, CA',
    description: 'Top medical space in Manhattan Beach',
    marketCap: '$45M',
    initialInvestment: '$500,000',
    investmentValue: '$1,280,942',
    nextDistribution: 'November 13th, 2022',
    distributionsToDate: '$850,273',
    projectPhase: 'Leased',
    targetedHold: '30 yr',
    IRR: '16%',
    photo: MMC,
    businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },{
  name: 'Manhattan Village',
  location: 'Manhattan Beach, CA',
  marketCap: '$120M',
  initialInvestment: '$500,000',
  investmentValue: '$1,280,942',
  nextDistribution: 'November 13th, 2022',
  distributionsToDate: '$850,273',
  projectPhase: 'Leased',
  targetedHold: '10 yr',
  IRR: '16%',
  photo: MBVillage,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },{
  name: 'Century City Condos',
  location: 'Century City, CA',
  marketCap: '$20M',
  initialInvestment: '$300,000',
  investmentValue: '$500,293',
  nextDistribution: 'October 4th, 2022',
  distributionsToDate: '$220,273',
  projectPhase: 'Under Construction',
  targetedHold: '30 yr',
  IRR: '16%',
  photo: Condos,
  businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },
  ]

  /*

  ,{
    name: 'Cotton Greenway Shopping Center',
    location: 'Surprise, AZ',
    marketCap: '$12M',
    initialInvestment: '$300,000',
    investmentValue: '$500,293',
    nextDistribution: 'October 4th, 2022',
    distributionsToDate: '$220,273',
    projectPhase: 'Under Construction',
    targetedHold: '8 yr',
    IRR: '16%',
    photo: CottonGreenway,
    businessPlan: `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.
                  \nLorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla quam iusto ratione tempore vel ipsam porro, assumenda, nam ullam velit excepturi officia. Aliquid repudiandae alias nemo architecto ea blanditiis accusamus.`,
  },

  */