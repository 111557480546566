import React from 'react'
import {Button} from 'components/shared/ButtonElement'
import styled from 'styled-components'

const Container = styled.div`
    
`
const Image = styled.img`
    height: 350px;
    width: 100%;
`

const Description = styled.p`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const PropertyBox = ( {location, title, description, image, id} ) => {
    return (
        <Container>
            <Image src={image} />
            <h3> {location} </h3>
            <h1> {title} </h1>
            <Description> {description} </Description>
            <div class="button-div"> <Button to={`/offering/${id}`} primary> View Offering </Button> </div>
        </Container>
    )
}

export default PropertyBox