import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from './button.js'


const NoHassle = () => {
    return (
        <div className="container4homeblk">
        <span style = {{ padding: 0, border: "3px dashed white"}}>
            <header className="homebigtxt" style={{color: 'white'}}> No hassle, quick and easy platform to find the best investment for your goals.</header>
            <Link to="/marketplace"><Button solidcolor = { 'white' } textColor = {'black'} text = {'Investment Opportunities'}></Button></Link>
        </span>
    </div>
    )
}

export default NoHassle