import React from 'react'

import Title from 'components/newoffering/Title'
import ImageAndProgress from 'components/newoffering/ImageAndProgress'
import InvestSection from 'components/newoffering/InvestSection'

import {HeroHolder, Sidebar, MobileTitleHolder, DesktopTitleHolder} from '../components'

import SidebarFacts from 'components/newoffering/Hero/SidebarFacts'


const RentalHero = ({offering, goToCalculator}) => {
  return (
    <HeroHolder>
      <MobileTitleHolder> <Title offering={offering} /> </MobileTitleHolder> 
      <ImageAndProgress offering={offering} />
      
        <Sidebar>
          <DesktopTitleHolder> <Title offering={offering} /> </DesktopTitleHolder>
          <SidebarFacts offering={offering} goToCalculator={goToCalculator} />
          <InvestSection offering={offering} />
        </Sidebar>

    </HeroHolder>
  )
}

export default RentalHero