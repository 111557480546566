import React from 'react';
import styled from 'styled-components';

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-top: 20px;
`;

const Header = styled.h2`
  text-align: left;
  padding-left: 50px;
`;

const Table = styled.table`
  width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  border-collapse: collapse;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
`;

const TableRow = styled.tr`
  background-color: white;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
  height: 80px;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: #FFFFFF;
  color: black;
  width: ${props => props.width};
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
`;


const CashFlow = () => {


    const tableData = [
      ['Effective Gross Revenue', '$3,084,310', '$3,965,492', '$4,206,991'],
      ['Total Operating Expenses', '$1,085,959', '$1,108,379', '$1,131,268'],
      ['Net Operating Income', '$1,998,351', '$2,857,114', '$2,953,189'],
    ];
  
    return (
      <TableSection>
        <Table>
          <thead>
            <TableRow>
              <TableHeader width="40%">Cash Flow Summary (after construction)</TableHeader>
              <TableHeader width="20%">Year 1</TableHeader>
              <TableHeader width="20%">Year 2</TableHeader>
              <TableHeader width="20%">Year 3</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            {tableData.map((row, i) => (
              <TableRow key={i}>
                {row.map((cell, j) => (
                  <TableCell key={j}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableSection>
    );
  };

export default CashFlow;