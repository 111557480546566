import styled from "styled-components";

export const Divider = styled.div`
    margin: 1rem auto;
    max-width: 1000px;
    height: .125rem;
    background-color: #E8E9EA;
    
`

export const MiniDivider = styled.div`
    margin 1rem auto;
    width: 250px;
    height: .125rem;
    background-color: #E8E9EA;
    
`

export const SectionTitle = styled.h1`
    color: #1F2937;
    font-weight: bold;
    font-size: ${ (props) => props.size};
    padding: 0;
    margin: 0;
    position: relative;


    &:after{
        position: absolute;
        bottom: 0px;
        right: 0;
        content: '';
        background-color: white;
        height: 2.5px;
        width: 100%;
    }
    
`