import React, {useState} from 'react'
import { SidebarMenuBtnWrapper, SidebarMenuButton, SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu, SidebarSignUpButton } from './SidebarElements'

const Sidebar = ({color}) => {
    const [isOpen, setIsOpen] = useState(false);

  return (
    <>
    <SidebarMenuBtnWrapper> <SidebarMenuButton color={color ? color : "black"} onClick={ () => setIsOpen(!isOpen)} /> </SidebarMenuBtnWrapper>
    <SidebarContainer isOpen={isOpen}>
        <Icon>
            <CloseIcon isOpen={isOpen} onClick={ () => setIsOpen(!isOpen)}/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="/">
                    Home
                </SidebarLink>
                <SidebarLink to="/marketplace">
                    Marketplace
                </SidebarLink>
                <SidebarLink to="/resources">
                    Resources
                </SidebarLink>
                <SidebarLink to="/about">
                    About
                </SidebarLink>
                <SidebarLink to="/signin">
                    Sign In
                </SidebarLink>
                <SidebarLink to="/signup">
                    <SidebarSignUpButton> Sign Up </SidebarSignUpButton>
                </SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>

    </>
  )
}

export default Sidebar