import styled from "styled-components";

export const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Inner = styled.div`
    width: ${ (props) => props.width};
`