import React, {useState} from 'react'
import styled from 'styled-components'
import MMC from 'photos/MMC.jpeg'
import {Button} from 'components/shared/ButtonElement'
import MainNavbar from 'components/navbars/MainNavbar'
import {useParams} from 'react-router-dom'
import {properties} from 'components/marketplace/MarketplaceApp/data'
import Sidebar from 'components/Sidebar'
import Floorplan from 'components/Offering/Floorplan'
import CatalinaVillage from 'components/offeringCatalina/CatalinaVillage'
import CenturyCity from 'components/offeringCentury/CenturyCity'
import {nFormatter} from 'utils/utils.js'


import rentroll from 'pdfs/century/rentroll.pdf'
import construction from 'pdfs/century/construction.pdf'
import proforma from 'pdfs/century/proforma.pdf'

import { filterById } from 'utils/utils.js'

const accent = '#0A68EC'

const Header = styled.div`
`

const Title = styled.h1`
  &::after{
    content:'Closing in 3 Days';
    color: red;
    display: block;
    font-size: .4em;
  }`


const HeroImgContainer = styled.div`
  width: 1200px;
  
`
const HeroImg = styled.img`
  width: 100%;
  
`

const HeroWrapper=styled.div`
  width: 80%;

`

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
`

const HeroContent = styled.div`
  display: flex;
  flex-direction: row;
`
const Facts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const Fact = styled.h3`
  text-align: center;
  &::before{
    content: '${ ({name}) => name }  |';
    
    color: #808080;
  }

`

const HeroImgFooter = styled.h4`
  margin-top: 0;
  margin-bottom: 0;
`

const InfoSection = styled.div`
  margin-left: 12em;
  margin-right: 12em;
`

const InfoHeader = styled.h2`
  &::after{
    content: '';
    display:block;
    background-color: #808080;
    width: 100%;
    height: 2px;
  }
`

const Info = styled.div`
display: block;
gap: 20px;
line-height: 1.5;
`

const BigFacts = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
`

const BigFact = styled.h2`
  font-size: 1.5em;
  white-space: nowrap;
  &::before{
    content: '${ ({name}) => name }';
    color: #808080;
    display: block;
    font-size: .75em;
  }
`

const InvestButton = styled(Button)`
  margin-top: auto;
  background-color: ${accent};
`

const ButtonCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1em;
`

const InfoP = styled.p`
  white-space: pre-line;
  line-height: 1.5;
`

const SponsorImage = styled.img`
  float: left;
  height: 300px;
  aspect-ratio: 1;
  margin-right: 2em;

`

const KeyMetrics = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
`
const Metric = styled(BigFact)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SlideContainer = styled.div`
  width: 100%;
  position: relative;
`

const Slider = styled.input`
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 25px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;

  &:hover{
    opacity: 1;
  }

  &::-webkit-slider-thumb{
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }

  &::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #04AA6D; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
`

const Gallery = styled.div`
  position: relative;
  width: 800px;
  height: 600px;
`

const GalleryLeft = styled.h1`
  position: absolute;
  left: 0;
  top: 50%;

  &:hover{
    cursor: pointer;
  }
`

const GalleryRight = styled.h1`
  position: absolute;
  right: 0;
  top: 50%;

  &:hover{
    cursor: pointer;
  }
`

const Table = styled.table``

const GalleryImage = styled.img`

`



const Offering = () => {
  const [userEstRent, setUserEstRent] = useState(4)
  const [userEstAppreciation, setUserEstAppreciation] = useState(7)
  const [userEstPrincipal, setUserEstPrincipal] = useState(10000)
  const [galleryPhoto, setGalleryPhoto] = useState(0)

  const {id} = useParams();

  // If id matches a special case, return the specific component
  switch (id) {
    case '0':
      return <CatalinaVillage />;
    case '1':
      return <CenturyCity />;
    // Add other cases here for other IDs
    default:
      break;  // Continue to the rest of the component for other IDs
  }

  // Get property info from API call
  const p = filterById(properties, id);

  /*1 - leverage /(1-leverage)*rate*/
  const leverage = 0.75
  const rate = 0.05
  const debtContrib = 100 * (leverage*rate) / (1-leverage) 

  let nPhotos = p.photos.length

  function nextGalleryPhoto(){
    setGalleryPhoto( modulo((galleryPhoto+1), nPhotos))
  }
  function lastGalleryPhoto(){
    setGalleryPhoto( modulo((galleryPhoto-1), nPhotos))
  }
  function modulo(n, d){
    return ((n % d) + d) % d
  }


  return (

    // Todo: Back button + no animation
    <>
        
        <MainNavbar />
        <HeroContainer>
          <HeroWrapper>
            <Header>
              <Title> {p.name} </Title>
            </Header>
            <HeroContent>
              <HeroImgContainer>
                <HeroImg src={p.photo} />
                <HeroImgFooter> Acquired {p.acquisitionDate} </HeroImgFooter>
                <Facts>
                  <Fact name='Rentable Area'> {` ${p.size}`} </Fact>
                  <Fact name='Offering'> {` ${p.currentOffering}`} / {` ${p.totalOffering}`} </Fact>
                </Facts>
                
              </HeroImgContainer>

              <BigFacts>
                
                  <BigFact name='Location'> {p.location} </BigFact>
                  <BigFact name='Deal Type'> {p.assetType} </BigFact>
                  <BigFact name='Developer'> {p.developer.name} </BigFact>
                  <BigFact name='Hold Period'> 20+ yrs </BigFact>
                  <BigFact name='Target IRR'> {p.targetIRR}% </BigFact>
                  <ButtonCont>
                    <InvestButton to={`/invest/${p.id}`}> Invest Now </InvestButton>
                  </ButtonCont>
              </BigFacts>
            </HeroContent>
          </HeroWrapper>
        </HeroContainer>

        <InfoSection>
            <InfoHeader> Description </InfoHeader>
            <Info>
                <InfoP> {p.description} </InfoP>
            </Info>

            
            <InfoHeader> Key Metrics</InfoHeader>
            <h3> Properrty Metrics </h3>
            <Info>
              <KeyMetrics>
                <Metric name='Rentable Area'> 27,181 sqft </Metric>
                <Metric name='Units'> 22 </Metric>
                <Metric name='Target IRR'> {p.targetIRR}% </Metric>
                <Metric name='Net Operating Income'> $1.5M </Metric>
                <Metric name='Property Value'> $24.3M </Metric>
                
              </KeyMetrics>
            </Info>
            
            <InfoHeader> Key Metrics </InfoHeader>
            <Info>
            <h3> Estimated Returns </h3>
              <KeyMetrics>
                <Metric name='Estimated Rental Dividend'> 4% </Metric>
                <Metric name='Estimated Annual Appreciation'> 7% </Metric>
              </KeyMetrics>
            <h3> Return Calculator </h3>
            
            <SlideContainer>
              Rental Dividend
              <Slider type="range" min="3" max="20" value={userEstRent} onInput={(e) => {setUserEstRent(e.target.value)}} /> 
              {userEstRent}%
              <br/>
              Annual Appreciation
              <Slider type="range" min="3" max="20" value={userEstAppreciation} onInput={(e) => {setUserEstAppreciation(e.target.value)}} /> 
              {userEstAppreciation}%
              <br/>

              Annual Return (<a href="" style={{color: "light-blue", textDecoration: "none" }}>How is this number calculated?</a>)
              <Slider type="range" min="4" max="40" value={parseFloat(userEstRent) + parseFloat(userEstAppreciation)/(1-leverage) - debtContrib} readonly/> 
              {Math.round(parseFloat(userEstAppreciation)/(1-leverage) + parseFloat(userEstRent) - debtContrib, 2).toFixed()}%

              <p>
              Rental dividend is the average of monthly operating income for the property.
              </p>
              <p>
              Annual appreciation is calculated from local data.  
              </p>
            </SlideContainer>
            </Info>
            
            <InfoHeader>Photos</InfoHeader>
            <Info>
              <Gallery>
                <GalleryLeft onClick={lastGalleryPhoto}> &lt; </GalleryLeft>
                <GalleryRight onClick={nextGalleryPhoto} > &gt; </GalleryRight>
                <GalleryImage src={p.photos[galleryPhoto]} />
              </Gallery>
            </Info>


            <InfoHeader> Management </InfoHeader>
            <Info>
                <SponsorImage src={p.developer.developerPhoto} />
                <InfoP> {p.developer.developerDescription} </InfoP>
                
            </Info>
            <InfoHeader> Floor Plan </InfoHeader>
            <Info>
              <Floorplan property={p} />
            </Info>
            
            <InfoHeader> Rent Roll </InfoHeader>
            <iframe src={rentroll} width="100%" height="500px"></iframe>

            <InfoHeader> Construction Costs </InfoHeader>
            <iframe src={construction} width="100%" height="500px"></iframe>

            <InfoHeader> Pro Forma </InfoHeader>
            <iframe src={proforma} width="100%" height="500px"></iframe>


            <InfoHeader> Business Plan </InfoHeader>
            <Info>
              <InfoP> {p.businessPlan} </InfoP>
            </Info>
            <InfoHeader> Performance Targets </InfoHeader>
            <Info>
              <ul>
                {(p.performanceTargets).map( (t, i) => {return (<li key={i}> {t} </li> )} ) }
              </ul>
            </Info>
            <InfoHeader>Comparables</InfoHeader>
        </InfoSection>


        
        

    </>
  )
}

export default Offering