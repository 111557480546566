import React from 'react'
import { Outer, Inner, Step, StepTitle, StepBody, SectionTitle, StepImg } from './components'


import { clampBuilder } from 'utils/utils';

import earn from 'photos/home/earn.jpg'
import search from 'photos/home/search.jpg'
import invest from 'photos/home/invest.jpg'

let minWidth = 360;
let maxWidth = 1680;

const Steps = () => {

    /*
    const StepArrow =  (<Arrow
                        direction="right"
                        shaftWidth={10}
                        shaftLength={50}
                        headWidth={30}
                        headLength={15}
                        fill="white"
                        
                        strokeWidth={2}
                        onClick={() => alert('You clicked the arrow!')}
                        />)*/

    

    let sectionMin = 2.2;
    let sectionMax = 3.2;
    
    let titleMin = 1.8;
    let titleMax = 2.4;
    let bodyMin = titleMin * .5;
    let bodyMax = titleMax * .5;


  return (
    
    <Outer>
    

        <SectionTitle size={clampBuilder(minWidth, maxWidth, sectionMin, sectionMax)}>
            How It Works
        </SectionTitle>
        <Inner>
            
            <Step width={clampBuilder(minWidth, maxWidth, 330/16, 440/16)}>
                <StepImg src={search} width="100%" />
                <StepTitle size={clampBuilder(minWidth, maxWidth, titleMin, titleMax)}> <font style={{fontWeight: 'normal'}}>1.</font> Search </StepTitle>
                <StepBody size={clampBuilder(minWidth, maxWidth, bodyMin, bodyMax)}> Choose an investment property from our expert-curated marketplace. </StepBody>
            </Step>
            
            <Step width={clampBuilder(minWidth, maxWidth, 330/16, 440/16)}>
                <StepImg src={invest} width="100%" />
                <StepTitle size={clampBuilder(minWidth, maxWidth, titleMin, titleMax)}> <font style={{fontWeight: 'normal'}}>2.</font> Invest </StepTitle>
                <StepBody size={clampBuilder(minWidth, maxWidth, bodyMin, bodyMax)}> Link a bank account and make your first investment in under five minutes. </StepBody>
            </Step>
            
            <Step width={clampBuilder(minWidth, maxWidth, 330/16, 440/16)}>
                <StepImg src={earn} width="100%" />
                <StepTitle size={clampBuilder(minWidth, maxWidth, titleMin, titleMax)}> <font style={{fontWeight: 'normal'}}>3.</font> Earn </StepTitle>
                <StepBody size={clampBuilder(minWidth, maxWidth, bodyMin, bodyMax)}> Enjoy quarterly rental dividends or watch a new development break ground. </StepBody>
            </Step>

        </Inner>
        
    </Outer>
  )
}

export default Steps
