import styled from "styled-components";
import { MiniDivider } from "../MiscComponents/misc";

export const Outer = styled.div`
    padding: 0.5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding-left: 2.5%;
    padding-right: 2.5%;

`


export const SubTitle = styled.h4`
    padding: 0;
    margin: 0 3rem;
    font-size: ${(props) => props.size};
    font-weight: normal;
    text-align: center;
    color: #1F2937;
`

export const OfferingsGrid = styled.div`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    gap: 2.5rem 0rem;

    @media(max-width: 750px){
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

`

export const OfferingInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    text-align: center;
    height: 100%;
    margin: 0 auto;
`

export const ItemHeader = styled.h1`
    font-size: ${(props) => props.size};
    color: #1F2937;
`

export const ItemCaption = styled.p`
    font-size: ${(props) => props.size};
    color: #1F2937;
`

export const DesktopCardHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 750px){
        display: none;
    }
`

export const MobileCardHolder = styled.div`
    display: none;

    @media(max-width: 750px){
        display: flex;
        align-items: center;
        justify-content: center;    
    }
`

export const DesktopIcon = styled.img`
    display: inline;
    @media(max-width: 750px){
        display: none;
        width: 30px;
        height: 30px;
    }
`

export const MobileIcon = styled.img`
    display: none;
    @media(max-width: 750px){
        display: inline;
        width: 50px;
        height: 50px;
    }
`

export const MobileMiniDivider = styled(MiniDivider)`
    display: none;
    @media(max-width: 750px){
        display: block;
    }
`