// import React from 'react';
// import './FinancialSection.css';

// const FinancialSection = ({ data }) => {
//   // Calculate the total value for scaling the bars.
//   const totalValue = data.reduce((total, section) => total + section.value, 0);

//   return (
//     <div className="financials">
//       <h2>Financials</h2>
//       <h3>Capital Stack</h3>
//       <p>Project Capitalization (88% Committed)</p>
//       <div className="chart">
//         <div className="line-container">
//           <div className="line" style={{ height: `${(data[0].value + data[1].value) / totalValue * 100}%` }}>
//             <div className="arrow">&#8592; 60%</div>
//           </div>
//           <div className="line" style={{ height: `${(data[2].value + data[3].value) / totalValue * 100}%` }}>
//             <div className="arrow">&#8592; 40%</div>
//           </div>
//         </div>
//         {data.map((section, index) => (
//           <div
//             key={index}
//             className="section"
//             style={{
//               backgroundColor: section.color,
//               height: `${section.value / totalValue * 100}%`,
//             }}
//           >
//             <span>${section.value.toFixed(1)}M</span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default FinancialSection;




// import React from 'react';
// import styled from 'styled-components';

// const Section = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
// `;

// const Header = styled.h2`
//   text-align: center;
// `;

// const TextWrapper = styled.div`
//   width: 80%;
//   margin-left: 20%;
// `;

// const SubHeader = styled.h3`
//   text-align: left;
//   text-decoration: underline;
//   margin-bottom: 5px;
// `;

// const Info = styled.p`
//   text-align: left;
//   margin-top: 5px;
// `;

// const Rectangle = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 40%;
//   height: 500px;
//   margin-top: 20px;
//   position: relative;
// `;

// const Box = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 60%;
//   color: white;
//   background-color: ${props => props.color};
//   flex: ${props => props.flex};
//   z-index: 1;
// `;

// // const Line = styled.div`
// //   position: absolute;
// //   background-color: black;
// //   width: 1px;
// //   height: ${props => props.height};
// //   left: ${props => props.left};
// //   top: ${props => props.top};
// // `;

// // const Line = styled.div`
// //   position: absolute;
// //   left: -10px;
// //   width: 2px;
// //   background-color: black;
// //   top: 0;
// // `;

// // const Percentage = styled.p`
// //   position: absolute;
// //   left: -40px;
// //   top: 50%;
// //   transform: translateY(-50%);
// // `;
// const Line = styled.div`
//   position: absolute;
//   left: -5px;
//   width: 1px;
//   background-color: black;
//   top: ${props => props.top}%;
// `;

// const Percentage = styled.p`
//   position: absolute;
//   left: -45px;
//   top: ${props => props.top}%;
// `;

// const FinancialSection = () => {
//   const numbers = [2.3, 11.0, 11.0, 22.5];
//   const colors = ["lightblue", "cornflowerblue", "skyblue", "powderblue"];

//   const percentages = [28, 72]; // The percentages for each line


//   return (
//     <Section>
//       <Header>Financials</Header>
//       <TextWrapper>
//         <SubHeader>Capital Stack</SubHeader>
//         <Info>Project Capitalization (88% Committed)</Info>
//       </TextWrapper>
//       <Rectangle>
//         {numbers.map((num, index) => (
//             <Box key={index} flex={num} color={colors[index]}>
//             ${num.toFixed(1)}M
//             </Box>
//         ))}
//         <Line top={0} style={{height: `${percentages[0]}%`}} />
//         <Percentage top={percentages[0]/2 - 5}>{percentages[0]}%</Percentage>
//         <Line top={percentages[0]} style={{height: `${percentages[1]}%`, left:'-15px'}} />
//         <Percentage top={percentages[0] + percentages[1]/2 - 5} style={{left: '-55px'}}>{percentages[1]}%</Percentage>
//       </Rectangle>
//     </Section>
//   );
// };

// export default FinancialSection;
import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Header = styled.h2`
  text-align: center;
  font-size: 30px;
`;

const TextWrapper = styled.div`
  width: 80%;
  margin-left: 20%;
`;

const SubHeader = styled.h3`
  text-align: left;
  text-decoration: underline;
  margin-bottom: 5px;
`;

const Info = styled.p`
  text-align: left;
  margin-top: 5px;
`;

const Rectangle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 500px;
  margin-top: 20px;
  position: relative;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  color: white;
  background-color: ${props => props.color};
  flex: ${props => props.flex};
  border-top: 0.5px solid #528389;
  border-left: 1px solid #528389;
  border-right: 1px solid #528389;
`;

const Line = styled.div`
  position: absolute;
  width: 1px;
  background-color: black;
  right: -10px;
  top: ${props => `${props.top}%`};
`;

const Line0 = styled.div`
  position: absolute;
  left: -2px;
  width: 1px;
  background-color: black;
  top: ${props => props.top}%;
`;

const Percentage = styled.p`
  position: absolute;
  right: -30px;
  top: ${props => `${props.top}%`};
`;

const Percentage0 = styled.p`
  position: absolute;
  left: -150px;
  top: ${props => props.top}%;
`;

const FinancialSection = () => {
  const numbers = [2.3, 11.0, 11.0, 22.5];
  const colors = ["lightblue", "cornflowerblue", "skyblue", "powderblue"];
  const percentagesLeft = [28, 72];  // for the left lines
  const percentagesRight = [30, 70, 50, 50];

  return (
    <Section>
      <Header>Financial Breakdown</Header>
      <TextWrapper>
        <SubHeader>Capital Stack</SubHeader>
        <Info>Project Capitalization (88% Committed)</Info>
      </TextWrapper>
      <Rectangle>
        {numbers.map((num, index) => (
          <Box key={index} flex={num} color={colors[index]}>
            ${num.toFixed(1)}M
          </Box>
        ))}
        <Line0 top={0} style={{height: `${percentagesLeft[0]}%`}} />
        <Percentage0 top={percentagesLeft[0]/2 - 5}>Equity - {percentagesLeft[0]}%</Percentage0>
        <Line0 top={percentagesLeft[0]} style={{height: `${percentagesLeft[1]}%`, left: '-10px'}} />
        <Percentage0 top={percentagesLeft[0] + percentagesLeft[1]/2 - 5}style = {{left: '-250px'}}>Financing & <br/>Construction Debt - {percentagesLeft[1]}%</Percentage0>

        {/* <Line right={0} style={{height: `${percentagesRight[0]}%`}} />
        <Percentage right={0} style={{top: `${percentagesRight[0]/2}%`}}>{percentagesRight[0]}%</Percentage>
        <Line right={10} style={{height: `${percentagesRight[1]}%`}} />
        <Percentage right={10} style={{top: `${percentagesRight[0] + percentagesRight[1]/2}%`}}>{percentagesRight[1]}%</Percentage>
        <Line right={20} style={{height: `${percentagesRight[2]}%`}} />
        <Percentage right={20} style={{top: `${percentagesRight[0] + percentagesRight[1] + percentagesRight[2]/2}%`}}>{percentagesRight[2]}%</Percentage>
        <Line right={30} style={{height: `${percentagesRight[3]}%`}} />
        <Percentage right={30} style={{top: `${percentagesRight[0] + percentagesRight[1] + percentagesRight[2] + percentagesRight[3]/2}%`}}>{percentagesRight[3]}%</Percentage> */}
      </Rectangle>
    </Section>
  );
};

export default FinancialSection;
