import React, { useState, useEffect } from 'react';
import './SelectShares.css';

const maxShares = 100000;
const shareOptions = [10, 250, 500, 1000, maxShares];

const SelectShares = ({ setCanProceed, enteredAmount, setEnteredAmount }) => {
    const [selectedOption, setSelectedOption] = useState(1);
    const [checked, setChecked] = useState(false);

    const perShare = 1;

    const handleOptionClick = (index) => {
        setSelectedOption(index);
        if (typeof shareOptions[index] === 'number') {
            setEnteredAmount(shareOptions[index] * perShare);
        }
    }

    const handleAmountChange = (event) => {
        setEnteredAmount(event.target.value);
    }

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    }

    const validateAmount = () => {
        return enteredAmount % perShare === 0 && enteredAmount <= maxShares * perShare;
    }

    useEffect(() => {
        setCanProceed(validateAmount() && checked);
    }, [enteredAmount, checked]);

    return (
        <div className="select-shares">
            <div className="options">
            {shareOptions.map((option, index) => (
                <div 
                    key={index} 
                    className={`option ${enteredAmount / perShare === option ? 'active' : ''}`}
                    onClick={() => setEnteredAmount(option * perShare)}
                >
                    ${new Intl.NumberFormat().format(option * perShare)}
                    <br />
                    {new Intl.NumberFormat().format(option)} Shares
                </div>
            ))}
            </div>
            <div className="amount">
            <div className="input-container">
                    <input 
                        type="text"
                        value={`$${new Intl.NumberFormat().format(enteredAmount)}`}
                        onChange={e => {
                            const val = e.target.value.replace('$', '').replace(/,/g, '');
                            if (!isNaN(val)) setEnteredAmount(val);
                        }}
                    />
                </div>
                <br />
                {validateAmount() ? `${new Intl.NumberFormat().format(enteredAmount / perShare)} Shares` : enteredAmount > maxShares * perShare ? `Please enter a value less than $${new Intl.NumberFormat().format(maxShares * perShare)}` : 'Please enter a multiple of 10.'}
            </div>
            <div className="disclaimer">
                <label>
                <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
                I understand I am investing with the intention of holding my securities for the target investment period, and that MarketSpace will not offer refunds on my investment. Note: you will be able to review the investment details before finalizing the purchase.
                </label>
            </div>
        </div>
    );
}

export default SelectShares;
