import React from 'react'
import Mallp from 'photos/homeextraphotos/mall.jpeg'
import {Link} from 'react-router-dom'
import {Button} from './button.js'

const ElevInfo = () => {
    return (
        <div className="container4homeblk">
        <span >
            <header className="homebigtxt" style = {{ color: 'white' }}>Real Estate investments open new doors</header>  
            <h2 style = {{ color: '#F0EFEF' }}>Investing through Real Estate Investment Trusts (REITs) or crowdfunding sources allows you to get started in real estate without deep pockets and large upfront costs.</h2>
            <p style = {{ color: '#F0EFEF' }}>Read on to see how our system works</p>
            <Link to="/resources" ><Button solidcolor = { 'red' } textColor = {'white'} border = {10} text = {'Read On'}></Button></Link>
        </span>
        <span>
            <img alt = 'Mall Pic' src = { Mallp } style={{ maxHeight: '100%', maxWidth: '80%', marginLeft: '10px' }} />
        </span>
    </div>
    )
}

export default ElevInfo

