import HeroSection from 'components/home/HeroSection'
import React, {useEffect} from 'react'
import HomeNavbar from 'components/navbars/HomeNavbar'
import InfoSection from 'components/home/InfoSection'
import FactsSection from 'components/home/FactsSection'
import Footer from 'components/home/Footer'
import HomeSlider from 'components/home/HomeSlider'
import SpacerWhite from 'components/home/SpacerWhite'
import SpacerBlk from 'components/home/SpacerBlk'

import BluePrint from 'components/home/HomeExtras/blueprint'
import ElevInfo from 'components/home/HomeExtras/elevator'
import NoHassle from 'components/home/HomeExtras/nohassle'



const VideoHome = ( {user} ) => {
  useEffect(() => {window.scrollTo(0, 0)}, []);
  return (
    <>
        <HomeNavbar />
        <HeroSection />
        <InfoSection />
        <FactsSection />
        <HomeSlider />
        <ElevInfo />
        <BluePrint />
        <NoHassle />
        <Footer />
    </>
  )
}

export default VideoHome