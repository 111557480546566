import React, {useState} from 'react'
import { AlreadyHaveAnAccount, Blue, Blue1, Card, Eye, EyeSlash, FieldsCard, Footer, InputBox, LowerCard, Outer, SignUpCard, Subtext, UpperCard } from './components'

import Logo from 'components/navbars/MainNavbar/msLogo'

import { Button } from 'components/shared/ButtonElement'
import { ArrowForward, ArrowRight } from 'components/home/HeroSection/HeroElements'

//import { Auth } from 'aws-amplify';


const Signup = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
      setHover(!hover)
    }

    const [click, setClick] = useState(false)

    const toggleEye = () => {
        setClick(!click);
    }

    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')

    /*async function signUp() {
        try {
            const { user } = await Auth.signUp({
                email,
                pass,
                autoSignIn: { // optional - enaes auto sign in after user is confirmed
                    enabled: true,
                }
            });
            console.log(user);
        } catch (error) {
            console.log('error signing up:', error);
        }
    }*/

  return (
    <Outer>
        <Card>
            <UpperCard>
                <Logo />
                <Subtext> Invest in Something Real </Subtext>
            </UpperCard>
            <LowerCard>
                <FieldsCard>
                    <InputBox placeholder="Email" type="email" value={email} onChange={(e) => (setEmail(e.target.value))} />
                    <InputBox placeholder="Password" type={click ? "text" : "password"} value={pass} onChange={(e) => setPass(e.target.value)}/>
                    { click ? <EyeSlash onClick={toggleEye} /> : <Eye onClick={toggleEye} /> }
                </FieldsCard>
                <SignUpCard>
                    <Button onClick={() => {}} onMouseEnter={onHover} onMouseLeave={onHover} primary={false} dark="true">
                        Sign Up { hover ? <ArrowForward /> : <ArrowRight /> }
                    </Button>
                    <Footer>
                        <AlreadyHaveAnAccount> Already have an account? </AlreadyHaveAnAccount>
                        <Blue to="/signin"> Log in. </Blue>
                    </Footer>
                </SignUpCard>
            </LowerCard>
        </Card>
    </Outer>
  )
}

export default Signup
