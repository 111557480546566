import React, {useEffect, useState} from 'react'

import MainNavbar from 'components/navbars/MainNavbar'
import Hero from 'components/newhome/Hero'
import Steps from 'components/newhome/Steps'
import Explore from 'components/newhome/Explore'
import OfferingCard from 'components/shared/OfferingCard'
import Footer from 'components/home/Footer'

import OldHero from 'components/home/HeroSection'
import { Divider } from 'components/newhome/MiscComponents/misc'
import HomeNavbar from 'components/navbars/HomeNavbar'

var Scroll   = require('react-scroll');
var Element  = Scroll.Element;
var scroller = Scroll.scroller;

const NewHome = () => {
  const listenScrollEvent = e => {
    if (window.scrollY > 400) {
      setNav('main');
    } else {
      setNav('home');
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', listenScrollEvent);
  }, []);

  const goDown = () => {
    scroller.scrollTo('HeroScroll', {
      duration: 1250,
      delay: 100,
      smooth: true,
      offset: 10, 
    })
  }

  const [nav, setNav] = useState('home');

  return (
    <div>
      
      {nav == 'home' ? <HomeNavbar /> : <MainNavbar />}
      <OldHero goDown={goDown} />
      <Element name="HeroScroll" />
      <Hero />
      <Divider />
      <Steps />
      <Divider />
      <Explore />
      <Divider />
      <Footer />
      
    </div>
  )
}

export default NewHome
