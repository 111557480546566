import React from 'react'
import {properties} from 'components/marketplace/MarketplaceApp/data' 
import PropertyBox from 'components/shared/PropertyBox'

const HomeSlider = () => {
  return (
    <div>
            <div className="property-container" style={{marginBottom: '1em'}}>
                <div className="scrollmenu">
                    {properties.map( (property, i) => {return (<a key={i}> 
                        <PropertyBox
                        location= {property.location}
                        title = {property.name}
                        description = {property.description} 
                        image = {property.photo}
                        id = {property.id}
                        />
                        </a>) }  ) }
                </div>
            </div>

    </div>
  )
}

export default HomeSlider