import React from 'react'

import Signup from 'components/signup/Signup'

const SignIn = () => {
  return (
    <>
        <Signup />
    </>
  )
}

export default SignIn
