import styled from "styled-components";

export const Line = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`

export const Download = styled.a`
    font-weight: bold;
    cursor: pointer;
`