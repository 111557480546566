
import cc from 'photos/10285 Missouri Ave Century City/cc.jpg'
import garage from 'photos/10285 Missouri Ave Century City/Garage.png'
import FirstFloor from 'photos/10285 Missouri Ave Century City/First Floor.png'
import SecondFloor from 'photos/10285 Missouri Ave Century City/Second Floor.png'
import ThirdFloor from 'photos/10285 Missouri Ave Century City/Third Floor.png'
import FourthFloor from 'photos/10285 Missouri Ave Century City/Fourth Floor.png'
import FifthFloor from 'photos/10285 Missouri Ave Century City/Fifth Floor.png'
import SixthFloor from 'photos/10285 Missouri Ave Century City/Sixth Floor.png'

import CatalinaCover from 'photos/Catalina/catalinacoverpic.png'
import gbCover1 from 'photos/greenberg/OCEAN VIEW MAKAHUENA 2303/cover.jpeg'
import gbCover2 from 'photos/greenberg/OCEAN FRONT CONDO AT KAPILI RD #9/cover.jpeg'
import gbCover3 from 'photos/greenberg/LUXURY DESIGNER CONDO BY THE BEACH/outside.jpeg'
import gbCover4 from 'photos/greenberg/DESIGNER RETREAT WITH POOL AND AC BY THE BEACH/cover.jpeg'
import gbCover5 from 'photos/greenberg/OCEAN VIEW POIPU PALMS 104/cover.jpeg'
import gbCover6 from 'photos/greenberg/BEACH BUNGALOW/cover.jpeg'
import gbCover7 from 'photos/greenberg/SURF BUNGALOW/cover.jpeg'
import gbCover8 from 'photos/greenberg/OHANA HOUSE/cover.jpeg'
import gbCover9 from 'photos/greenberg/HULA BUNGALOW/cover.jpeg'



import beds from 'photos/icons/bed-solid.svg'
import baths from 'photos/icons/shower-solid.svg'
import area from 'photos/icons/maximize-solid.svg'
import clock from 'photos/icons/clock-solid.svg'
import hammer from 'photos/icons/hammer-solid.svg'

function getRandomInt(min, max) {

    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

let developmentTag = {
    text: 'Development',
    subText: '',
    color: 'development'
}

let rentalTag = {
    text: 'Rental',
    subText: '',
    color: 'rental'
}

let IPOTag = {
    text: 'IPO',
    subText: '',
    color: 'orange'
}

let completionTag = (date) => {
    return({
    text: 'Completion',
    subText: date,
    color: 'white'
    })
}

let testFacts = [
    {
        icon: beds,
        name: 'Bedrooms',
        body: '2'
    },
    {
        icon: baths,
        name: 'Bathrooms',
        body: '3'
    },
    {
        icon: area,
        name: 'Rentable Area',
        body: '1,478 sqft'
    },
    {
        icon: clock,
        name: 'Build Date',
        body: '2021'
    }
]

let testDevFacts = [
    {
        icon: beds,
        name: 'Units',
        body: '22'
    },
    {
        icon: hammer,
        name: 'Developer',
        body: 'The Watermark Group'
    },
    {
        icon: area,
        name: 'Rentable Area',
        body: '22,171 sqft'
    },
    {
        icon: clock,
        name: 'Est. Completion Date',
        body: 'May 2023'
    }
]



let newsSample = [{
        source: "Property Management",
        title: "Solis Health Signs 10 Year Lease",
        body: "Manhattan Medical Partners LLC has signed a 10 year lease with Solis Health. This will bring $300k/yr of rental income for the property. Solis health is a..."
    },
    {
        source: "The Beach Reporter",
        title: "Bruce’s Beach Sold Back to LA County",
        body: "Bruce's Beach will be sold back to LA County for $20 million The return of the property was hailed as a step toward righting the wrongs inflicted..."
    },
    {
        source: "The Beach Reporter",
        title: "Bruce’s Beach Sold Back to LA County",
        body: "Bruce's Beach will be sold back to LA County for $20 million The return of the property was hailed as a step toward righting the wrongs inflicted..."
    }
]

let ccOffering = {
    name: 'Century Apartments',
    location: 'Los Angeles, CA',
    photos: [cc, garage, FirstFloor, SecondFloor, ThirdFloor, FourthFloor, FifthFloor, SixthFloor],
    class: 'development',
    upperTags: [
        developmentTag,
        {
            text: '$10.00',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        completionTag('Dec 2024')
    ],
    investors: 2,
    progress: 1,
    offeringSize: 5200000,
    targetIRR: 0.20,
    equityMultiple: 2.12,
    facts: testDevFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 20.80,
    newsKeyWord: '"century city"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',0,0],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]
}


// Greenberg 1
let OceanViewMak = {
    name: 'Ocean View Makahuena 2302',
    location: 'Kauai, HI',
    photos: [gbCover1],
    class: 'rental',
    upperTags: [
        rentalTag,
        {
            text: '$12.50',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        {
            text: 'AirBNB',
            subText: '',
            color: 'white'
        }
    ],
    investors: 2,
    progress: 1,
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 12.50,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',500,84],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]

}

// Greenberg 2
let OceanFrontKapili = {
    name: 'Kapili No. 9',
    location: 'Kauai, HI',
    photos: [gbCover2],
    class: 'rental',
    upperTags: [
        rentalTag,
        {
            text: '$12.50',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        {
            text: 'AirBNB',
            subText: '',
            color: 'white'
        }
    ],
    investors: getRandomInt(2000, 4000),
    progress: getRandomInt(30, 99),
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 12.50,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',0,0],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]
}

// Greenberg 3
let luxuryCondoByBeach = {
    name: 'Designer Condo No. 1',
    location: 'Kauai, HI',
    photos: [gbCover3],
    class: 'rental',
    upperTags: [
        rentalTag,
        {
            text: '$12.50',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        {
            text: 'AirBNB',
            subText: '',
            color: 'white'
        }
    ],
    investors: getRandomInt(2000, 4000),
    progress: getRandomInt(30, 99),
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 12.50,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',0,0],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]
}

// Greenberg 4

let DesignerRetreat = {
    name: 'Designer Condo No. 2',
    location: 'Kauai, HI',
    photos: [gbCover4],
    class: 'rental',
    upperTags: [
        rentalTag,
        {
            text: '$12.50',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        {
            text: 'AirBNB',
            subText: '',
            color: 'white'
        }
    ],
    investors: getRandomInt(2000, 4000),
    progress: getRandomInt(30, 99),
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 12.50,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',0,0],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]
}

// Catalina
let CatalinaListing = {
    name: 'Catalina Village',
    location: 'Redondo Beach, CA',
    photos: [CatalinaCover,
        // "https://ms-property-images.s3.us-west-1.amazonaws.com/greenberg/OCEAN+VIEW+POIPU+PALMS+104/0.jpeg",
        // "https://ms-property-images.s3.us-west-1.amazonaws.com/greenberg/OCEAN+VIEW+POIPU+PALMS+104/1.jpeg",
        // "https://ms-property-images.s3.us-west-1.amazonaws.com/greenberg/OCEAN+VIEW+POIPU+PALMS+104/3d601e04-de27-4655-873c-b4d4f8332bb8.jpeg.jpeg",
        // "https://ms-property-images.s3.us-west-1.amazonaws.com/greenberg/OCEAN+VIEW+POIPU+PALMS+104/40c9783a-1caf-4d67-8061-431c1f0b9705.jpeg.jpeg",
        // "https://ms-property-images.s3.us-west-1.amazonaws.com/greenberg/OCEAN+VIEW+POIPU+PALMS+104/61316ca9-21aa-42d1-a960-79509bf03513.jpeg.jpeg",
        // "https://ms-property-images.s3.us-west-1.amazonaws.com/greenberg/OCEAN+VIEW+POIPU+PALMS+104/94652da5-6db2-4ad2-a8ab-2b0d7d78cb9d.jpeg.jpeg"
    ],
    class: 'development',
    upperTags: [
        developmentTag,
        {
            text: '$1.00',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        completionTag('October 2025')
    ],
    investors: 2,
    progress: 1, 
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 1,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
        ['02/01/2022',643,80],
        ['03/01/2022',595,94],
        ['04/01/2022',599,76],
        ['05/01/2022',593,40],
        ['06/01/2022',534,50],
        ['07/01/2022',568,78],
        ['08/01/2022',534,67],
        ['09/01/2022',543,61],
        ['10/01/2022',534,86],
        ['11/01/2022',500,84],
        ['12/01/2022',522,82],
        ['01/01/2023',481,85]]
    
}

// Greenberg 6
let BeachBungalow = {
    name: 'Beach Bungalow',
    location: 'Kauai, HI',
    photos: [gbCover6],
    class: 'rental',
    upperTags: [
        rentalTag,
        {
            text: '$12.50',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        {
            text: 'AirBNB',
            subText: '',
            color: 'white'
        }
    ],
    investors: getRandomInt(2000, 4000),
    progress: getRandomInt(30, 99),
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 12.50,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',0,0],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]
}

// Greenberg 7
let SurfBungalow = {
    name: 'Surf Bungalow',
    location: 'Kauai, HI',
    photos: [gbCover7],
    class: 'rental',
    upperTags: [
        rentalTag,
        {
            text: '$12.50',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        {
            text: 'AirBNB',
            subText: '',
            color: 'white'
        }
    ],
    investors: getRandomInt(2000, 4000),
    progress: getRandomInt(30, 99),
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 12.50,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',0,0],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]
}

// Greenberg 8
let OhanaHouse = {
    name: 'Ohana House',
    location: 'Kauai, HI',
    photos: [gbCover8],
    class: 'rental',
    upperTags: [
        rentalTag,
        {
            text: '$12.50',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        {
            text: 'AirBNB',
            subText: '',
            color: 'white'
        }
    ],
    investors: getRandomInt(2000, 4000),
    progress: getRandomInt(30, 99),
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 12.50,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',0,0],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]
}

// Greenberg 9
let HulaBungalow = {
    name: 'Hula Bungalow',
    location: 'Kauai, HI',
    photos: [gbCover9],
    class: 'rental',
    upperTags: [
        rentalTag,
        {
            text: '$12.50',
            subText: '',
            color: 'white'
        }
    ],
    lowerTags: [
        {
            text: 'AirBNB',
            subText: '',
            color: 'white'
        }
    ],
    investors: getRandomInt(2000, 4000),
    progress: getRandomInt(30, 99),
    offeringSize: 1250000,
    currentRental: 0.06,
    currentAppreciation: 0.03,
    historicalRental: 0.04,
    historicalAppreciation: 0.04,
    facts: testFacts,
    beds: 3,
    baths: 2,
    news: newsSample,
    perShare: 12.50,
    newsKeyWord: '"kauai"',
        rents: [['01/01/2022',444,100],
    ['02/01/2022',643,80],
    ['03/01/2022',595,94],
    ['04/01/2022',599,76],
    ['05/01/2022',593,40],
    ['06/01/2022',534,50],
    ['07/01/2022',568,78],
    ['08/01/2022',534,67],
    ['09/01/2022',543,61],
    ['10/01/2022',534,86],
    ['11/01/2022',0,0],
    ['12/01/2022',522,82],
    ['01/01/2023',481,85]]
}

// OceanFrontKapili, BeachBungalow, SurfBungalow, OhanaHouse, HulaBungalow, luxuryCondoByBeach,DesignerRetreat


export const offerings = [ CatalinaListing, ccOffering]


