import React, { useRef, useLayoutEffect, useState } from 'react';

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import poipu from 'testdata/rents/ATR Poipu monthly from 2022-01-25 to 2023-01-25 airbnb all all_all.csv'
import Papa from 'papaparse'
import { useEffect } from 'react';
import { clampBuilder } from 'utils/utils';

import { SegmentHolder, Title } from '../TextSegment/components';
import { Source } from './components';

function Chart({offering}) {

    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        fetch(poipu)
        .then(r => r.text())
        .then(text => {
            let parsedText = Papa.parse(text);
            let data = parsedText.data;
            setCsvData(data);
            console.log(data)});
    }, [])

    useLayoutEffect(() => {
    
    
        let root = am5.Root.new("chartdiv");

        let responsive = am5themes_Responsive.newEmpty(root);

        root.setThemes([
          am5themes_Animated.new(root),
          responsive
        ]);

        var chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            focusable: true,
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout,
          pinchZoomX:true
          })
        );

        var easing = am5.ease.linear;
        chart.get("colors").set("step", 3);

        var xAxis = chart.xAxes.push(
          am5xy.DateAxis.new(root, {
            maxDeviation: 0.1,
            groupData: false,
            baseInterval: {
              timeUnit: "month",
              count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
          })
        );

        function createAxisAndSeries(name, data, opposite, tooltipLabel, axisLabel, axisRotation, index) {
          var yRenderer = am5xy.AxisRendererY.new(root, {
            opposite: opposite
          });
          var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
              maxDeviation: 1,
              renderer: yRenderer
            })
          );

          /*
          responsive.addRule({
            name: "AxisRendererX",
            relevant: function(width, height){
              return width < 600;
            },
            applying: function() {
              chart.yAxes.each((yAx, i) => {
                if(i == index){
                  yAx.children.removeIndex(0);
                }
              })
            },
            removing: function() {
              chart.yAxes.each((yAx, i) => {
                if(i == index){
                  yAx.children.push(
                    am5.Label.new(root, {
                    fill: series.get("fill"),
                    rotation: axisRotation,
                    text: axisLabel,
                    y: am5.p50,
                    centerX: am5.p50
                  }))
              }
              })
            }
          })*/

          if (chart.yAxes.indexOf(yAxis) > 0) {
            yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
          }

          // Add series
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
          var series = chart.series.push(
            am5xy.LineSeries.new(root, {
              name: name,
              xAxis: xAxis,
              yAxis: yAxis,
              valueYField: "value",
              valueXField: "date",
              tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: tooltipLabel
              })
            })
          );

          //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
          series.strokes.template.setAll({ strokeWidth: 1 });

          yRenderer.grid.template.set("strokeOpacity", 0.05);
          yRenderer.labels.template.set("fill", series.get("fill"));
          yRenderer.setAll({
            stroke: series.get("fill"),
            strokeOpacity: 1,
            opacity: 1
          });

          // Set up data processor to parse string dates
          // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
          series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "yyyy-MM-dd",
            dateFields: ["date"]
          });
          
          
          yAxis.children.unshift(am5.Label.new(root, {
            fill: series.get("fill"),
            rotation: axisRotation,
            text: axisLabel,
            y: am5.p50,
            centerX: am5.p50
          }));
          
          series.data.setAll(data);
        }


        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          behavior: "none"
        }));
        cursor.lineY.set("visible", false);

        // add scrollbar
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
          orientation: "horizontal"
        }));

        var data1 = offering.rents.map( (point) => {
          return({
              date: new Date(point[0]).getTime(),
              value: parseInt(point[1])
          })
        })

        var data2 = offering.rents.map( (point) => {
          return({
              date: new Date(point[0]).getTime(),
              value: parseInt(point[2])
          })
        })

      createAxisAndSeries("Avg Nightly Rate", data1, false, "Avg. Nightly Rate ${valueY}", "Average Nightly Rate ($)", -90, 0);
      
      createAxisAndSeries("Occupancy", data2, true, "Occupancy {valueY}%", "Occupancy (%)", -270, 1);
      
// Add legend
// https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
var legend = chart.children.push(am5.Legend.new(root, {
  centerX: am5.percent(50),
  x: am5.percent(50),
  layout: root.horizontalLayout,
  
}));

// When legend item container is hovered, dim all the series except the hovered one
legend.itemContainers.template.events.on("pointerover", function(e) {
  var itemContainer = e.target;

  // As series list is data of a legend, dataContext is series
  var series = itemContainer.dataItem.dataContext;

  chart.series.each(function(chartSeries) {
    if (chartSeries != series) {
      chartSeries.strokes.template.setAll({
        strokeOpacity: 0.15,
        stroke: am5.color(0x000000)
      });
    } else {
      chartSeries.strokes.template.setAll({
        strokeWidth: 3
      });
    }
  })
})

// When legend item container is unhovered, make all series as they are
legend.itemContainers.template.events.on("pointerout", function(e) {
  var itemContainer = e.target;
  var series = itemContainer.dataItem.dataContext;

  chart.series.each(function(chartSeries) {
    chartSeries.strokes.template.setAll({
      strokeOpacity: 1,
      strokeWidth: 1,
      stroke: chartSeries.get("fill")
    });
  });
})

      // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
      legend.data.setAll(chart.series.values);
      
      // Add titles
    chart.children.unshift(am5.Label.new(root, {
      text: "Jan 2022 - Jan 2023",
      fontSize: 14,
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50)
    }));

    chart.children.unshift(am5.Label.new(root, {
      text: "Average Nightly Rate & Occupancy",
      fontSize: 25,
      fontWeight: "500",
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 0,
      paddingBottom: 0
    }));

      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }, []);


  return (
    <SegmentHolder>
      <Title> Short Term Rental Data </Title>
      <div id="chartdiv" style={{ width: "100%", height: clampBuilder(360, 1680, 300/16, 500/16)}}></div>
      <Source> Source: <a href="https://www.alltherooms.com" style={{textDecoration: "none"}}> alltherooms.com </a> </Source>
    </SegmentHolder>
  );
}
export default Chart;