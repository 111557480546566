import React from 'react'
import { useState } from 'react'

import {Container, InputBox, InvestButton, HorizSpan, Bolded, Unbolded, ButtonLabelHolder, SharePriceHolder, InvestBorder} from './components'


import {clampBuilder} from 'utils/utils'


const InvestSection = ({offering}) => {

  let perShare = offering.perShare;
  const [shares, setShares] = useState(1);

  const changeHandler = e => {
    setShares(e.target.value);      
  }
  
  let investSectionSize = clampBuilder(360, 1680, 1.1, 1.4);
  

  return (
    <InvestBorder>
      <Container>
        
          
          <HorizSpan>
            <SharePriceHolder> <h2 style={{'fontWeight': '200', 'fontSize': investSectionSize}}> ${perShare.toFixed(2)}</h2> <Bolded size={investSectionSize}> Per Share </Bolded> </SharePriceHolder>
            <SharePriceHolder> <InputBox value={shares} onChange={changeHandler} size={investSectionSize} width={clampBuilder(330, 1680, 60/16, 100/16)}/> <Bolded size={investSectionSize}> Shares </Bolded> </SharePriceHolder>
          </HorizSpan>
          <InvestButton width={clampBuilder(360, 1680, 120/16, 300/16)}> <ButtonLabelHolder> <Bolded size={investSectionSize}> Invest </Bolded>  <Unbolded size={investSectionSize} style={{color: 'white'}}> ${ (perShare*shares).toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2}) } </Unbolded>  </ButtonLabelHolder> </InvestButton>
          
          
      </Container>
    </InvestBorder>
  )
}

export default InvestSection
